import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, Form } from 'react-bootstrap';
import { getInterviewsByCandidate } from '../../store/actions/candidateActions';
import { Label } from '../form';
import Select from 'react-select';

const InterviewsByCandidate = ({ Changehandle, UniqueID, ID }) => {
    const dispatch = useDispatch();

    // State declarations
    const [search, setSearch] = useState('');
    const [isClearable, setIsClearable] = useState(false);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);

    // Redux state selectors
    const { InterviewsByCandidate, loading, error } = useSelector((state) => state.candidate);

    const InterviewsByCandidatedata = InterviewsByCandidate?.data || [];

    // Fetch interviews when UniqueID changes
    useEffect(() => {
        if (UniqueID) {
            dispatch(getInterviewsByCandidate(UniqueID));
        }
    }, [dispatch, UniqueID]);

    // Show loading spinner
    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }

    // Handle case of missing or invalid data
    if (!InterviewsByCandidatedata.length && error) {
        return (
            <>
                <Label text="Select Job" className="label-input" HtmlFor="select_job" />
                <Form.Select name="select_job" disabled>
                    <option value="">Something went wrong.</option>
                </Form.Select>
            </>
        );
    }


    const handleChange = (selectedOption) => {
        console.log(selectedOption.value, "tedOption")
        if (selectedOption) {
            Changehandle('select_job', selectedOption.value);
        } else {
            Changehandle('');
        }
    };

    const handleSearchChange = (inputValue) => {
        setSearch(inputValue);
    };

    const options = InterviewsByCandidatedata.map((option) => ({
        value: option.interview_id + '|' + option.interview_type_id,
        label: `${option.job_name}${option.interview_title ? ` (${option.interview_title})` : ''}`,
    }));

    const selectedOption = ID ? options.find((option) => option.value === ID) : null;

    return (
        <>
            <Label text="Select Job" className="label-input" HtmlFor="select_job" required="required" />
            <Select
                isDisabled={isDisabled}
                isLoading={loading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                options={options}
                value={selectedOption}
                onChange={handleChange}
                onInputChange={handleSearchChange}
                placeholder="Select job from dropdown"
            />
        </>
    );
};

export default InterviewsByCandidate;




// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Form, Spinner } from 'react-bootstrap';
// import { getInterviewsByCandidate } from '../../store/actions/candidateActions';
// import { Label } from '../form';

// const InterviewsByCandidate = ({ Changehandle, UniqueID }) => {
//     const dispatch = useDispatch();
//     const { InterviewsByCandidate, loading, error } = useSelector((state) => state.candidate);
//     let InterviewsByCandidatedata = InterviewsByCandidate?.data;

//     useEffect(() => {
//         if (UniqueID) {
//             dispatch(getInterviewsByCandidate(UniqueID));
//         }
//     }, [dispatch, UniqueID]);



//     if (loading) {
//         return <Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>;
//     }

//     if (!InterviewsByCandidatedata || !Array.isArray(InterviewsByCandidatedata)) {
//         return (
//             <>
//                 <Label text="Select Job" className="label-input" HtmlFor="select_job" />
//                 <Form.Select name="select_job" disabled>
//                     <option value="">Something went wrong.</option>
//                 </Form.Select>
//             </>
//         );
//     }

//     return (
//         <>
//             <Label text="Select Job" HtmlFor="select_job" required="required" />
//             <Form.Select name="select_job" id="select_job" onChange={Changehandle}>
//                 <option value="">Select a job from the dropdown</option>
//                 {InterviewsByCandidatedata.map((option) => (
//                     <option key={option.interview_id} value={`${option.interview_id}|${option.interview_type_id}`}
//                     >
//                         {option.job_name}{option.interview_title ? ` (${option.interview_title})` : ''}

//                     </option>
//                 ))}
//             </Form.Select>
//         </>
//     );
// };

// export default InterviewsByCandidate;
