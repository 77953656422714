import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJobs } from '../../store/actions/jobActions';
import Select from 'react-select';

const CopyJob = ({ Changehandle, copyJob }) => {
    const dispatch = useDispatch();

    const [search, setSearch] = useState('');
    const { allGetJobs, loading } = useSelector((state) => state.job);
    const [isClearable, setIsClearable] = useState(false);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {

        const payload = {
            search: search
        }
        dispatch(getJobs(payload));


    }, [dispatch, search]);

    const handleChange = (selectedOption) => {
        if (selectedOption) {
            Changehandle(selectedOption.value);
        } else {
            Changehandle('')
        }
    };

    const handleSearchChange = (inputValue) => {

        setSearch(inputValue);


    };

    const options = allGetJobs.map((job) => ({
        value: job.id,
        label: job.job_title,
    }));

    const selectedOption = copyJob ? options.find((option) => option.value === copyJob) : null;

    return (

        <>

            <Select
                isDisabled={isDisabled}
                isLoading={loading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                options={options}
                value={selectedOption}
                onChange={handleChange}
                onInputChange={handleSearchChange}
                placeholder="Select job to copy"

            />
        </>
    );
};

export default CopyJob;


