import React, { useState, useEffect } from "react";
import { Table, DropdownButton, Dropdown, Form, Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { share, Video, checkbox, copyicon, adduser, eye, pencil, deletclrred, JobActive, exporticon, deletegray, downloadInterview } from "../../helper/Icons/Icon";
import Statusbtn from "../common/Statusbtn";
import { actionlistfields, headertimeLine, redordercolumnfields } from "./fields";
import ActionList from '../common/ActionList';
import ShareCandidate from "../common/ShareCandidate";
import { CustomButton, CustomRating, } from "../form/index";
import Liveterview from "../RecordedInterviewDetails/Liveterview";
import ReorderColumns from "../common/ReorderColumns";
import RecordedInterview from "../RecordedInterviewDetails/RecordedInterview";
import Spinner from "../common/Spinner";
import * as moment from 'moment'
import { useLocation, useOutletContext } from "react-router-dom";
import { getAll, deleteInterview, deleteInterviews, exportFile, downloadinterview, downloadinterviews, downloadLiveinterview } from '../../store/actions/interviewActions'
import { useDispatch, useSelector } from 'react-redux';
import Paginate from "../common/Paginate";
import JobDetail from "../job/JobDetail";
import Swal from 'sweetalert2';
import Stages from "../common/Stages";
import Tabelcolumn from "../common/Tabelcolumn";
import { getByName } from "../../store/actions/userPrefrenceActions";
import ReactDatePicker from "react-datepicker";
import ErrorAlert from "../common/ErrorAlert";
import { UpdateMetaTags } from "../common/UpdateMetaTags";
import ShareCandidateLiveInterview from "../common/ShareCandidateLiveInterview";
const defaultCurrentPage = 1
const defaultPerPage = 25

const InterviewList = () => {
    const dispatch = useDispatch();
    const refreshApi = useOutletContext();
    const [updateList, setUpdateList] = useState(true)
    const [checkedAll, setcheckedAll] = useState(false)
    const [showRecordedInterview, setShowRecordedInterview] = useState(false);
    const [showactionlist, setShowActionList] = useState(false);
    const [showcandidateEvaluation, setShowCandidateEvaluation] = useState(false);
    const [showcandidateEvaluationLiveInterview, setcandidateEvaluationLiveInterview] = useState(false);
    const [showLiveterview, setShowLiveterview] = useState(false);
    const [showDetailPage, setshowDetailPage] = useState(false);
    const [selectedRating, setSelectedRating] = useState('');
    const [showReorderColumns, setShowReorderColumns] = useState(false);

    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);
    const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
    const [perPage, setPerPage] = useState(userPrefrenceDetail?.preference_value?.record_per_page ?? defaultPerPage);
    const [InterviewID, setInterviewID] = useState(null);
    const [jobSateID, setjobSateID] = useState(null);



    const [reload, setReload] = useState(false);
    const [interviewIds, setInterviewIds] = useState([]);
    const [searchField, setSearchField] = useState({});
    const [showSelectJob, setSelectJob] = useState(false);
    const prefrenceName = "users";
    const [interviewTupe, setInterviewTupe] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null)
    const [errorMessageAlert, setErrorMessageAlert] = useState(null)
    const { interviews, loading, error } = useSelector((state) => state.interview);

    let interviewList = interviews.data;
    let headers = interviews.headers;
    let pages = interviews.pages;
    let totalPages = interviews.total_page;
    let totalRecords = interviews.total_record;

    const [sortConfig, setSortConfig] = useState({ key: interviews?.order_by, order: interviews?.sort_by });
    useEffect(() => {
        UpdateMetaTags({
            title: "Interviews | VidHirePro",
            description: "Interviews | VidHirePro",
        });
        dispatch(getByName(prefrenceName));
    }, [dispatch, updateList])

    useEffect(() => {
        if (userPrefrenceDetail?.preference_value?.record_per_page) {
            setPerPage(userPrefrenceDetail.preference_value.record_per_page);
        }
    }, [userPrefrenceDetail, updateList]);

    useEffect(() => {

        let data = {
            'limit': perPage, 'page': currentPage, search: searchField, order_by: sortConfig?.key, sort_by: sortConfig?.order
        }
        dispatch(getAll(data)).then((res) => {
            if (res.payload.status === 401) {
                setErrorMessageAlert(res.payload?.data?.error || 'Unauthorized access');
                return;
            }

            if (res.payload.status && res.payload?.data?.error) {
                setErrorMessage(res.payload?.data?.error || "Something went wrong");
                return;
            }


            setErrorMessage(null);


        });

    }, [dispatch, reload, currentPage, perPage, searchField, showReorderColumns, updateList, showRecordedInterview, showLiveterview, sortConfig]);

    useEffect(() => {
        refreshPage();

    }, [refreshApi])
    const reloadList = (bol) => {
        setReload(bol)
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPage = (perPage) => {
        setPerPage(perPage);
        setCurrentPage(1)
    };

    const handleModel = (props, id, interviewType) => {

        switch (props) {
            case "actionlist":

                setInterviewID(id);
                setInterviewTupe(interviewType)
                setShowActionList(true);
                break;

            case "Liveterview":
                setInterviewID(id);
                setShowLiveterview(true);
                break;
            case "ReorderColumns":
                setInterviewID(id);
                setShowReorderColumns(true);
                break;
            case "ShareEvaluation":
                setInterviewID(id);
                setInterviewTupe(interviewType)
                setShowCandidateEvaluation(true);
                break;
            case "DownloadInterview":
                InterviewsDownloadActionList(id)
                break;



            case "candidateEvaluationLiveInterview":
                setInterviewID(id);
                setcandidateEvaluationLiveInterview(true);
                break;
            case "SelectJob":
                setInterviewID(id);
                setSelectJob(true);
                break;
            case "Delete":
                handleDelete(id);
                //reloadList(true);
                setShowActionList(false);
                break;
            case "ViewDetail":
                setInterviewID(id);
                setShowRecordedInterview(true);
                break;
            case "JobDetail":
                setjobSateID(id)
                setshowDetailPage(true)
                break;
            default:
                break;
        }
    };

    const closeModel = (props, id) => {

        switch (props) {
            case "actionlist":
                setShowActionList(false);
                break;
            case "ViewDetail":
                setShowRecordedInterview(false);
                break;
            case "Liveterview":
                setShowLiveterview(false);
                break;
            case "ReorderColumns":
                setShowReorderColumns(false);
                refreshPage(pages)

                break;

            case "ShareEvaluation":
                setShowCandidateEvaluation(false);
                break;
            case "SelectJob":
                setSelectJob(false);
                break;
            case "Delete":
                setShowActionList(false);
                break;
            case "candidateEvaluationLiveInterview":

                setcandidateEvaluationLiveInterview(false);
                break;
            case "JobDetail":

                setshowDetailPage(false)
                break;
            default:
                break;
        }
    };

    const handleSelectAllChange = (e) => {
        if (e.target.checked) {
            setcheckedAll(true);
            setInterviewIds(
                interviewList
                    .filter(
                        interview =>
                            !(
                                interview.stage_name.includes("Interview Underway") ||
                                interview.stage_name.includes("Invited")
                            )
                    )
                    .map(interview => ({
                        id: interview.id,
                        interview_type_id: interview.interview_type_id,
                    }))
            );
        } else {
            setInterviewIds([]);
            setcheckedAll(false);
        }
    };


    const deleteMsg = () => Swal.fire({
        icon: 'success',
        title: 'Deleted',
        text: 'Item(s) has been deleted.',
        showConfirmButton: false,
        timer: 3000
    });


    const handleDateChange = (date, key) => {
        const formattedDate = date ? moment(date).format('MM-DD-YYYY') : "";
        setCurrentPage(1)
        setSearchField((prevState) => {
            const newSearchField = { ...prevState };

            if (formattedDate) {

                newSearchField[key] = formattedDate;
            } else {

                delete newSearchField[key];
            }

            return newSearchField;
        });
    };

    const handleFieldSearch = (e) => {
        const { name, value } = e.target;

        setCurrentPage(1)
        if (value === "") {
            const newSearchField = { ...searchField };
            delete newSearchField[name];
            setSearchField(newSearchField);
        } else {

            setSearchField((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    };
    const toggleSortOrder = (key) => {
        setSortConfig(prevConfig => {
            if (prevConfig.key === key) {
                return { key, order: prevConfig.order === 'asc' ? 'desc' : 'asc' };
            } else {
                return { key, order: 'asc' };
            }
        });

    };

    const refreshPage = (page) => {
        setSearchField({});
        setCurrentPage(defaultCurrentPage);
        setcheckedAll(false);
        setInterviewIds([]);
        setPerPage(userPrefrenceDetail?.preference_value?.record_per_page);
        setSortConfig({})
        setUpdateList(!updateList)
        let data = {
            'limit': perPage, 'page': currentPage, search: searchField
        }
        dispatch(getAll(data)).then((res) => {
            setSortConfig({ key: res.payload?.order_by, order: res.payload?.sort_by })
            if (res.payload.status === 401) {
                setErrorMessageAlert(res.payload?.data?.error || 'Unauthorized access');
                return;
            }

            if (res.payload.status && res.payload?.data?.error) {
                setErrorMessage(res.payload?.data?.error || "Something went wrong");
                return;
            }


            setErrorMessage(null);


        });
    };
    const exportInterviews = () => {
        let payload = {
            'export_file': 1
        }
        dispatch(exportFile(payload)).then((res) => {


            let byteCharacters;
            try {
                byteCharacters = atob(res.payload);
            } catch (e) {

                Swal.fire({
                    icon: 'error',
                    title: 'Download Failed',
                    text: 'Failed to decode Jobs data.',
                });
                return;
            }

            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            const blob = new Blob([byteArray], { type: 'text/xlsx' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'Interviews_' + moment(new Date()).format('MM-DD-YYYY') + '.xlsx';

            link.click();

            URL.revokeObjectURL(link.href);

            Swal.fire({
                icon: 'success',
                title: 'Interviews',
                text: 'Interviews downloaded successfully.',
                showConfirmButton: false,
                timer: 3000
            });
        })
            .catch((error) => {
                console.error('Download failed:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Download Failed',
                    showConfirmButton: false,
                    text: `Failed to download Interview: ${error.message}`,
                });
            });;
    }

    const handleCheckboxChange = (id, interview_type_id) => {
        setInterviewIds((prevSelectedItems) => {

            if (prevSelectedItems.some(item => item.id === id)) {

                return prevSelectedItems.filter(item => item.id !== id);
            } else {

                return [...prevSelectedItems, { id, interview_type_id }];
            }
        });
    };
    const InterviewsDownloadActionList = (interviewId) => {

        InterviewDownloadRecorded(interviewId)
        // if (type == 1) {
        //     InterviewDownloadRecorded(interviewId)
        //     return;

        // }
        // else {


        // }
    }
    const InterviewDownloadRecorded = (interviewId) => {


        // interviewIds.forEach((interviewId) => {
        dispatch(downloadinterview(interviewId))
            .then((res) => {

                if (res && res.payload.data) {
                    const byteCharacters = atob(res.payload.data);
                    const byteNumbers = new Uint8Array(byteCharacters.length);

                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }

                    const blob = new Blob([byteNumbers], { type: 'application/zip' })
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'interview.zip';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    URL.revokeObjectURL(link.href);

                    Swal.fire({
                        icon: 'success',
                        title: 'Interview Downloaded',
                        text: `Interview downloaded successfully.`,
                        showConfirmButton: false,
                        timer: 2000
                    }).then(() => {

                        setShowActionList(false);
                    });
                } else {
                    throw new Error('No data received for download.');
                }
            })
            .catch((error) => {




                Swal.fire({
                    icon: 'error',
                    showConfirmButton: false,
                    title: 'Download Failed',
                    text: `Failed to download interview: ${error.response ? error.response.data.message : error.message}`,
                });
            });
        // });
    };
    const InterviewsDownload = (interviewIds = []) => {

        if (interviewIds.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'No Interviews Selected',
                text: 'Please select at least one interview to download.',
            });
            return;
        }
        const payload =
        {
            interviewIds: interviewIds
        }
        // interviewIds.forEach((interviewId) => {
        dispatch(downloadinterviews(payload))
            .then((res) => {

                if (res && res.payload.data) {
                    const byteCharacters = atob(res.payload.data);
                    const byteNumbers = new Uint8Array(byteCharacters.length);

                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }

                    const blob = new Blob([byteNumbers], { type: 'application/zip' })
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'interviews.zip';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    URL.revokeObjectURL(link.href);

                    Swal.fire({
                        icon: 'success',
                        title: 'Interview Downloaded',
                        text: `Interview downloaded successfully.`,
                        showConfirmButton: false,
                        timer: 2000
                    }).then(() => {
                        setcheckedAll(false);
                        setInterviewIds([]);
                    });
                } else {
                    throw new Error('No data received for download.');
                }
            })
            .catch((error) => {




                Swal.fire({
                    icon: 'error',
                    showConfirmButton: false,
                    title: 'Download Failed',
                    text: `Failed to download interview: ${error.response ? error.response.data.message : error.message}`,
                });
            });
        // });
    };
    const InterviewsDownloadLive = (interviewId) => {

        if (interviewId) {
            dispatch(downloadLiveinterview(interviewId))
                .then((res) => {


                    if (res.error)
                        Swal.fire({
                            icon: 'error',
                            title: 'Download Failed',
                            text: `${res.error.message}`,
                            showConfirmButton: false,
                            timer: 2500
                        });
                    else {

                        const blob = new Blob([res.payload.data], { type: 'video/mp4' });


                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = 'video.mp4';

                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);


                        URL.revokeObjectURL(link.href);

                        Swal.fire({
                            icon: 'success',
                            title: 'Interview Downloaded',
                            text: `Interview downloaded successfully.`,
                            showConfirmButton: false,
                            timer: 2000
                        }).then(() => {
                            setShowActionList(false);
                        });
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Download Failed',
                        text: `Failed to download invoice: ${error.message}`,
                    });
                });

        }

    }


    const handleDelete = (interviewId = false) => {

        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure you want to delete the selected item(s)?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                if (interviewId) {
                    dispatch(deleteInterview(interviewId))
                        .then(() => {
                            reloadList(true);
                            setShowActionList(false);
                            deleteMsg();
                        });
                } else {


                    const data = new FormData();
                    interviewIds.forEach((item, index) => {
                        data.append(`ids[${index}]`, item.id);
                    });

                    dispatch(deleteInterviews(data))
                        .then(() => {
                            setReload(!reload)
                            setShowActionList(false);
                            setInterviewIds([]);
                            deleteMsg();
                        });
                }
            }
        });

        reloadList(false)
    };



    return (
        <>
            {loading &&
                <Spinner />
            }
            {errorMessageAlert && <ErrorAlert errorMessage={errorMessageAlert} />}
            <Row className="listing-section row-pad-0" >
                <Col md={12} className="text-end d-flex justify-content-end mt-10 mb-10" >
                    <Dropdown className="linsting-dropdown-arrowNone mr-10" drop="down-centered start" >
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip-quick-action">
                                    More Items
                                </Tooltip>
                            }
                        >
                            <Dropdown.Toggle id="dropdown-autoclose-true" className="header-btn y-center" variant="none">
                                <i class="fa fa-ellipsis-v" ></i>

                            </Dropdown.Toggle>
                        </OverlayTrigger>
                        <Dropdown.Menu>

                            <Dropdown.Item onClick={() => handleDelete()} disabled={interviewIds.length === 0}>
                                {deletegray} Delete
                            </Dropdown.Item>
                            <Dropdown.Item onClick={exportInterviews} >
                                {exporticon} Export
                            </Dropdown.Item>
                            <Dropdown.Item

                                onClick={() => {
                                    const InterviewsID = interviewIds.map(item => item.id);
                                    // const recordedInterviews = interviewIds.filter(item => item.interview_type_id !== 2).map(item => item.id);

                                    if (InterviewsID.length > 0) {
                                        InterviewsDownload(InterviewsID);
                                    }
                                    // if (liveInterviews.length > 0) {
                                    //     InterviewsDownloadLive(liveInterviews);
                                    // }
                                }}
                                disabled={interviewIds.length === 0}
                            >
                                {downloadInterview} Download Interviews
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Stages reloadList={reloadList} module={'interviews'} userPrefrence={'interview_listing_stage_filter'} />


                    <CustomButton className="header-btn mr-10" Tooltip="Select column" onClick={() => handleModel("ReorderColumns")} text={<i class="fa fa-columns fa-fw"></i>} />
                    <CustomButton Tooltip="Refresh" onClick={refreshPage} className="header-btn mr-10" text={<i class="fa fa-refresh"></i>} />
                </Col>

                <div className="oveflow-interview-auto">
                    <Table className="table" >
                        <thead>
                            <tr>
                                <th style={{ width: "15px" }} className="th-checbox">
                                    <Form.Check type="checkbox" checked={checkedAll} onChange={handleSelectAllChange} />
                                </th>

                                <th style={{ width: "15px" }} className="th-icon" ><i className="fa fa-ellipsis-v" title=""></i></th>
                                <th style={{ minWidth: "100px", width: "150px" }} className="th-text">Quick Action</th>
                                {headers &&
                                    headers.map((header, index) => {
                                        const fieldValue = searchField[header.key] || '';
                                        const isSortedColumn = sortConfig.key === header.key;
                                        const isDateField = header.key.toLowerCase().includes("created_date") || header.key.toLowerCase().includes("updated_date") || header.key.toLowerCase() === "start_timestamp" || header.key.toLowerCase() === "end_timestamp";
                                        return (
                                            <th key={index} className="sorting" style={{ minWidth: "100px", width: "200px" }}>
                                                <span onClick={() => toggleSortOrder(header.key)}>
                                                    {isSortedColumn ? (sortConfig.order === 'asc' ? <i class="fa fa-sort-amount-asc" aria-hidden="true"></i> : <i class="fa fa-sort-amount-desc" aria-hidden="true"></i>) : <i class="fa fa-exchange" aria-hidden="true"></i>}
                                                </span>
                                                {header.key === 'ratings' ? (
                                                    <Form.Select
                                                        name={header.key}
                                                        value={fieldValue}
                                                        onChange={handleFieldSearch}
                                                        className="rating-dropdown form-control"
                                                    >
                                                        <option value="">Select Rating</option>


                                                        {[1, , 2, 3, 4, 5].map((rate) => (
                                                            <option key={rate} value={rate}>{rate}</option>
                                                        ))}
                                                    </Form.Select>
                                                ) : isDateField ? (
                                                    <ReactDatePicker
                                                        className="form-control rating-dropdown custom-datepicker"
                                                        selected={fieldValue}
                                                        onChange={(date) => handleDateChange(date, header.key)}
                                                        placeholderText={header.title}
                                                        dateFormat="MM-dd-yyyy"
                                                        isClearable={!!fieldValue}
                                                        showPopperArrow={true}
                                                        popperPlacement="bottom"
                                                    />
                                                ) : (
                                                    <Form.Control
                                                        name={header.key}
                                                        value={fieldValue}
                                                        type="text"
                                                        placeholder={header.title}
                                                        onChange={handleFieldSearch}
                                                    />
                                                )}
                                            </th>
                                        )
                                    })
                                }

                            </tr>
                        </thead>

                        <tbody>
                            {errorMessage ? <td colSpan={10} className=" pad-20 text-center mt-20 mb-10 error-message">{errorMessage}</td> :

                                (interviewList && interviewList.length > 0 ? (
                                    interviewList.map((interview) => {

                                        const InterviewUnderway = interview.stage_name.includes("Interview Underway") && interview.stage_name.includes("Interview Underway")
                                        const Invited = interview.stage_name.includes("Invited") && interview.stage_name.includes("Invited")

                                        return (

                                            <tr key={interview.id}>
                                                <><td>

                                                    <Form.Check
                                                        key={interview.id}
                                                        disabled={(InterviewUnderway || Invited) ? true : false}

                                                        type="checkbox"
                                                        checked={(InterviewUnderway || Invited) ? false : interviewIds.some(item => item.id === interview.id)}
                                                        onChange={() => handleCheckboxChange(interview.id, interview.interview_type_id)}
                                                    />

                                                </td>

                                                    <Tabelcolumn className={` ${(InterviewUnderway || Invited) ? "cus-disabled" : ""} cursor cursor td-icon`} onClick={() => handleModel("actionlist", interview.id, interview.interview_type_id)} icon={<i className="fa fa-ellipsis-v" title="Quick Action"></i>} Tooltip="Quick Action" />


                                                    <td className="cursor td-icon">
                                                        <div className="quick-action">
                                                            {interview.interview_type_id == 2 ?

                                                                <Tabelcolumn className={` ${(InterviewUnderway || Invited) ? "cus-disabled" : ""} cursor cursor td-icon`} span="span" icon={Video} Tooltip="Live Interview" onClick={() => handleModel("Liveterview", interview.id)} />
                                                                : <Tabelcolumn className={` ${(InterviewUnderway || Invited) ? "cus-disabled" : ""} cursor cursor td-icon`} span="span" icon={Video} onClick={() => handleModel("ViewDetail", interview.id)} Tooltip="Recorded interview" />}

                                                            {/* <span>{copyicon}</span> */}
                                                            {
                                                                interview.interview_type_id == 2 ?
                                                                    <Tabelcolumn
                                                                        className={` ${(InterviewUnderway || Invited) ? "cus-disabled" : ""} cursor cursor td-icon`}
                                                                        span="span"
                                                                        icon={share}
                                                                        onClick={() => handleModel("ShareEvaluation", interview.id, interview.interview_type_id)}
                                                                        Tooltip="Share for Evaluation"
                                                                    />
                                                                    :
                                                                    <Tabelcolumn
                                                                        className={` ${(InterviewUnderway || Invited) ? "cus-disabled" : ""} cursor cursor td-icon`}
                                                                        span="span"
                                                                        icon={share}
                                                                        onClick={() => handleModel("ShareEvaluation", interview.id, interview.interview_type_id)}
                                                                        Tooltip="Share for Evaluation"
                                                                    />
                                                            }





                                                        </div>

                                                    </td>

                                                </>
                                                {Object.keys(interview).map(function (key) {
                                                    if (key == "job_title")
                                                        return <td className="td-text td-ellipsis " title={interview.job_title}><a href="#" onClick={() => handleModel("JobDetail", interview.job_id)}>{interview.job_title}</a></td>
                                                    else if (key == "candidate_name")
                                                        return <td className="td-text td-ellipsis" title={interview?.candidate_name}> {interview?.candidate_name}</td>;
                                                    else if (key == "stage_name")
                                                        return <td className="td-text td-ellipsis " title={interview?.stage_name}><Statusbtn className={` status ${interview?.stage_name.replace(/\s+/g, '-').toLowerCase()}`} text={interview?.stage_name} /></td>;
                                                    else if (key == "ratings")
                                                        return <td className="td-text "> {<CustomRating value={interview[key]} edit={false} />}</td>;
                                                    else if (key.includes("_date") || key.includes("_time"))
                                                        return <td className="td-text td-ellipsis " title={moment(interview[key]).format('MM-DD-YYYY h:mm A')}>{interview[key] ? moment(interview[key]).format('MM-DD-YYYY h:mm A') : ""}</td>;
                                                    else if (key != "id" && key != "interview_type_id" && key != "candidate_id" && key != "job_id")
                                                        return <td className="td-text td-ellipsis" title={interview[key]}>{interview[key]}</td>


                                                })}

                                            </tr>
                                        )
                                    })) : <tr>
                                    <td colSpan={`${headers?.length ? headers?.length + 3 : 10}`} className="text-center">No records found.</td>
                                </tr>)

                            }

                        </tbody>
                    </Table>


                </div>

                <Paginate
                    pages={pages}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    perPage={perPage}
                    totalRecords={totalRecords}
                    handlePageChange={handlePageChange}
                    handlePerPage={handlePerPage}
                />
            </Row >

            {showactionlist && <ActionList list={actionlistfields} headertext="Action List" ID={InterviewID} onclick={handleModel} handleClose={() => closeModel("actionlist")} interviewTupe={interviewTupe} />
            }
            {(showcandidateEvaluation && interviewTupe === 1) && <ShareCandidate handleClose={() => closeModel("ShareEvaluation")} interviewID={InterviewID} />}
            {(showcandidateEvaluation && interviewTupe === 2) && <ShareCandidateLiveInterview handleClose={() => closeModel("ShareEvaluation")} interviewID={InterviewID} />}


            {showLiveterview && <Liveterview id={InterviewID} timeLineheader={headertimeLine} handleClose={() => closeModel("Liveterview")} />}
            {showRecordedInterview && <RecordedInterview id={InterviewID} timeLineheader={headertimeLine} handleClose={() => closeModel("ViewDetail")} />}
            {/* {showSelectJob && <InviteLiveInterview UniqueCandidateID={InterviewID} handleClose={() => closeModel("SelectJob")} text="Select Job" modelopnetext="ShareCandidate" />} */}

            {showReorderColumns && <ReorderColumns module="interviews" headertext="Manage Columns" handleClose={() => closeModel("ReorderColumns")} />}
            {showDetailPage && <JobDetail jobId={jobSateID} handleClose={() => closeModel("JobDetail")} />}
        </>
    );
};



export default InterviewList;
