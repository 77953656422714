import { Row, Col } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";
import { React, useEffect, useState } from "react";
const BillingLayout = () => {
    const [refreshComponent, setrefreshComponent] = useState(false);
    const refreshCom = (to) => {
        setrefreshComponent((prev) => !prev);
    }
    return (
        <>
            <Row className="row-pad-0">
                <Col md={2} className="IntegrationsSetting-tab pad-0">
                    <div className="template-wrap-nav d-flex flex-column pad-20 bg-white">
                        <NavLink
                            onClick={refreshCom}
                            to="plan-overview"
                            className={({ isActive }) => `pad-10 mb-20 ${isActive ? 'active' : ''}`}
                            style={{ padding: "10px" }}
                        >
                            Plan Overview
                        </NavLink>

                        <NavLink to="billing-packages"
                            onClick={refreshCom}
                            className={({ isActive }) => `pad-10 mb-20 ${isActive ? 'active' : ''}`}
                            style={{ padding: "10px" }}
                        >Packages</NavLink>
                        <NavLink
                            to="billing-history"
                            onClick={refreshCom}
                            className={({ isActive }) => `pad-10 mb-20 ${isActive ? 'active' : ''}`}
                            style={{ padding: "10px" }}
                        >
                            Billing History
                        </NavLink>



                    </div>
                </Col>
                <Col sm={10} className='bg-light-gray'>
                    <Outlet context={refreshComponent} />
                </Col>
            </Row >
        </>
    );
};

export default BillingLayout;
