import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from "react-bootstrap";
import { mic, sky, answerDuration } from "../../helper/Icons/Icon";
import { CustomButton } from "../form";
import Swal from 'sweetalert2';
import Spinner from '../common/Spinner';

import { useParams } from 'react-router-dom';
import { addquestionAnswer, addquestionAnswerBlob, updateInterveiewTimeine } from "../../store/actions/InterviewproceedAction";
import { CodeSlash } from "react-bootstrap-icons";
const config = require('../../config/config.json');

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 2000
    });
}

const AudioRecorder = ({ question, answer, activeStep, reLoad }) => {
    const { token } = useParams();
    const audioref = useRef(null);
    const mediaRecorder = useRef(null);
    const dispatch = useDispatch();
    const [isRecording, setIsRecording] = useState(false);
    const [recordedBlob, setRecordedBlob] = useState(null);
    const [aurl, setAurl] = useState(answer?.interview_answer ?? null);
    const [mLoading, setMLoading] = useState(false);
    const [thinkTimeShow, SetThinkTimeShow] = useState(true);
    const [retakeVideo, setRetakeVideo] = useState(false);
    const [retakeCount, setRetakeCount] = useState(answer?.answer_retake ?? 0);
    const [audioChunks, setAudioChunks] = useState([]);
    const [startStreem, setStartStreem] = useState(null);
    const [responseTimeCount, setResponseTimeCount] = useState(0);
    const [thinkTime, setThinkTime] = useState(0);
    const [responseTime, setResponseTime] = useState(answer?.response_time_insec ?? 0);
    const th = useRef();
    const rs = useRef();
    const responseTimeCountInterval = useRef(null)
    const totalResponseTime = 5 * 60; //5 
    const { loading, error } = useSelector((state) => state.Interviewproceed);
    useEffect(() => {
        if (!isRecording && !answer?.interview_answer && thinkTimeShow) {
            th.current = setInterval(() => setThinkTime(prevSeconds => prevSeconds + 1), 1000)
        }

        if (isRecording) {
            rs.current = setInterval(() => setResponseTime(prevSeconds => prevSeconds + 1), 1000)
            responseTimeCountInterval.current = setInterval(() => setResponseTimeCount(prevSeconds => prevSeconds + 1), 1000)

        }

    }, [isRecording, answer, aurl]);
    useEffect(() => {
        if (responseTimeCount >= totalResponseTime) {
            stopRecording(true)
        }
    }, [responseTime])
    const startRecording = async (retacke) => {
        setMLoading(true)
        if (!answer?.interview_answer) {
            clearInterval(th.current);
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

            audioref.current.srcObject = stream;
            mediaRecorder.current = new MediaRecorder(stream, { mimeType: 'audio/webm' });
            mediaRecorder.current.start();

            setMLoading(false)
            setStartStreem(stream)
            setIsRecording(true);
            SetThinkTimeShow(false)
            if (retacke) {
                setRetakeCount(retakeCount + 1)
            }


            const chunks = [];
            mediaRecorder.current.ondataavailable = (event) => {
                chunks.push(event.data);
            };

            setAudioChunks(chunks);

        } catch (error) {
            let errorMessage = "Error: Could not start audio source";
            if (error.name === "NotReadableError") {
                SetThinkTimeShow(true)
                setMLoading(false)
                errorMessage += ". This could be due to permission issues, browser support, or device availability.";

            } else if (error.name === "NotAllowedError") {
                SetThinkTimeShow(true)
                setMLoading(false)
                errorMessage += ". Please grant permission to access the microphone.";

            }

            Swal.fire({
                icon: "error",
                title: "Error!",
                text: errorMessage,
                showConfirmButton: false,
                timer: 2000
            });
        }

    };


    const stopRecording = (retacke) => {

        if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
            mediaRecorder.current.stop();
        }
        clearInterval(rs.current);
        clearInterval(responseTimeCountInterval.current);

        mediaRecorder.current.onstop = () => {

            const blob = new Blob(audioChunks, { type: 'audio/webm' });
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                var base64data = reader.result;
                setRecordedBlob(blob);

                const audioUrl = window.URL.createObjectURL(blob);
                setAurl(audioUrl)

                const formData = new FormData();
                formData.append("interview_template_question_id", question.interview_template_question_id);
                formData.append("retakes", retakeCount);
                formData.append("response_time", Math.ceil(responseTime / 60));
                formData.append("response_time_insec", responseTime);
                formData.append("think_time", Math.ceil(thinkTime / 60));
                formData.append("interview_answer", audioUrl);
                formData.append("interview_answer_encoded", blob);
                formData.append("question_text", question.question_text);
                formData.append("answer_media", question.question_type_name);
                setMLoading(true)
                dispatch(addquestionAnswerBlob({ token, formData }))
                    .then((res) => {
                        setMLoading(false)

                        reLoad(true)
                    })
                    .catch(() => {
                        sweetAlert(
                            "error",
                            "Error!",
                            "Something went wrong!"
                        );
                    });

                let qNumber = activeStep + 1;
                const log = {
                    key: 'audio',
                    log: `Submitted Response for ${question.question_type_name} Question ${qNumber}`,
                    date_time: new Date()

                };
                dispatch(updateInterveiewTimeine({ token, log }));

                audioref.current.srcObject = null;
                startStreem.getTracks().forEach(track => track.stop());
                // setResponseTimeCount(0)
                setIsRecording(false);
                setRetakeVideo(false);
                setAudioChunks([]);
                setStartStreem(null)
                reLoad(false)

            };
        }
    };




    return (

        <Row className="row-pad-0 pad-10 border-radius-5 interview-media-wrap bg-ligte-gray mt-15">
            {mLoading && <Spinner />}
            <Col md={12} className="RecordAudio-wrap">
                <h4 className="sub-heading">
                    <span className="mr-5">{mic}Record Audio:</span>
                </h4>
                <div className="audio-section mt-20 sm-t-10 ">
                    <span className="ready-audio">
                        <small></small>
                    </span>


                    <>
                        {(!answer?.interview_answer || retakeVideo) &&
                            <audio controls ref={audioref} autoPlay muted />
                        }

                        {(!retakeVideo && answer?.interview_answer) && (
                            <audio controls
                                src={`${aurl}`}
                            />
                        )}

                        <div className="audio-buttons">
                            {(!answer?.interview_answer || retakeVideo) && (
                                <>
                                    {retakeVideo ?
                                        <>
                                            <CustomButton text="Record Now" className=" custom-btn-primary mt-20 mr-10 " disabled={isRecording} onClick={() => startRecording(true)} />
                                            <CustomButton text="Save & Continue" className="custom-btn-outline mr-10 mt-20 mr-10" disabled={!isRecording} onClick={() => stopRecording(true)} />
                                            <CustomButton text="Cancel Retake" className="custom-btn-outline mr-10 mt-20" disabled={isRecording} onClick={() => setRetakeVideo(false)} />

                                        </>
                                        :
                                        <>
                                            <CustomButton text="Record Now" className=" custom-btn-primary mt-20 mr-10" disabled={isRecording} onClick={() => startRecording(false)} />
                                            <CustomButton text="Save & Continue" className="custom-btn-outline mr-10  mt-20" disabled={!isRecording} onClick={() => stopRecording(false)} />

                                        </>
                                    }

                                    <div className="mt-1 only-response-time" style={{ display: isRecording ? 'block' : 'none' }}> <div className="d-flex align-items-center"> <span className="mr-10" >{answerDuration}</span><span className="play-time">Responding time:{responseTime}</span></div></div>

                                    {(!isRecording && !answer?.interview_answer) &&

                                        (thinkTimeShow && <div className="mt-1 only-response-time"><span className="mr-10" >{sky}</span><span className="play-time">Thinking time: {thinkTime}</span></div>)

                                    }
                                </>
                            )}

                            {(!retakeVideo && answer?.interview_answer) && (
                                <>
                                    {/* <CustomButton text="Download Audio" className="custom-btn-outline mr-10 mt-20" onClick={() => handleDownload(`${answer?.interview_answer}`)} /> */}

                                    {question.retakes !== answer?.answer_retake &&

                                        <CustomButton text="Retake " className="custom-btn-outline mt-20" onClick={() => setRetakeVideo(true)} />
                                    }
                                </>
                            )}
                        </div>
                    </>


                </div>

            </Col>
        </Row>

    );
};

export default AudioRecorder;



