import { Offcanvas, Row, Container, Col } from "react-bootstrap";

import { useEffect, useState } from "react"
import { CustomButton, InputField, Switch, SelectField, Label } from "../form/index";
import ShareCandidate from "./ShareCandidate";
import ShareCandidateLiveInterview from "./ShareCandidateLiveInterview";
import { adduser, attachment, crossicon, website, faplusicon, faminusicon, btnplus, expirtime } from "../../helper/Icons/Icon"
import Alljobs from "./Alljobs";
import '../../css/actionlist.css';
import AllUniqueJobCandidate from "./AllUniqueJobCandidate";
import InterviewsByCandidat from "./InterviewsByCandidat";
import ScheduleInterviews from "../../components/common/ScheduleInterview";

const InviteLiveInterview = ({ handleClose, text, modelopnetext, candidateId, UniqueCandidateID }) => {


  const [selectedID, setSelectID] = useState(null);
  const [showscheduledjob, setscheduledjob] = useState(false);
  const [type, setType] = useState('');
  const [ShowshareCandidate, setshareCandidate] = useState(false);
  const [ShowLiveShareCandidate, setLiveShareCandidate] = useState(false)
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    select_job: ''
  });

  const jobId = formData.select_job;
  useEffect(() => {
    setSelectID(candidateId);

  }, [])
  const scheduledHandler = (action) => {
    if (action === "scheduleInterview") {
      const validationErrors = validateForm(formData);
      if (Object.keys(validationErrors).length === 0) {
        setscheduledjob(!showscheduledjob);
        if (showscheduledjob) {
          handleClose();
        }
        else {
          setErrors(validationErrors);
        }
      }

    } else if (action === "ShareCandidate") {
      const validationErrors = validateForm(formData);
      if (Object.keys(validationErrors).length === 0) {
        if (type == 1) {

          setshareCandidate(true);
        }
        else {

          setLiveShareCandidate(true)
        }


        if (ShowshareCandidate) {
          handleClose();
        }
      } else {
        setErrors(validationErrors);
      }
    }
  };
  const interviewsCandidathandle = (name, value) => {
    console.log(name, value)
    // const name = e.target.name;
    const [interview_id, interview_type_id] = value.split('|');
    console.log(interview_id, interview_type_id)
    setType(interview_type_id)

    setFormData(prevData => ({
      ...prevData,
      [name]: interview_id
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: interview_id ? '' : 'Field is required'
    }));

  }
  // const Changehandle = (e) => {
  //   const { name, value } = e.target;
  //   setFormData(prevData => ({
  //     ...prevData,
  //     [name]: value
  //   }));
  //   setErrors(prevErrors => ({
  //     ...prevErrors,
  //     [name]: value.trim() ? '' : 'Field is required'
  //   }));
  // };
  const handleJobSearch = (name, value) => {
    console.log(name, value)
    const numericValue = value ? parseInt(value) : value;
    setFormData(prevData => ({
      ...prevData,
      [name]: numericValue
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: value ? '' : 'Field is required'
    }));
  };
  const validateForm = (formData) => {
    const errors = {};
    if (!formData.select_job || formData.select_job === '') {
      errors.select_job = 'Select job is required';
    }
    return errors;
  };


  const CloseModel = (props) => {
    if (props == "candidateEvaluationLiveInterview") {
      setLiveShareCandidate(false)

    }
    else {
      setshareCandidate(false)
    }
  }

  return (
    <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "620px" }}>

      <Offcanvas.Header className="model-header bg-white ">

        <Offcanvas.Title>
          <span className="model-header-svg">{adduser}{text}</span>


        </Offcanvas.Title>
        <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} /></div>
      </Offcanvas.Header>
      <Col md={12} className=" mt-10 pad-10 ">
        <Row className="row-pad-0 pad-20 bg-white radius-7  " >
          {modelopnetext == "scheduleInterview" ? <Alljobs Changehandle={handleJobSearch} JobID={formData.select_job} /> : <InterviewsByCandidat UniqueID={UniqueCandidateID} Changehandle={interviewsCandidathandle} ID={formData.select_job} />}

          {errors.select_job && <span className="text-danger">{errors.select_job}</span>}


        </Row>

      </Col>
      <Col md={12} className="text-end mt-20 mb-20 pad-0">
        <CustomButton className="mr-10 custom-btn-primary" text="Submit" onClick={() => scheduledHandler(modelopnetext)} />

      </Col>



      {showscheduledjob && <ScheduleInterviews jobId={jobId} handleClose={() => scheduledHandler("scheduleInterview")} />}
      {ShowshareCandidate && <ShareCandidate interviewID={jobId} handleClose={() => CloseModel("ShareCandidate")} />}
      {ShowLiveShareCandidate && <ShareCandidateLiveInterview interviewID={jobId} handleClose={() => CloseModel("candidateEvaluationLiveInterview")} />}
    </Offcanvas>
  );
}



export default InviteLiveInterview;
















// import { Offcanvas, Row, Container, Col } from "react-bootstrap";

// import { useEffect, useState } from "react"
// import { CustomButton, InputField, Switch, SelectField, Label } from "../form/index";
// import ShareCandidate from "./ShareCandidate";
// import ShareCandidateLiveInterview from "./ShareCandidateLiveInterview";
// import { adduser, attachment, crossicon, website, faplusicon, faminusicon, btnplus, expirtime } from "../../helper/Icons/Icon"
// import Alljobs from "./Alljobs";
// import '../../css/actionlist.css';
// import AllUniqueJobCandidate from "./AllUniqueJobCandidate";
// import InterviewsByCandidat from "./InterviewsByCandidat";
// import ScheduleInterviews from "../../components/common/ScheduleInterview";

// const InviteLiveInterview = ({ handleClose, text, modelopnetext, candidateId, UniqueCandidateID }) => {


//   const [selectedID, setSelectID] = useState(null);
//   const [showscheduledjob, setscheduledjob] = useState(false);
//   const [type, setType] = useState('');
//   const [ShowshareCandidate, setshareCandidate] = useState(false);
//   const [ShowLiveShareCandidate, setLiveShareCandidate] = useState(false)
//   const [errors, setErrors] = useState({});
//   const [formData, setFormData] = useState({
//     select_job: ''
//   });
//   const jobId = formData.select_job;
//   useEffect(() => {
//     setSelectID(candidateId);

//   }, [])
//   const scheduledHandler = (action) => {
//     if (action === "scheduleInterview") {
//       const validationErrors = validateForm(formData);
//       if (Object.keys(validationErrors).length === 0) {
//         setscheduledjob(!showscheduledjob);
//         if (showscheduledjob) {
//           handleClose();
//         }
//         else {
//           setErrors(validationErrors);
//         }
//       }

//     } else if (action === "ShareCandidate") {
//       const validationErrors = validateForm(formData);
//       if (Object.keys(validationErrors).length === 0) {
//         if (type == 1) {

//           setshareCandidate(true);
//         }
//         else {

//           setLiveShareCandidate(true)
//         }


//         if (ShowshareCandidate) {
//           handleClose();
//         }
//       } else {
//         setErrors(validationErrors);
//       }
//     }
//   };
//   const interviewsCandidathandle = (e) => {
//     const name = e.target.name;
//     const [interview_id, interview_type_id] = e.target.value.split('|');

//     setType(interview_type_id)

//     setFormData(prevData => ({
//       ...prevData,
//       [name]: interview_id
//     }));
//     setErrors(prevErrors => ({
//       ...prevErrors,
//       [name]: interview_id ? '' : 'Field is required'
//     }));

//   }
//   const Changehandle = (e) => {
//     const { name, value } = e.target;
//     setFormData(prevData => ({
//       ...prevData,
//       [name]: value
//     }));
//     setErrors(prevErrors => ({
//       ...prevErrors,
//       [name]: value.trim() ? '' : 'Field is required'
//     }));
//   };

//   const validateForm = (formData) => {
//     const errors = {};
//     if (!formData.select_job || formData.select_job.trim() === '') {
//       errors.select_job = 'Select job is required';
//     }
//     return errors;
//   };


//   const CloseModel = (props) => {
//     if (props == "candidateEvaluationLiveInterview") {
//       setLiveShareCandidate(false)

//     }
//     else {
//       setshareCandidate(false)
//     }
//   }

//   return (
//     <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "620px" }}>

//       <Offcanvas.Header className="model-header bg-white ">

//         <Offcanvas.Title>
//           <span className="model-header-svg">{adduser}{text}</span>


//         </Offcanvas.Title>
//         <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} /></div>
//       </Offcanvas.Header>
//       <Col md={12} className=" mt-10 pad-10 ">
//         <Row className="row-pad-0 pad-20 bg-white radius-7  " >
//           {modelopnetext == "scheduleInterview" ? <Alljobs Changehandle={Changehandle} /> : <InterviewsByCandidat UniqueID={UniqueCandidateID} Changehandle={interviewsCandidathandle} />}

//           {errors.select_job && <span className="text-danger">{errors.select_job}</span>}


//         </Row>

//       </Col>
//       <Col md={12} className="text-end mt-20 mb-20 pad-0">
//         <CustomButton className="mr-10 custom-btn-primary" text="Submit" onClick={() => scheduledHandler(modelopnetext)} />

//       </Col>



//       {showscheduledjob && <ScheduleInterviews jobId={jobId} handleClose={() => scheduledHandler("scheduleInterview")} />}
//       {ShowshareCandidate && <ShareCandidate interviewID={jobId} handleClose={() => CloseModel("ShareCandidate")} />}
//       {ShowLiveShareCandidate && <ShareCandidateLiveInterview interviewID={jobId} handleClose={() => CloseModel("candidateEvaluationLiveInterview")} />}
//     </Offcanvas>
//   );
// }



// export default InviteLiveInterview;