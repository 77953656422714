
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { getJobs } from '../../store/actions/jobActions';


const Alljobs = ({ Changehandle, JobID }) => {
    const dispatch = useDispatch();


    const { allGetJobs, loading } = useSelector((state) => state.job);
    const [search, setSearch] = useState('');
    const [isClearable, setIsClearable] = useState(false);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {

        const payload = {
            search: search
        }
        dispatch(getJobs(payload));


    }, [dispatch, search]);

    const handleChange = (selectedOption) => {
        if (selectedOption) {
            Changehandle('select_job', selectedOption.value);
        } else {
            Changehandle('')
        }
    };

    const handleSearchChange = (inputValue) => {

        setSearch(inputValue);


    };

    const options = allGetJobs.map((job) => ({
        value: job.id,
        label: job.job_title,
    }));

    const selectedOption = JobID ? options.find((option) => option.value === JobID) : null;

    return (

        <>

            <Select
                isDisabled={isDisabled}
                isLoading={loading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                options={options}
                value={selectedOption}
                onChange={handleChange}
                onInputChange={handleSearchChange}
                placeholder="Select job from dropdown"

            />
        </>
    );
};

export default Alljobs;




// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Form, Spinner } from 'react-bootstrap';
// import { getJobs } from '../../store/actions/jobActions';
// import { Label } from '../form';
// import { CodeSlash } from 'react-bootstrap-icons';

// const Alljobs = ({ Changehandle }) => {
//     const dispatch = useDispatch();
//     const { allGetJobs, loading, error } = useSelector((state) => state.job);


//     useEffect(() => {
//         dispatch(getJobs())

//     }, [dispatch]);


//     if (!allGetJobs || !Array.isArray(allGetJobs)) {
//         return (<>

//             <Label text="Select Job " className="label-input" HtmlFor="select_job" required="required" />
//             <Form.Select name='select_job'  >
//                 <option value="">Some thing wrong check internect connection</option>

//             </Form.Select>
//         </>)

//     }


//     return (
//         <>


//             <Label text="Select Job" HtmlFor="select_job" required="required" />
//             <Form.Select name='select_job' id='select_job' onChange={Changehandle} >
//                 <option value="">Select job from dropdown</option>
//                 {allGetJobs.map(option => (
//                     <option
//                         key={option.id}
//                         value={option.id}

//                     >
//                         {option.job_title}
//                     </option>
//                 ))}
//             </Form.Select>
//         </>
//     );
// };

// export default Alljobs;
