import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from '../../store/actions/authActions';
const ErrorAlert = ({ errorMessage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorMessage) {
      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        showConfirmButton: false,
        timer: 1000,
      }).then(() => {
        dispatch(logout());
        navigate("/");
      });
    }
  }, [errorMessage, navigate, dispatch]);
  return null;
};

export default ErrorAlert;
