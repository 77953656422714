
import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { share, adduser, eye, pencil, deletclrred, downloadarrowblow } from "../../helper/Icons/Icon";
import { useLocation, useOutletContext } from "react-router-dom";

import { Form, Button, Row, Col } from "react-bootstrap";
import Spinner from "../common/Spinner";
import { getByName } from "../../store/actions/userPrefrenceActions";
import { getPackagesAll, downloadInvoice, getTransactionsAll } from "../../store/actions/accountBillingAction";
import { useDispatch, useSelector } from 'react-redux';
import ErrorAlert from "../common/ErrorAlert";
import * as moment from 'moment'
import Tabelcolumn from "../common/Tabelcolumn";
import Paginate from "../common/Paginate";
import { CustomButton } from "../form";
import { UpdateMetaTags } from "../common/UpdateMetaTags";
import Swal from "sweetalert2";
import ReactDatePicker from "react-datepicker";
const defaultCurrentPage = 1
const defaultPerPage = 25

const headers = [
    { key: 'invoice_date', title: 'Invoice Date' },
    { key: 'purchase_type', title: 'Purchase Type' },
    { key: 'package_purchase_id', title: 'Invoice ID' },
    { key: 'payment_status', title: 'Status' },
    { key: 'created_date', title: 'Due Date' },
    { key: 'due_paid', title: 'Due Paid' },
    { key: 'amount', title: 'Amount' }
];

const BillingList = () => {

    const dispatch = useDispatch();
    const refreshApi = useOutletContext();
    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);
    const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
    const [perPage, setPerPage] = useState(userPrefrenceDetail?.preference_value?.record_per_page ?? defaultPerPage);
    const [searchField, setSearchField] = useState({});
    const [errorMessage, setErrorMessage] = useState(null)
    const [errorMessageAlert, setErrorMessageAlert] = useState(null)
    const prefrenceName = "users";
    const { allPackageTransactions, loading, error } = useSelector((state) => state.accountBilling);
    const billingList = allPackageTransactions?.data;
    const [sortConfig, setSortConfig] = useState({});
    let pages = allPackageTransactions?.pages;
    let totalPages = allPackageTransactions?.total_page;
    let totalRecords = allPackageTransactions?.total_record;

    useEffect(() => {
        UpdateMetaTags({
            title: "Billing History | VidHirePro",
            description: "Billing History | VidHirePro",
        });
        dispatch(getByName(prefrenceName));
    }, [dispatch])

    useEffect(() => {
        if (userPrefrenceDetail?.preference_value?.record_per_page) {
            setPerPage(userPrefrenceDetail.preference_value.record_per_page);
        }
    }, [userPrefrenceDetail]);

    useEffect(() => {
        if (perPage) {
            let payload = {
                'limit': perPage, 'page': currentPage, search: searchField, order_by: sortConfig?.key, sort_by: sortConfig?.order
            }

            dispatch(getTransactionsAll(payload)).then((res) => {
                if (res.payload.status === 401) {
                    setErrorMessageAlert(res.payload?.data?.error || 'Unauthorized access');
                    return;
                }

                if (res.payload.status && res.payload?.data?.error) {
                    setErrorMessage(res.payload?.data?.error || "Something went wrong");
                    return;
                }


                setErrorMessage(null);
            });;
        }


    }, [dispatch, currentPage, perPage, searchField, sortConfig]);


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPage = (perPage) => {
        setPerPage(perPage);
        setCurrentPage(1)
    };

    useEffect(() => {
        refreshPage();
    }, [refreshApi])

    const refreshPage = (page) => {
        setSearchField({});
        setCurrentPage(defaultCurrentPage);
        setPerPage(userPrefrenceDetail?.preference_value?.record_per_page ?? defaultCurrentPage);
        setSortConfig({})
        let payload = {
            'limit': perPage, 'page': defaultCurrentPage,

        }
        dispatch(getTransactionsAll(payload));
    };
    const handleDateChange = (date, key) => {
        const formattedDate = date ? moment(date).format('MM-DD-YYYY') : "";
        setCurrentPage(1)
        setSearchField((prevState) => {
            const newSearchField = { ...prevState };

            if (formattedDate) {

                newSearchField[key] = formattedDate;
            } else {

                delete newSearchField[key];
            }

            return newSearchField;
        });



    };

    const handleFieldSearch = (e) => {
        const { name, value } = e.target;

        setCurrentPage(1)
        if (value === "") {
            const newSearchField = { ...searchField };
            delete newSearchField[name];
            setSearchField(newSearchField);
        } else {
            const cleanValue = value.includes('$') ? value.replace(/^\$/, '') : value;

            setSearchField((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const invoiceDownload = (packagePurchaseId) => {
        dispatch(downloadInvoice(packagePurchaseId))
            .then((res) => {

                const byteCharacters = atob(res.payload);
                const byteNumbers = new Array(byteCharacters.length);


                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);


                const blob = new Blob([byteArray], { type: 'application/pdf' });


                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'invoice.pdf';
                link.click();


                URL.revokeObjectURL(link.href);






                Swal.fire({
                    icon: 'success',
                    title: 'Invoice Downloaded',
                    text: 'Invoice downloaded successfully.',
                    showConfirmButton: false,
                    timer: 3000
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Download Failed',
                    text: `Failed to download invoice: ${error.message}`,
                });
            });
    };

    const toggleSortOrder = (key) => {
        setSortConfig(prevConfig => {
            if (prevConfig.key === key) {
                return { key, order: prevConfig.order === 'asc' ? 'desc' : 'asc' };
            } else {
                return { key, order: 'asc' };
            }
        });
    };


    return (
        <>
            {loading &&
                <Spinner />
            }

            {errorMessageAlert && <ErrorAlert errorMessage={errorMessageAlert} />}
            <Row className=" row-pad-0" >

                <div className="listing-section billing-list"
                >


                    <Table className="table">
                        <thead>
                            <tr>


                                <th style={{ width: "120px" }} className="th-text">Download
                                    Invoice</th>

                                {headers &&
                                    headers.map((header, index) => {
                                        const fieldValue = searchField[header.key] || '';
                                        const isSortedColumn = sortConfig.key === header.key;
                                        const isDateField = header.key.toLowerCase().includes("created_date") || header.key.toLowerCase().includes("invoice_date") || header.key.toLowerCase().includes("due_paid");
                                        return (
                                            <th key={index} style={{ minWidth: "100px", width: "200px" }} className={`sorting  ${header.key.toLowerCase().includes("invoice_date") ? "date-picker-billing" : ""}`} >
                                                <span onClick={() => toggleSortOrder(header.key)}>
                                                    {isSortedColumn ? (sortConfig.order === 'asc' ? <i class="fa fa-sort-amount-asc" aria-hidden="true"></i> : <i class="fa fa-sort-amount-desc" aria-hidden="true"></i>) : <i class="fa fa-exchange" aria-hidden="true"></i>}
                                                </span>
                                                {isDateField ? (
                                                    <ReactDatePicker
                                                        className="form-control rating-dropdown custom-datepicker"
                                                        selected={fieldValue}
                                                        onChange={(date) => handleDateChange(date, header.key)}
                                                        placeholderText={header.title}
                                                        dateFormat="MM-dd-yyyy"
                                                        isClearable={!!fieldValue}
                                                        showPopperArrow={true}
                                                        popperPlacement="bottom"
                                                    />
                                                ) : (
                                                    <Form.Control
                                                        name={header.key}
                                                        type="text"
                                                        value={fieldValue}
                                                        placeholder={header.title}
                                                        onChange={handleFieldSearch}
                                                    />
                                                )}    </th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {errorMessage ? <td colSpan={10} className=" pad-20 text-center mt-20 mb-10 error-message">{errorMessage}</td> :
                                (billingList && billingList.length > 0 ? billingList.map((label, index) => (
                                    <tr key={index}>
                                        <td className="td-icon">
                                            < Tabelcolumn span="props" className="cursor" icon={downloadarrowblow} onClick={() => invoiceDownload(label.transaction_id)} Tooltip="Download" />

                                        </td>

                                        <td className="td-text">{label.created_date ? moment(label.created_date).format('MM-DD-YYYY h:mm A') : ''}</td>
                                        <td className="td-text">{label.purchase_type}</td>
                                        <td className="td-text">{label.invice_id}</td>
                                        <td className="td-text">
                                            <CustomButton
                                                className={`status-${label.payment_status} status`}
                                                text={label.payment_status}
                                            />
                                        </td>
                                        <td className="td-text">
                                            {label.created_date ? moment(label.created_date).format('MM-DD-YYYY h:mm A') : ''}
                                        </td>
                                        <td className="td-text">{label.payment_date ? moment(label.payment_date).format('MM-DD-YYYY h:mm A') : ''}</td>
                                        <td className="td-text">${label.amount}</td>
                                    </tr>
                                )) :
                                    <tr>
                                        <td colSpan={10} className="text-center">No records found.</td>
                                    </tr>)}
                        </tbody>
                    </Table>
                </div>
                <Paginate
                    pages={pages}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    perPage={perPage}
                    totalRecords={totalRecords}
                    handlePageChange={handlePageChange}
                    handlePerPage={handlePerPage}
                />

            </Row >

        </>
    );
};



export default BillingList;
