



import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Row, Col, Offcanvas } from "react-bootstrap";
import virtelligenceTeam from "../../assets/images/virtelligenceteam.jpg";
import Companyoverview from "./Companyoverview";
import { CustomButton } from "../form/index";
import AboutCompany from "./AboutCompany";
import Photos from "./Photos";
import Highlights from "./Highlights";
import Spinner from "../common/Spinner";
import Connectsocialmedia from "./Connectsocialmedia";
import JobsLike from "./JobsLike";
import Paginate from "../common/Paginate";
import banner from '../../assets/images/banner.png';
import { JobActive, crossicon } from "../../helper/Icons/Icon";
import { getById, getCompanyJobs, companyhighlights } from "../../store/actions/companyActions";
import HeaderCompanName from "./HeaderCompanName";
import { getAll } from "../../store/actions/documentActions";
const config = require('../../config/config.json');


const ViewCompanyModel = () => {
  const companyId = sessionStorage.getItem('companyID');
  const [errorMessage, setErrorMessage] = useState(null)

  const dispatch = useDispatch();


  useEffect(() => {


    if (companyId) {

      dispatch(getById(companyId)).then((res) => {

        if (res.payload.status === 401) {
          setErrorMessage("Company  has been deleted." || "Something went wrong");
          // setErrorMessageAlert(res.payload?.data?.error || 'Unauthorized access');
          return;
        }

        if (res.payload.status && res.payload?.data) {
          setErrorMessage("Company  has been deleted." || "Something went wrong");
          return;
        }

        setErrorMessage(null);
      });

      dispatch(companyhighlights(companyId));
      dispatch(getAll({ module_name: "companies", module_pk_id: companyId }));
    }
  }, [dispatch, companyId]);

  const { companyDetail, loading, error } = useSelector((state) => state.company);

  const { highlightData } = useSelector((state) => state.company);



  const { documents } = useSelector((state) => state.document);




  const data = {
    Companylabel: "Company Name",
    Companyvalue: companyDetail ? companyDetail?.company_name : "-",
    Industrylabel: "Industry",
    Industryvalue: companyDetail ? companyDetail?.industry?.industry_name : "-",
    Citylabel: "City, State",
    Cityvalue: companyDetail ? companyDetail?.city ?? companyDetail?.city : ", " + companyDetail?.state_name ?? "-",
    ZipCodelabel: "Zip Code",
    ZipCodevalue: companyDetail ? companyDetail?.zip_code : "-",
    Websitelabel: "Website",
    Websitevalue: companyDetail ? companyDetail?.website_url : "-",
    Countrylabel: "Country",
    Countryvalue: companyDetail ? companyDetail?.country_name : "-",
    CompanySizelabel: "Company Size",
    CompanySizevalue: companyDetail ? companyDetail?.company_size : "-",
  };



  return (
    <>
      {loading && <Spinner />}
      {errorMessage ? <div
        className="message-card pad-20 text-center mt-20 mb-10 error-message"

        style={{

          width: "fit-content",
          marginTop: "250px",
          maxWidth: "100%",
        }}
      >
        <div>{errorMessage}</div>
      </div> :

        (companyDetail &&
          <>
            <HeaderCompanName logo={<img src={`${companyDetail.company_logo}`} style={{ width: "100%", height: "100%", }} alt="company logo" />} bgclr={companyDetail?.header_footer_colour_code} name={companyDetail.company_name} />

            <Col className="pad-20 bg-ligte-gray wrap-company y-scroll">
              {(companyDetail?.landing_page_settings?.show_banner || companyDetail?.landing_page_settings?.show_logo) && (


                <Row className="row-pad-0 virtelligenceTeam-card pb-10 ">
                  <Col md={12} className="p-relative pad-0 ">
                    <div className="virtelligenceTeam-img">
                      {companyDetail?.landing_page_settings?.show_banner && (

                        <img alt="" src={companyDetail?.company_banner || banner} />

                      )}
                    </div>
                    {companyDetail?.landing_page_settings?.show_logo && (
                      <div className="profile-image-view-company p-absolute">
                        <img src={companyDetail?.company_logo || 'default-logo.png'} alt="" />
                      </div>
                    )}
                    {companyDetail?.landing_page_settings?.show_name && (
                      <div className="company-name">
                        <b>{companyDetail?.company_name || 'Company Name'}</b>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
              < Row className="row-pad-0  mt-20">
                <Col md={7} className="">
                  <Row className="pad-20 mt-20 bg-white border-radius-5">
                    <Row className='row-pad-0   mb-10 '>
                      <Col md={12} className='pad-0'><h2 className="sub-heading">{companyDetail?.landing_page_settings?.show_name && `${data?.Companyvalue}`} Company Overview</h2> </Col>
                      <span className='single-border d-block'></span>
                    </Row>

                    <Row className='row-pad-0 pad-0 mt-1 CompanyOverview-body'>
                      <Col md={6} className='' >
                        {companyDetail?.landing_page_settings?.show_name && <div className="content">
                          <label className="label-view ">{data?.Companylabel}:</label><small className="view-text">{data?.Companyvalue}</small>
                        </div>
                        }
                        <div className="content">
                          <label className="label-view " >{data?.Industrylabel}:</label> <small className="view-text">{data?.Industryvalue}</small>
                        </div>
                        <div className="content">
                          <label className="label-view " >{data?.Citylabel}:</label> <small className="view-text" >{data?.Cityvalue}</small>

                        </div>
                        <div className="content">
                          <label className="label-view ">{data?.ZipCodelabel}:</label> <small className="view-text" >{data?.ZipCodevalue}</small>

                        </div>
                      </Col>

                      <Col md={6} className=''>
                        <div className="content">
                          <label className="label-view ">{data?.Websitelabel}:</label> <small className="view-text" >{data?.Websitevalue}</small>
                        </div>
                        <div className="content">
                          <label className="label-view ">{data?.Countrylabel}:</label><small className="view-text" >{data?.Countryvalue}</small>
                        </div>
                        <div className="content">
                          <label className="label-view ">{data?.CompanySizelabel}:</label> <small className="view-text" >{data?.CompanySizevalue == 0 ? "" : data?.CompanySizevalue}</small>
                        </div>
                      </Col>
                    </Row>

                  </Row>
                  {companyDetail?.landing_page_settings?.show_description && (
                    < Row className="pad-20 mt-20 bg-white border-radius-5">
                      <AboutCompany companyDetail={companyDetail} companyName={companyDetail?.landing_page_settings?.show_name} />
                    </Row>
                  )}
                  {companyDetail?.landing_page_settings?.show_images && (
                    <Row className="pad-20 mt-20 bg-white border-radius-5">
                      <Photos company={companyDetail} documents={documents} />
                    </Row>
                  )}
                </Col>

                <Col md={5} className="">
                  {companyDetail?.landing_page_settings?.show_cards && (<Row className="pad-20 mt-20 bg-white border-radius-5 ml-10">
                    <Highlights company={highlightData} />
                  </Row>)}
                  {companyDetail?.landing_page_settings?.show_socials && (
                    <Row className="pad-20 mt-10 bg-white border-radius-5 ml-10">
                      <Connectsocialmedia company={companyDetail} />
                    </Row>
                  )}
                  <Row className="pad-20 mt-20 bg-white border-radius-5 ml-10 pad-10">
                    <Col md={12} className="pad-10 mb-10 ">

                      <JobsLike companyId={companyId} />


                    </Col>


                  </Row>

                  {/* <Col md={12} className="pad-0 mt-20 d-flex justify-content-center">
                  <CustomButton
                    size="lg"
                    // onClick={ProceedInterview}
                    className="btn-full"
                    text="Proceed with the Interview"
                  />
                </Col> */}
                </Col>
              </Row>
            </Col >
          </>
        )}

    </>





  );
};

export default ViewCompanyModel;

