import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Form, Spinner } from 'react-bootstrap';
import { getCandidates } from '../../store/actions/candidateActions';
import { Label } from '../form';

const AllCadidates = ({ Changehandle, ID }) => {
    const dispatch = useDispatch();

    const [search, setSearch] = useState('');
    const [isClearable, setIsClearable] = useState(false);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const { CandidatesData, loading, error } = useSelector((state) => state.candidate);
    console.log(CandidatesData, "getCandidates")
    let candidateList = CandidatesData;

    useEffect(() => {

        const payload = {
            search: search
        }
        dispatch(getCandidates(payload))


    }, [dispatch, search]);

    const handleChange = (selectedOption) => {
        if (selectedOption) {
            Changehandle('select_job', selectedOption.value);
        } else {
            Changehandle('')
        }
    };

    const handleSearchChange = (inputValue) => {

        setSearch(inputValue);


    };

    if (!candidateList && !Array.isArray(candidateList)) {
        return (<>

            <Label text="Select Job " className="label-input" HtmlFor="select_job" required="required" />
            <Form.Select name='select_job'  >
                <option value="">Some thing wrong</option>

            </Form.Select>
        </>)

    }
    const options = candidateList.map((candidate) => ({
        value: candidate.id,
        label: candidate.name,
    }));

    const selectedOption = ID ? options.find((option) => option.value === ID) : null;




    return (
        <>


            <Label text="Select Candidate" className="label-input" HtmlFor="select_job" required="required" />
            <Select
                isDisabled={isDisabled}
                isLoading={loading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                options={options}
                value={selectedOption}
                onChange={handleChange}
                onInputChange={handleSearchChange}
                placeholder="Select Candidate from dropdown"

            />
        </>
    );
};

export default AllCadidates;


{/* <Form.Select name='select_job' id='select_job' onChange={Changehandle} >
<option value="">Select Candidate from dropdown</option>
{candidateList.map(option => (
    <option
        key={option.id}
        value={option.id}

    >
        {option.name}
    </option>
))}
</Form.Select> */}