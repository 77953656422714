import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import { param } from 'jquery';

const config = require('../../config/config.json');
const API_BASE_URL = config.API_BASE_URL;
const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null


export const create = createAsyncThunk(
    'account/create',
    async (payload, thunkAPI) => {

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            }

            const { data } = await axios.post(`${API_BASE_URL}/account-billing-info`, payload, config)

            return data

        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }

        }
    }
)


export const get = createAsyncThunk(
    'account/getCardInformation',
    async (thunkAPI) => {

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            }

            const { data } = await axios.get(`${API_BASE_URL}/account-billing-info`, config)

            return data

        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }

        }
    }
)

export const update = createAsyncThunk(
    'account/updateCardInformation',
    async (thunkAPI) => {

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            }

            const { data } = await axios.get(`${API_BASE_URL}/account-billing-info`, config)

            return data

        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }

        }
    }
)

export const accountUsageInfo = createAsyncThunk(
    'account/account-usage-info',
    async (thunkAPI) => {

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            }

            const { data } = await axios.get(`${API_BASE_URL}/account-usage-info`, config)

            return data

        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }

        }
    }
)
// add packages 
export const getPlan = createAsyncThunk(
    'account/getPlan',
    async (thunkAPI) => {

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            }

            const { data } = await axios.get(`${API_BASE_URL}/packages?package_type=package`, config)

            return data

        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }

        }
    }
)

export const getPackages = createAsyncThunk(
    'account/getPackages',
    async (payload, thunkAPI) => {

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
                params: payload
            }

            const { data } = await axios.get(`${API_BASE_URL}/packages?package_type=credit`, config)

            return data

        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }

        }
    }
)
export const packagePurchased = createAsyncThunk(
    'account/packagePurchased',
    async ({ id, payload }, thunkAPI) => {

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + userToken
                },
            }

            const { data } = await axios.post(`${API_BASE_URL}/package-purchased/${id}`, payload, config)

            return data

        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }

        }
    }
)
export const packageFinal = createAsyncThunk(
    'account/packageFinal',
    async ({ packageId, packagePurchasedId }, thunkAPI) => {




        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            }
            const { data } = await axios.put(`${API_BASE_URL}/package-purchased/${packageId}/${packagePurchasedId}`, null, config)

            return data

        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }

        }
    }
)

export const stripePayment = createAsyncThunk(
    'account/stripePayment',
    async (id, thunkAPI) => {

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            }

            const { data } = await axios.post(`${API_BASE_URL}/package-purchased/${id}`, ' ', config)

            return data

        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }

        }
    }
)

export const updatePackagepurchased = createAsyncThunk(
    'account/updatePackagepurchased',
    async (payload, thunkAPI) => {

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            }

            const { data } = await axios.put(`${API_BASE_URL}/update-package-purchased`, payload, config)

            return data

        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }

        }
    }
)
export const accountStatus = createAsyncThunk(
    'account/accountStatus',
    async (_, thunkAPI) => {

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            }

            const { data } = await axios.get(`${API_BASE_URL}/account-status`, config)

            return data

        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }

        }
    }
)
// tabel 
export const getPackagesAll = createAsyncThunk(
    'account/getPackagesAll',
    async (payload, thunkAPI) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken,
                },
                params: payload
            };


            const { data } = await axios.get(`${API_BASE_URL}/get-package-purchased`, config);

            return data;
        } catch (error) {
            if (error.response && error.response.data) {
                return thunkAPI.rejectWithValue(error.response.data);
            } else {
                return thunkAPI.rejectWithValue({ message: 'An unexpected error occurred' });
            }
        }
    }
);

export const getTransactionsAll = createAsyncThunk(
    'account/transactionsAll',
    async (payload, thunkAPI) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken,
                },
                params: payload
            };


            const { data } = await axios.get(`${API_BASE_URL}/get-transactions`, config);

            return data;
        } catch (error) {
            if (error.response && error.response.data) {
                return thunkAPI.rejectWithValue(error.response);
            } else {
                return thunkAPI.rejectWithValue({ message: 'An unexpected error occurred' });
            }
        }
    }
);
export const downloadInvoice = createAsyncThunk(
    'account/downloadInvoice',
    async (packagePurchaseId, thunkAPI) => {

        try {
            const config = {
                headers: {

                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            }

            const { data } = await axios.get(`${API_BASE_URL}/invoice/${packagePurchaseId}`, config)

            return data

        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }

        }
    }
)

export const getAmountBreakdown = createAsyncThunk(
    'account/ getAmountBreakdown',
    async (id, thunkAPI) => {

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },

            }

            const { data } = await axios.get(`${API_BASE_URL}/get-amount-breakdown/${id}`, config)

            return data

        } catch (error) {

            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.message)
            }

        }
    }
)
