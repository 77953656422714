import { copyicon } from "../../helper/Icons/Icon";

export const FieldConfig = [
  {
    text: "Full Name",
    id: "fullname",
    labelclass: "label-input",
    type: "text",
    placeholder: "Enter your full name",
    required: true,
  },
  {
    text: "Work Email",
    id: "workemail",
    className: "label-input",
    type: "email",
    placeholder: "Enter your work email",
    required: true,
  },
  {
    text: "Company Name",
    id: "companyname",
    className: "label-input",
    type: "text",
    placeholder: "Enter your company name",
    required: true,
  },
  {
    text: "Phone Number",
    id: "phonenumber",
    className: "label-input",
    type: "tel",
    placeholder: "Enter your phone number",
    required: true,
    countryCode: "US",
  },
];
export const loginFields = [
  {
    text: "Email Address",
    id: "email",
    name: "email",
    className: "label-input",
    type: "email",
    placeholder: "Enter your email address",
    required: true,
  },
  {
    text: "Password",
    id: "password",
    name: "password",
    className: "label-input",
    type: "password",
    placeholder: "Enter your password",
    required: true,
  },
]
export const otpFields = [
  {
    text: "5 digits verification code",
    id: "otp",
    name: "otp",
    className: "label-input",
    type: "otp",
    placeholder: "Enter your verification code",
    required: true,
  }
]

export const fieldConfigRequestdemo = [
  { text: "Full Name", id: "name", type: "text", placeholder: "Enter your full name", className: "label-input", required: true, },
  { text: "Work Email", id: "email", type: "text", placeholder: "Enter your valid work email address", className: "label-input", required: true, },
  { text: "Company Name", id: "company_name", type: "text", placeholder: "Enter your company name", className: "label-input", required: true, },

  { text: "Phone Number", id: "phone", type: "tel", placeholder: "Enter your phone number", className: "label-input", required: true, },

];

export const fieldConfiregister = [
  // { text: "sssstimeZone", id: "time_zone_idtttt", type: "", placeholder: "", className: "", required: true, },

  { text: "Password", id: "password", type: "password", placeholder: "Enter your new password here", className: "label-input", required: true, },


  { text: "Confirm Password", id: "confirm_password", type: "password", placeholder: "Enter your confirm password here", className: "label-input", required: true, }


];
export const changepasswordfield = [
  { text: "Current Password", id: "oldPassword", className: "label-input", type: "password", placeholder: "Enter your current password here", required: true },
  { text: "New Password", id: "newPassword", className: "label-input", type: "password", placeholder: "Enter your new password here", required: true },
  { text: "Confirm Password", id: "confirmPassword", className: "label-input", type: "password", placeholder: "Enter your confirm password", required: true }
];


export const actionlistfields = [
  {
    icon: copyicon,
    text: "View Details",
    props: "profile"
  },
  {
    icon: copyicon,
    text: "Change Password ",
    props: "changePassword"
  },

];