

import React from 'react';


const Message = ({ errorMsg }) => {
  return (
    <div className="message-container">
      <div className="message">
        {errorMsg ? errorMsg : "Interview evaluation added successfully"}
      </div>
    </div>
  );
}

export default Message;
