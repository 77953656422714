import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { option, sky } from "../../helper/Icons/Icon";
import Swal from 'sweetalert2';
import TextEditor from '../common/TextEditorLimit';
import { useParams } from 'react-router-dom';
import { addquestionAnswer, updateInterveiewTimeine } from "../../store/actions/InterviewproceedAction";
import { CustomButton } from '../form';
import Spinner from '../common/Spinner';

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 2000
    });
}

const ResponseEditor = ({ question, answer, activeStep, reLoad }) => {
    const { token } = useParams();
    const dispatch = useDispatch();
    const th = useRef();

    const [thinkTime, setThinkTime] = useState(answer?.think_time_insec ?? 0);
    const [wordCount, setWordCount] = useState(answer?.interview_answer?.length || 0);
    const [inputValue, setInputValue] = useState(answer?.interview_answer || "");
    const [mLoading, setMLoading] = useState(false);
    useEffect(() => {
        th.current = setInterval(() => setThinkTime(prevSeconds => prevSeconds + 1), 1000);
        return () => {
            clearInterval(th.current);
        };
    }, []);

    const cleanHTMLTags = (content) => {
        return content
            .replace(/&nbsp;/g, ' ')
            .replace(/<\/?[^>]+(>|$)/g, " ")
            .replace(/\s+/g, ' ')

    };

    const handleChange = (newContent) => {
        const plainText = cleanHTMLTags(newContent);
        const wordArray = plainText.split(' ').filter(Boolean); // Regex \s+ to handle all spaces, tabs, etc.
        const count = wordArray.length;

        if (count > question.max_words) {
            sweetAlert("error", "Error!", `Word limit exceeded! Maximum allowed is ${question.max_words} words.`);
            return;
        }


        setInputValue(newContent);
        setWordCount(count);
    };



    const saveAnswer = () => {
        setMLoading(true)
        const payload = {
            interview_template_question_id: question.interview_template_question_id,
            think_time_insec: thinkTime,
            think_time: Math.ceil(thinkTime / 60),
            response_time: 0,
            retakes: 0,
            interview_answer: inputValue,
            question_text: question.question_text,
            answer_media: question.question_type_name,
        };

        dispatch(addquestionAnswer({ token, payload }))
            .then(() => {
                reLoad(true);
                setMLoading(false)
            })
            .catch(() => {
                sweetAlert("error", "Error!", "Something went wrong!");
            });

        let qNumber = activeStep + 1;
        const log = {
            key: "written",
            log: `Submitted Response for ${question.question_type_name} Question ${qNumber}`,
            date_time: new Date()
        };
        dispatch(updateInterveiewTimeine({ token, log }));

        reLoad(false);
    }

    return (
        <Row className="row-pad-0 pad-10 border-radius-5 interview-media-wrap bg-light-gray mt-15 sm-t-10 ">
            {mLoading && <Spinner />}
            <Col md={12}>
                <h4 className='sub-heading'><span className="mr-5">Enter Response:</span></h4>
                <Form.Group controlId="exampleForm.ControlTextarea1" className='mt-20 p-relative'>
                    <TextEditor
                        textValue={inputValue}
                        handleChange={handleChange}

                        setTextValue={setInputValue}
                        maxWords={question?.max_words}
                        placeholderValue={"Enter your text here"}
                    />
                    <span className={`count-word ${wordCount > question?.max_words ? 'text-danger' : ''}`}>
                        {wordCount}/{question?.max_words}
                    </span>
                </Form.Group>
                <div className='space-between'>
                    <div className="mt-1 only-response-time"><span className="mr-10" >{sky}</span><span className="play-time">Thinking time: {thinkTime}</span></div>

                    {/* <div className='mt-1'><span>Thinking time: {thinkTime}</span></div> */}
                    <div>
                        <CustomButton
                            text="Save"
                            className="text-end custom-btn-primary mt-2"
                            disabled={!inputValue}
                            onClick={saveAnswer}
                        />
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default ResponseEditor;
