import { crossicon, userclick, downloadInterview, acceptcandidate, rejectecandidate, Video, actionlist, share, shareleftarrow, deletegray, downloadarrowblow, view, eye, copyicon, pencil } from "../../helper/Icons/Icon";

export const actionlistfields = [
    // {
    //     icon: eye,
    //     text: "View Detail",
    //     props: "ViewDetail"
    // },
    {
        icon: share,
        text: "Share Interview for Evaluation",
        props: "ShareEvaluation"
    },
    {
        icon: downloadInterview,
        text: "Download Interview",
        props: "DownloadInterview"
    },

    {
        icon: deletegray,
        text: "Delete",
        props: "Delete"
    }
];

export const headertimeLine = [{
    icon: share,
    action: "interviewCandidateLiveEvaluation",
    texttooltip: "Share Candidate's Live Interview for Evaluation"


},
{
    icon: downloadarrowblow,
    action: "interviewdownload",
    texttooltip: "Download",

},
{
    icon: userclick,
    action: "interviewCommentModel",

    texttooltip: " Mark Candidate as Hired"
},


]
export const redordercolumnfields = [
    {

        text: "Job Title"
    },
    {

        text: "Job Type"
    },
    {

        text: "Status"
    },
    {

        text: "Date Updated"
    },
    {

        text: "Date Created"
    },
    {

        text: "Location"
    },
    {

        text: "Workplace Type"
    },
    {

        text: "Pay Type"
    },
    {

        text: "Salary"
    },
    {

        text: "No.of Openings"
    },
    {

        text: "Company"
    }

];