import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Label, InputField, CustomButton } from '../form/index';

import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword, verifyPasswordToken } from '../../store/actions/authActions';
import Swal from 'sweetalert2';
import { vidhireProLogo } from '../../helper/Icons/Icon';
const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 3000
    });
};

const Resetpassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { userToken } = useSelector((state) => state.auth);
    const [apiToken, setApiToken] = useState('');
    const [formData, setFormData] = useState({
        password: '',
        confirm_password: '',
    });
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [message, setMessage] = useState('');

    const Token = localStorage.getItem('userToken') || userToken;
    useEffect(() => {
        if (Token) {
            navigate("/dashboard");
        }
    }, [Token, navigate]);
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get("token");
        if (token) {
            dispatch(verifyPasswordToken({ token }))
                .then((res) => {

                    if (res.payload.error) {
                        setMessage(res.payload.error)
                        sweetAlert(
                            "error",
                            "Error!",

                            `${res.payload.error}!`
                        );

                    }
                    else {

                        setApiToken(token);
                    }
                })
                .catch(error => {

                    sweetAlert("error", "Error!", "Something went wrong!");
                    navigate('/login');
                });
        } else {
            navigate('/login');
        }
    }, [dispatch, location.search, navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        if (name === 'password' || name === 'confirm_password') {
            const passwordValue = name === 'password' ? value : formData.password;
            const confirmPasswordValue = name === 'confirm_password' ? value : formData.confirm_password;


            const passwordMismatchError = passwordValue && confirmPasswordValue && passwordValue !== confirmPasswordValue
                ? 'Passwords do not match'
                : '';

            const passwordLengthError = passwordValue && passwordValue.length < 6
                ? 'Password must be at least 6 characters'
                : '';


            setErrors((prevErrors) => ({
                ...prevErrors,
                confirm_password: passwordMismatchError,
                password: passwordLengthError || (passwordValue.trim() ? '' : 'Required'),
                [name]: value.trim() ? '' : 'Required',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: value.trim() ? '' : 'Required',
            }));
        }
    };




    const UpdatePasswordHandle = (e) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);
        if (formData.password !== formData.confirm_password) {
            validationErrors.confirm_password = 'Passwords do not match';
        }
        if (Object.keys(validationErrors).length === 0) {
            const payload = {
                new_password: formData.password,
                token: apiToken,
            };
            dispatch(updatePassword(payload))
                .then((res) => {
                    if (res.payload.error) {
                        sweetAlert("error", "Error!", `${res.payload.error}!`);
                    } else {
                        navigate('/login');
                        sweetAlert("success", "Success!", res.payload.message);
                    }
                })
                .catch(error => {

                    sweetAlert("error", "Error!", "Something went wrong!");
                });
        } else {
            setErrors(validationErrors);
        }
    };

    const validateForm = (formData) => {
        const errors = {};

        if (!formData.password.trim()) {
            errors.password = 'Password is required';
        }
        if (!formData.confirm_password.trim()) {
            errors.confirm_password = 'Confirm password is required';
        }
        return errors;
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    };

    return (
        <>
            <Container fluid className="pad-0">
                <Row>
                    <Col md={7} sm={6} className="pad-0">
                        <div className="logo"><img src={vidhireProLogo} alt='Logo' /></div>
                        <div className="info-graphics"></div>
                    </Col>
                    <Col md={5} sm={6} className="justify-content-center register-left-side d-flex  align-items-center">
                        {message ? <div className="message-card">
                            <div>{message}</div>
                            {/* <CustomButton onClick={() => setMessage('')} variant="primary" text="Try Again" /> */}
                        </div> :
                            <Form>
                                <Row className='row-pad-0   d-flex justify-content-center align-items-center'>
                                    <Row className="main-heading">
                                        <Col className="text-center">
                                            <h1 className='main-heading'>Vidhirepro</h1>
                                        </Col>
                                    </Row>
                                    <Row className="mb-1 d-flex justify-content-center ">
                                        <Col md={10} className="mt-10 mb-10 p-relative">
                                            <Label text="Password" className="label-input" required="required" />
                                            <InputField
                                                type={showPassword ? 'text' : 'password'}
                                                id="password"
                                                placeholder=""
                                                name="password"
                                                value={formData.password}
                                                onChange={handleChange}
                                                required
                                            />
                                            <span
                                                onClick={togglePasswordVisibility}
                                                className={`password-faEye fa fa-fw field-icon ${showPassword ? 'fa-eye-slash' : 'fa-eye'
                                                    }`}
                                            ></span>
                                            {errors.password && <p className="error-message">{errors.password}</p>}
                                        </Col>
                                        <Col md={10} className="mt-10 mb-10 p-relative">
                                            <Label text="Confirm Password" className="label-input" />
                                            <InputField
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                id="confirm_password"
                                                placeholder=""
                                                name="confirm_password"
                                                value={formData.confirm_password}
                                                onChange={handleChange}
                                                required
                                            />
                                            <span
                                                onClick={toggleConfirmPasswordVisibility}
                                                className={`password-faEye fa fa-fw field-icon ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'
                                                    }`}
                                            ></span>
                                            {errors.confirm_password && <p className="error-message">{errors.confirm_password}</p>}
                                        </Col>
                                    </Row>
                                    <Col className='col-md-10 d-flex justify-content-center '>
                                        <Row className="mb-10 mt-20 row-pad-0" style={{ width: "95%", }}>
                                            <CustomButton text='Update' className="custom-btn-primary" onClick={UpdatePasswordHandle} />
                                        </Row>
                                    </Col>
                                    <Col className='text-end col-md-10'>
                                        <span className="mr-10">Already a member? <Link to="/login" className='custom-link'>Sign In</Link></span>
                                    </Col>
                                </Row>
                            </Form>
                        }
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Resetpassword;
