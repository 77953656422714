import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Offcanvas, Row, Container, Col } from "react-bootstrap";
import Spinner from "./Spinner";
import { Checkbox, CustomButton, InputField, Label, SelectField } from "../form";
import { crossicon } from "../../helper/Icons/Icon";
import { getModuleFields } from "../../store/actions/tableFieldsActions";
import { create, getByName } from "../../store/actions/userPrefrenceActions";
import "../../css/actionlist.css";
import Swal from "sweetalert2";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 3000
    });
};

const ReorderColumns = (props) => {
    const [originalPNames, setOriginalPNames] = useState([]); // Copy of original data
    const [pNames, setPNames] = useState([]);
    const { module, handleClose, headertext } = props;
    const dispatch = useDispatch();

    const fields = useSelector((state) => state.tableField.fields);
    const { userPrefrenceDetail, loading, error } = useSelector((state) => state.userPrefrence);
    let selectedFilter = userPrefrenceDetail?.preference_value?.order_by;
    const [sortingOrder, setSortingOrder] = useState(selectedFilter);
    const prefrenceName = `${module}_listing`;

    useEffect(() => {
        dispatch(getByName(prefrenceName));
        dispatch(getModuleFields(module));
    }, [dispatch, module, prefrenceName]);

    useLayoutEffect(() => {
        if (userPrefrenceDetail?.preference_value?.fields) {
            const savedPrefVals = userPrefrenceDetail.preference_value.fields;
            setSortingOrder(userPrefrenceDetail?.preference_value?.order_by);
            setPNames(savedPrefVals);
            setOriginalPNames(savedPrefVals)
        } else {
            let pnames = [];
            if (fields.length > 0) {
                fields.forEach((item) => {
                    pnames.push({ key: item.field_name, label: item.field_label, checked: item.is_default_listing });
                });
            }
            setPNames(pnames);
            setOriginalPNames(pnames)
            setSortingOrder("updated_date")
        }


    }, [fields, userPrefrenceDetail]);

    const showSelectedSort = () => {
        return [{ value: "", label: "Select Default Sorting Column" }].concat(
            fields.map((item) => ({
                value: item.field_name,
                label: item.field_label,
            }))
        );
    };

    const handleResetCheckboxs = () => {
        setSortingOrder("updated_date")
        const defaultFields = fields
            .map((item) => ({ key: item.field_name, label: item.field_label, checked: item.is_default_listing }));

        const data = {
            preference_value: {
                order_by: "updated_date",
                fields: defaultFields,
            },
            preference_name: prefrenceName,
            module_code: module,
        };

        dispatch(create(data))
            .then(() => {
                handleClose();
                sweetAlert("success", "Success!", "Columns restored successfully");

            })
            .catch(() => {
                sweetAlert("error", "Error!", "Something went wrong!");
            });
    };

    const handleSelectAllChange = (e) => {
        const checked = e.target.checked;
        setPNames(pNames.map(item => ({ ...item, checked })));
    };

    const handleCheckboxChange = (e, key) => {
        const { checked } = e.target;
        setPNames(prevList =>
            prevList.map((item, i) =>
                i === key ? { ...item, checked: checked } : item
            )
        );
    };

    const saveUserPreference = () => {
        // Merge updated fields with the original fields
        let updatedFields = pNames;
        if (pNames.length !== originalPNames.length) {
            updatedFields = originalPNames.map((originalItem) => {
                const updatedItem = pNames.find((pName) => pName.key === originalItem.key);
                return updatedItem ? { ...originalItem, checked: updatedItem.checked } : originalItem;
            });
        }

        const allItemsUnchecked = updatedFields.every((item) => !item.checked);
        if (allItemsUnchecked) {
            sweetAlert("error", "Error!", "Please select at least one column!");
            return false;
        }

        const data = {
            preference_value: {
                order_by: sortingOrder,
                fields: updatedFields,
            },
            preference_name: prefrenceName,
            module_code: module,
        };

        dispatch(create(data))
            .then(() => {
                handleClose();
                sweetAlert("success", "Success!", "Columns edited successfully");
            })
            .catch(() => {
                sweetAlert("error", "Error!", "Something went wrong!");
            });

    };

    const filteredFields = (e) => {
        const query = e.target.value.toLowerCase();

        if (query) {
            const filteredNames = originalPNames.filter((item) =>
                item.label.toLowerCase().includes(query)
            );

            setPNames(filteredNames);
        } else {
            setPNames(originalPNames);
        }
    };

    // Handle drag end
    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedPNames = Array.from(pNames);
        const [movedItem] = reorderedPNames.splice(result.source.index, 1);
        reorderedPNames.splice(result.destination.index, 0, movedItem);

        setPNames(reorderedPNames);
    };

    return (
        <Offcanvas
            show={true}
            onHide={handleClose}
            placement="end"
            className="bg-ligte-gray custom-offcanvas"
            style={{ width: "40%" }}
        >
            <Container fluid className="row-pad-0 pad-0 pad-20">
                <Offcanvas.Header className="model-header bg-white  actionlist-header">
                    <Offcanvas.Title>
                        <span className="model-header-svg">
                            {headertext}</span>
                    </Offcanvas.Title>
                    <div className="text-end">
                        <CustomButton
                            onClick={handleClose}
                            className="text-end close-btn"
                            text={crossicon}
                        />
                    </div>
                </Offcanvas.Header>

                {loading &&
                    <Spinner />
                }
                <Col md={12} className="pad-10 InviteCandidate-wrap">
                    <Row className="row-pad-0">
                        <p>
                            Change column order according to your ease and make sure only
                            checked/selected columns will be shown in current module
                        </p>
                    </Row>
                    <Row className="row-pad-0">
                        <Col md={12} className="text-end">
                            <CustomButton
                                onClick={saveUserPreference}
                                className="ml-10 mr-10 custom-btn-primary"
                                text="Save"
                            />
                            <CustomButton
                                onClick={handleResetCheckboxs}
                                text="Reset"
                                className="custom-btn-outline"
                            />
                        </Col>
                    </Row>
                    <Row className="row-pad-0">
                        <Col md={6}>
                            <Label text="Sort Listing By" tooltip=" Selected column should be checked below for sorting to work." icon={<i style={{ marginLeft: "5px" }} className="fa fa-info-circle"></i>} />
                            <SelectField options={showSelectedSort()} onChange={(e) => setSortingOrder(e.target.value)} name={selectedFilter} />
                        </Col>
                        <Col md={6} className="text-end mt-31">
                            <InputField
                                type="text"
                                // value={searchQuery}
                                onChange={filteredFields}
                                placeholder="Search Columns"
                            />
                        </Col>
                    </Row>

                    <Row className="row-pad-0 mt-10">
                        <Col className="col-md-12">
                            <span>
                                <Checkbox
                                    label="Select All"
                                    checked={pNames.every((item) => item.checked === true)}
                                    onChange={handleSelectAllChange}
                                />
                            </span>
                        </Col>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="reorderColumns">
                                {(provided) => (
                                    <ul className="ReorderColumns-nav" {...provided.droppableProps} ref={provided.innerRef}>
                                        {pNames && pNames.map((item, index) => (
                                            <Draggable key={item.key} draggableId={item.key} index={index}>
                                                {(provided) => (
                                                    <li
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="moveableField"
                                                    >
                                                        <a href="#">
                                                            <span>
                                                                <Checkbox
                                                                    value={item.key}
                                                                    checked={item.checked === true}
                                                                    onChange={(e) => handleCheckboxChange(e, index)}
                                                                />
                                                            </span>
                                                            <span>{item.label}</span>
                                                        </a>
                                                        <span>
                                                            <i className="fa fa-arrows"></i>
                                                        </span>
                                                    </li>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </ul>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Row>

                    <Col md={12} className="mt-10 mb-20">
                        <CustomButton
                            onClick={saveUserPreference}
                            className="ml-10 mr-10 custom-btn-primary"
                            text="Save"
                        />
                        <CustomButton
                            onClick={handleResetCheckboxs}
                            text="Reset"
                            className="custom-btn-outline"
                        />
                    </Col>
                </Col>
            </Container>
        </Offcanvas>
    );
};

export default ReorderColumns;