import { Button, Col, Row, Modal } from "react-bootstrap";
import { sky, Video, time, bell, } from "../../helper/Icons/Icon"
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import interview from "../../assets/images/interview.jpg";

import { CustomButton } from "../form";
const PracticeQuestion = () => {
  const { token } = useParams();
  const videotag = useRef(null);
  const mediaRecorder = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [error, setError] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [suggestionMsg, setSuggestionMsg] = useState("");
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [prevRecording, setPrevRecording] = useState(false);
  const [preData, setPreData] = useState(null)
  const navigate = useNavigate();
  const [retakeVideo, setRetakeVideo] = useState(false);
  const location = useLocation();
  const [videoChunks, setVideoChunks] = useState([]);
  const PracticeProceed = () => {

    navigate(`/system-check/${token}`);
  }

  useEffect(() => {

    if (isRecording) {
      const timer = setInterval(() => {
        setCurrentTime(prevTime => prevTime + 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isRecording]);

  const startRecording = async () => {

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      videotag.current.srcObject = stream;
      mediaRecorder.current = new MediaRecorder(stream);

      setIsRecording(true);

      mediaRecorder.current.start();
      const chunks = [];

      mediaRecorder.current.ondataavailable = (event) => {
        chunks.push(event.data);
      };
      setVideoChunks(chunks);


    } catch (error) {
      let errorMessage = "Error: Could not start video source";
      if (error.name === "NotReadableError") {
        errorMessage +=
          ". This could be due to permission issues, browser support, or device availability.";
        setSuggestionMsg("Please check your browser settings, ensure that your browser supports media devices, and verify that the camera and microphone are properly connected and accessible.");
      } else if (error.name === "NotAllowedError") {
        errorMessage += ". Please grant permission to access the camera and microphone.";
        setSuggestionMsg("Click on the browser's lock icon in the address bar to grant permission to access the camera and microphone.");
      }
      setError(errorMessage);
      setShowErrorModal(true);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
      setRetakeVideo(true)
    }
    mediaRecorder.current.onstop = () => {
      const blob = new Blob(videoChunks, { type: 'video/webm' });
      var reader = new FileReader();
      reader.readAsDataURL(blob);

      reader.onloadend = function () {
        var base64data = reader.result;



        const videoUrl = window.URL.createObjectURL(blob);
        setRecordedBlob(videoUrl)
      }
    }
  };

  const retakeHandel = () => {
    setRecordedBlob(null);
    setIsRecording(false);
  }
  const handleCloseErrorModal = useCallback(() => {
    setShowErrorModal(false);
    setError(null);
    setSuggestionMsg("");
  }, []);
  return (

    <>
      <Row className="row-pad-0">
        <h5 className="sub-heading mt-10 ">Practice Question</h5>
        <Row className="bg-white pad-20 mt-10 radius-7 sm-pad-0   row-pad-0">
          <Col md={12} className=" mt-10 sm-m-0 d-flex justify-content-between sm-flex-wrap flex-wrap">

            <div className="system-card ">
              <div className="syetem-icon sm-t-8 sm-flex-align-start">
                {bell}
              </div>
              <div className="system-msg question-text">
                <small className="system-text">You are currently in a practice mode. Please take a moment to unwind and familiarize yourself with the process, as no data will be retained.</small>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="row-pad-0 bg-white border-radius-5 pad-20 sm-pad-0 mt-20" >



          <Col md={12} className=" mt-10 d-flex justify-content-between sm-flex-wrap ">
            <div className="system-card ">
              <div className="syetem-icon">
                {Video}

              </div>
              <div className="system-msg">
                <small className="system-text">Video Question: How are you doing today?</small>

              </div>

            </div>

            {/*  */}
            <div className="system-card ">
              <div className="syetem-icon">
                {sky}

              </div>
              <div className="system-msg">
                <small className="system-text">Allowed Think Time:</small>
                <small className="system-text-span">2:00 Mins</small>

              </div>

            </div>
            <div className="system-card ">
              <div className="syetem-icon">
                {time}

              </div>
              <div className="system-msg">
                <small className="system-text">Allowed Answer Duration:</small>
                <small className="system-text-span">2:00 Mins</small>

              </div>

            </div>


          </Col>

          <Col md={12} className="mt-20">
            <Row className="row-pad-0 pad-20 sm-pad-0 border-radius-5 Practice-Question-wrap bg-ligte-gray ">



              <Row className="row-pad-0 pad-10 border-radius-5 interview-media-wrap bg-ligte-gray">
                <Col md={12} className="RecordVideo-wrap sm-pad-0">
                  <h4 className="ml-20 sm-ml-10">
                    <span className="mr-10">{Video}</span>Record Video
                  </h4>
                  <div className="video-section  sm-t-10  ">

                    {!recordedBlob && (<>
                      <div class="status">Ready</div>
                      <video height="100%" autoPlay muted style={{ background: "black", borderRadius: "10px" }} ref={videotag} />
                    </>)}
                    {recordedBlob &&
                      <video height="100%" controls>
                        <source src={`${recordedBlob}`} type="video/webm" />
                        Your browser does not support the video tag.
                      </video>
                    }

                    <div className="">

                      <div className="">
                        {!recordedBlob && (
                          <>
                            <div className="retake-btn-wrap ">
                              <CustomButton text="Record Now" disabled={isRecording} className=" custom-btn-primary mt-10 mr-10 " onClick={startRecording} />
                              <CustomButton text="save and continue" disabled={!isRecording} className="custom-btn-outline  mt-10" onClick={stopRecording} />
                            </div>
                            <span className="ml-20 sm-ml-10" style={{ display: isRecording ? 'block' : 'none' }}>Response Time: {currentTime}</span>
                          </>
                        )}
                        {recordedBlob && (
                          <CustomButton className="custom-btn-primary video-retak-btn" onClick={() => retakeHandel()} text="Retake" />)}
                      </div>

                    </div>
                  </div>


                  {/* Error Modal */}
                  <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {error}
                      {suggestionMsg && <p><strong>Suggestion:</strong> {suggestionMsg}</p>}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseErrorModal}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Col>
              </Row>






            </Row>
          </Col>



        </Row>
        <Col md={12} className="text-end pad-0 mt-20 mb-20">
          <CustomButton className="custom-btn-primary sm-proceed-btn" onClick={PracticeProceed} text="Proceed" />
        </Col>

      </Row>

    </>)
}


export default PracticeQuestion;