import Accordion from 'react-bootstrap/Accordion';
import { Row, Col, Spinner } from "react-bootstrap";
import { edit } from '../../helper/Icons/Icon';
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import JobDetail from '../job/JobDetail';
import Profile from '../../pages/Profile';
import CandidatesDetail from '../candidate/CandidatesDetail';
import { profileActivityLog } from '../../store/actions/activityLogsActions';
import * as moment from 'moment';
import { Navigate, useNavigate } from 'react-router-dom';
const TimeLine = ({ id }) => {
  const [isAccordionOpen, setAccordionOpen] = useState(true);
  const [showDetailPage, setShowDetailPage] = useState(false);
  const [showProfile, setProfileShow] = useState(false);
  const [timelineLimitData, SettimelineLimitData] = useState(10)
  const [showCandidatesDetailPage, setCandidatesDetailPage] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleAccordion = () => {
    setAccordionOpen(!isAccordionOpen);
  };

  const { profileLogs, loading, error } = useSelector((state) => state.activityLogs);

  useEffect(() => {

    const payload = {
      id: id,
      limit: timelineLimitData,
    }
    dispatch(profileActivityLog(payload));

  }, [dispatch, timelineLimitData]);

  const handleLogClick = (log) => {

    if (log.module_code == "jobs") {

      setSelectedJob(log.module_pk_id);
      setShowDetailPage(true);


    }
    if (log.module_code == "companies") {
      sessionStorage.setItem('companyID', log.module_pk_id);
      window.open('/company', '_blank');
    }
    if (log.module_code == "candidates") {
      setSelectedJob(log.module_pk_id);
      setCandidatesDetailPage(true);

    }

  };
  const handleLogClose = (logtype) => {
    if (logtype == "jobs") {
      setShowDetailPage(false);
      setCandidatesDetailPage(false);
    }
    if (logtype == "candidates") {
      setCandidatesDetailPage(false);
      setShowDetailPage(false);
    }






  };
  // const UserProfileModel = (log) => {
  //   setSelectedJob(log.created_by);
  //   setProfileShow(true)

  // }
  const viewMore = () => {

    SettimelineLimitData((prev) => prev + 10)
  }

  return (
    <>
      <Accordion className='pad-0 bg-white border-radius-5  '>
        <Row md={12} className='pad-5 mt-1 m-1 bg-white border-radius-5 row-pad-0'>
          <Col md={6} className='d-flex justify-content-start align-items-center ' ><h5 className='sub-heading mt-1'>Timeline</h5></Col>
          <Col className="d-flex justify-content-end align-items-center" md={6}>
            <a href="#" className='ml-10 ' onClick={toggleAccordion}>
              <i className={`fa ${isAccordionOpen ? 'fa-angle-up' : ' fa-angle-down'} clr-light-black`} style={{ fontSize: "24px" }}></i>
            </a>
          </Col>
        </Row>
        <span className='single-border d-block'></span>

        <Accordion.Collapse in={isAccordionOpen} style={{ height: 'calc(840px - 200px)', overflowY: 'auto' }}>
          <Row className='row-pad-0'>
            {loading && <Spinner />}
            <Col md={12} className="activity-feedHeader" style={{ height: '500px', overflowY: 'auto' }}>
              {profileLogs && Array.isArray(profileLogs) && profileLogs.length > 0 ? (
                <>


                  {
                    profileLogs.map((label, index) => (
                      <div className="activity-feed" key={index}>
                        <div className="feed-item mb-10">
                          <div className="date">
                            <i>{edit}</i> | {label.created_by_name}<span className="pull-right">{moment(label.created_date).format('MM-DD-YYYY h:mm A')}</span>
                          </div>
                          <div className="text mt-1">
                            {
                              label.module_code === "candidates" ? "Candidate" :
                                label.module_code === "jobs" ? "Job" :
                                  label.module_code === "documents" ? "" :
                                    label.module_code === "rating" ? '' :
                                      label.module_code === "companies" ? "Company" :
                                        label.module_code === "interviews" ? "Interview" :
                                          label.module_code === "accounts" ? "Account" :
                                            label.module_code === "users_preferences" ? '' :
                                              label.module_code === "meeting_schedulars" ? `${label.field_name} Meeting schedulars` :
                                                label.module_code === "users" ? "User" :
                                                  label.module_code === "candidates_invitation" ? "Candidate invitation" :
                                                    label.module_code === "interview_templates" ? "Interview template" :
                                                      label.module_code.includes('_') ?
                                                        label.module_code.replace(/_/g, " ") :
                                                        label.module_code
                            }

                            {/* <span>===={label.module_code}==={label.activity}</span> */}

                            {
                              (label.module_code === "candidates" || label.module_code === "jobs" || label.module_code === "companies")
                                ? (

                                  label.activity.toLowerCase().includes('deleted') ?
                                    <span style={{ fontWeight: "bold" }}> {
                                      label.field_name && label.field_name.includes('_')
                                        ? label.field_name.replace(/_/g, "   ")
                                        : label.field_name
                                    } </span> :
                                    <a href='#' onClick={() => handleLogClick(label)} style={{ marginLeft: "5px", marginRight: "5px" }}>
                                      {label.field_name && label.field_name.includes('_')
                                        ? label.field_name.replace(/_/g, "   ")
                                        : label.field_name}
                                    </a>
                                ) : (

                                  <span style={{ marginLeft: "5px", marginRight: "5px", fontWeight: "bold" }}>
                                    {(label.module_code === "meeting_schedulars" || label.module_code === "rating") ? '' :

                                      label.field_name && label.field_name.includes('_')
                                        ? label.field_name.replace(/_/g, "   ")
                                        : label.field_name

                                    }
                                  </span>


                                )
                            }


                            {label.module_code === "rating" ?
                              <span>
                                {label.activity.includes('_') ? (
                                  <>
                                    {label.activity.replace(/_/g, " ")}
                                    <small style={{ fontWeight: "bold" }}>{label.field_name}</small>
                                  </>
                                ) : (
                                  <>
                                    {label.activity}
                                    <small style={{ fontWeight: "bold" }}>{label.field_name}</small>
                                  </>
                                )}
                              </span>

                              : <span>
                                {label.activity.includes('_')
                                  ? label.activity.replace(/_/g, " ")
                                  : label.activity}
                              </span>

                            }
                          </div>

                          <span className='single-border d-block mt-1'></span>
                        </div>
                      </div>
                    ))}

                  {timelineLimitData > profileLogs.length ? null :
                    (
                      <Col md={12} className="view-more-line">
                        <div onClick={viewMore} className="view-more">
                          View More
                        </div>
                      </Col>

                    )}
                </>

              ) : (
                <p>No activity logs found.</p>

              )}


            </Col>
          </Row>
        </Accordion.Collapse>
      </Accordion >
      {showDetailPage && (
        <>

          <JobDetail handleClose={() => handleLogClose("jobs")} jobId={selectedJob} />
        </>
      )
      }
      {showCandidatesDetailPage && <CandidatesDetail handleClose={() => handleLogClose("candidates")} candidateId={selectedJob} />}
      {showProfile && <Profile handleClose={() => handleLogClose("UserProfile")} profileId={selectedJob} />}
    </>
  );
}

export default TimeLine;
