import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { uploadfile } from '../../helper/Icons/Icon';
import { CustomButton } from '../form';
import Swal from 'sweetalert2';
import { option, sky } from "../../helper/Icons/Icon";
import Spinner from "../common/Spinner";
import { useParams } from 'react-router-dom';
import { addquestionAnswer, updateInterveiewTimeine, addquestionAnswerBlob } from "../../store/actions/InterviewproceedAction";
const config = require('../../config/config.json');

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 2000
    });
}

const Fileupload = ({ question, answer, activeStep, reLoad }) => {
    const { token } = useParams();
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [fileName, setFileName] = useState(answer?.interview_answer || "");
    const [fileSize, setFileSize] = useState(null);
    const [thinkTime, setThinkTime] = useState(answer?.think_time_insec ?? 0);
    const { loading, error } = useSelector((state) => state.Interviewproceed);
    const th = useRef();
    const [mLoading, setMLoading] = useState(false);


    useEffect(() => {
        th.current = setInterval(() => setThinkTime(prevSeconds => prevSeconds + 1), 1000);
    
        return () => {
            clearInterval(th.current);
        };
    }, [thinkTime]);

    const handleUpload = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = useCallback((event) => {
        const file = event.target.files[0];
    
        if (file) {
       
            if (file.size > 5 * 1024 * 1024) {
                Swal.fire("Error", "File size exceeds 5MB limit.", "error");
                return;
            }
    
       
            const allowedExtensions = /(\.docx|\.doc|\.pdf|\.txt)$/i;
            if (!allowedExtensions.exec(file.name)) {
                Swal.fire("Error", "Invalid file type. Only .docx, .doc, .pdf, .txt are allowed.", "error");
                return;
            }
    
            setFileName(file.name);
            setFileSize((file.size / (1024 * 1024)).toFixed(2));
    
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                const base64data = reader.result;
                setMLoading(true);
    
                const formData = new FormData();
                formData.append("interview_template_question_id", question.interview_template_question_id);
                formData.append("retakes", 0);
                formData.append("response_time", 0);
                formData.append("response_time_insec", thinkTime);
                formData.append("think_time", Math.ceil(thinkTime / 60));
                formData.append("interview_answer", file.name);
                formData.append("interview_answer_encoded", file);
                formData.append("question_text", question.question_text);
                formData.append("answer_media", question.question_type_name);
    
                dispatch(addquestionAnswerBlob({ token, formData }))
                    .then((res) => {
                    
                        reLoad(!mLoading);
                        setMLoading(false);
                        sweetAlert("success", "Success!", "File uploaded successfully")
    
                        const qNumber = activeStep + 1;
                        const log = {
                            key: 'file_upload',
                            log: `Submitted Response for ${question.question_type_name} Question ${qNumber}`,
                            date_time: new Date()
                        };
                        dispatch(updateInterveiewTimeine({ token, log }));
                    })
                    .catch((error) => {
                        setMLoading(false);
                        sweetAlert("error", "Error!", "Something went wrong. Please try again!");
                    });
            };
        }
    }, [question, token, thinkTime, activeStep, dispatch, reLoad]);
    
    

    const splitName = (fileName) => {
        return fileName.split('/').pop();
    };

    return (
        <Row className="row-pad-0 pad-10 border-radius-5 interview-media-wrap bg-light-gray sm-t-10  mt-15">
            {mLoading && <Spinner />}
            <div>
                <h4 className="sub-heading"><span className="mr-5">Upload File:</span></h4>
                <Row className="row-pad-0">
                    <Col md={12} className="bg-white border-radius-5 FileUploadView-wrap mt-10">
                        <div className="file-icon">{uploadfile}</div>
                        <div className="file-wrap mt-10">
                            <input
                                type="file"
                                ref={fileInputRef}
                                className="hide"
                                onChange={handleFileChange}
                            />
                            <div>
                                <CustomButton text="Upload File" style={{ width: "151.54px" }} className="custom-btn-outline mb-10" onClick={handleUpload} />
                            </div>
                            <span className="file-name"><small>Allowed Extension: </small><small>.docx, .doc, .pdf, .txt</small></span>
                            <span className="file-size"><small>Allowed Limit: </small>5MB</span>

                            {fileName && (
                                <div className="mt-2 file-name-overflow">
                                    <span className="file-name"><strong>File Name:</strong> {splitName(fileName)} </span>
                                    <br />
                                    {fileSize && <span className="file-size"><strong>File Size:</strong> {fileSize} MB</span>}
                                </div>
                            )}
                        </div>
                    </Col>
                    <div className="mt-1 only-response-time"><span className="mr-10" >{sky}</span><span className="play-time">Thinking time: {thinkTime}</span></div>
                    
                    {/* <div className="mt-1 pad-0"><span>Thinking time: {thinkTime}</span></div> */}
                </Row>
            </div>
        </Row>
    );
};

export default Fileupload;





// const Fileupload = ({ question, answer, activeStep, reLoad }) => {
//     const { token } = useParams();
//     const dispatch = useDispatch();
//     const fileInputRef = useRef(null);
//     const [fileName, setFileName] = useState(answer?.interview_answer || "");
//     const [fileSize, setFileSize] = useState(null);
//     const [thinkTime, setThinkTime] = useState(answer?.think_time_insec ?? 0);
//     const { loading, error } = useSelector((state) => state.Interviewproceed);
//     const th = useRef();
//     const [mLoading, setMLoading] = useState(false);
//     useEffect(() => {
//         th.current = setInterval(() => setThinkTime(prevSeconds => prevSeconds + 1), 1000);
    
//         return () => {
//             clearInterval(th.current);
//         };
//     }, [thinkTime]);

//     const handleUpload = () => {

    
//         fileInputRef.current.click();
//     };

//     const handleFileChange = (event) => {
//         const file = event.target.files[0];

//         if (file) {
      
//             if (file.size > 5 * 1024 * 1024) { // 5MB
//                 Swal.fire("Error", "File size exceeds 5MB limit.", "error");
//                 return;
//             }

 
//             const allowedExtensions = /(\.docx|\.doc|\.pdf|\.txt)$/i;
//             if (!allowedExtensions.exec(file.name)) {
//                 Swal.fire("Error", "Invalid file type. Only .docx, .doc, .pdf, .txt are allowed.", "error");
//                 return;
//             }

//             setFileName(file.name);
//             setFileSize((file.size / (1024 * 1024)).toFixed(2)); // Size in MB

//             const reader = new FileReader();
//             reader.readAsDataURL(file);
//             reader.onloadend = () => {
//                 const storedThinkTime = localStorage.getItem('storedthinkTime');

//                 const base64data = reader.result;
//                 setMLoading(true)
//                 // const payload = {
//                 //     interview_template_question_id: question.interview_template_question_id,
//                 //     retakes: 0,
//                 //     response_time: 0,
//                 //     think_time_insec: thinkTime,
//                 //     think_time: Math.ceil(thinkTime / 60),
//                 //     interview_answer: file.name,
//                 //     interview_answer_encoded: base64data,
//                 //     answer_media: question.question_type_name,
//                 //     question_text: question.question_text,
//                 // };
//                 const formData = new FormData();
//                 formData.append("interview_template_question_id", question.interview_template_question_id);
//                 formData.append("retakes", 0);
//                 formData.append("response_time", 0);
//                 formData.append("response_time_insec", storedThinkTime);
//                 formData.append("think_time", Math.ceil(storedThinkTime / 60));
//                 formData.append("interview_answer", file.name);
//                 formData.append("interview_answer_encoded", file);
//                 formData.append("question_text", question.question_text);
//                 formData.append("answer_media", question.question_type_name);
//                 dispatch(addquestionAnswerBlob({ token, formData })).then((res) => {
//                     reLoad(true);
//                     setMLoading(false)
//                     localStorage.removeItem('storedthinkTime');
//                 })
//                     .catch(() => {
//                         sweetAlert(
//                             "error",
//                             "Error!",
//                             "Something went wrong!"
//                         );
//                     });
//                 // dispatch(addquestionAnswer({ token, payload }))
//                 //     .then((res) => {
//                 //         reLoad(true)
//                 //     })
//                 //     .catch(() => {
//                 //         sweetAlert(
//                 //             "error",
//                 //             "Error!",
//                 //             "Something went wrong!"
//                 //         );
//                 //     });

//                 let qNumber = activeStep + 1;
//                 const log = {
//                     key: 'file_upload',
//                     log: `Submitted Response for ${question.question_type_name} Question ${qNumber}`,
//                     date_time: new Date()
//                 };
//                 dispatch(updateInterveiewTimeine({ token, log }));

//                 reLoad(false)

//             };
//         }
//     };
//     const splitName = (fileName) => {
//         return fileName.split('/').pop();

//     }
//     return (

//         <Row className="row-pad-0 pad-10 border-radius-5 interview-media-wrap bg-light-gray sm-t-10  mt-15">
//             {mLoading && <Spinner />}
//             <div>
//                 <h4 className="sub-heading"><span className="mr-5">Upload File:</span></h4>
//                 <Row className="row-pad-0">
//                     <Col md={12} className="bg-white border-radius-5 FileUploadView-wrap mt-10">
//                         <div className="file-icon">{uploadfile}</div>
//                         <div className="file-wrap mt-10">
//                             <input
//                                 type="file"
//                                 ref={fileInputRef}
//                                 className="hide"
//                                 onChange={handleFileChange}
//                             />
//                             <div><CustomButton text="Upload File" style={{ width: "151.54px" }} className="custom-btn-outline mb-10" onClick={handleUpload} /></div>
//                             <span className="file-name"><small>Allowed Extension: </small><small>.docx, .doc, .pdf, .txt</small></span>
//                             <span className="file-size"><small>Allowed Limit: </small>5MB</span>

//                             {fileName && (
//                                 <div className="mt-2">
//                                     <span className="file-name"><strong>File Name:</strong> {splitName(fileName)} </span>
//                                     <br />
//                                     {fileSize && <span className="file-size"><strong>File Size:</strong> {fileSize} MB</span>}
//                                 </div>
//                             )}
//                         </div>
//                     </Col>
//                     <div className="mt-1 pad-0"><span>Thinking time: {thinkTime}</span></div>
//                 </Row>
//             </div>
//         </Row>
//     );
// };

// export default Fileupload;
