
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Video from "twilio-video";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stopScreenShareIcon } from "../../helper/Icons/Icon";
import { faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { Modal, Col, Row, Form, Button, Alert, Tooltip, OverlayTrigger } from "react-bootstrap";
import { adduser, leaveMeeting } from "../../helper/Icons/Icon";
import { InputField, Label, CustomButton } from "../form";
import { useDispatch } from "react-redux";
import Swal from 'sweetalert2';
import { addParticipant, CallLliveInterview, closeMeetingRoom } from "../../store/actions/InterviewproceedAction";
import { updateInterveiewTimeine, UpdateInterviewByToken } from "../../store/actions/InterviewproceedAction";
import Spinner from "../common/Spinner";
import $ from 'jquery';
import TextEditor from "../common/TextEditor";
import StarRating from 'react-rating-stars-component';
const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 3000
    });
}

const LiveInterview = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [showModalFeedBack, setShowModalFeedBack] = useState(false);
    const [hoveredTooltip, setHoveredTooltip] = useState(null);
    const [roomName, setRoomName] = useState(localStorage.getItem('roomNamel') ?? null);
    const [roomId, setRoomID] = useState(null);
    const [candidateName, setcandidateName] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const [rating, setRating] = useState(0);
    const [comments, setComments] = useState("");
    const [errorsFeedback, setErrorsFeedback] = useState({});
    const [contentEditor, setContentEditor] = useState(`<p>Hi,</p><p>Please visit the link to join live video call</p><p>[link]</p><p>Regards,</p>`);
    const [formData, setFormData] = useState({
        invite_message: contentEditor,
        email: '',
    });
    const [errors, setErrors] = useState({
        invite_message: '',
        email: '',
    });
    const [meetingLeft, setMeetingLeft] = useState(false);
    const [error, setError] = useState('');
    const [audioTrack, setAudioTrack] = useState(null);
    const [isAudioMuted, setIsAudioMuted] = useState(false);
    const [videoTrack, setVideoTrack] = useState(null);
    const [isVideoMuted, setIsVideoMuted] = useState(false);
    const [room, setRoom] = useState(null);
    const [screenTrack, setScreenTrack] = useState(null);
    const [isScreenSharing, setIsScreenSharing] = useState(false);
    const [participantsCount, setParticipants] = useState([]);
    let activeScreenSharingParticipant = null;
    const logEvent = (key, logMessage) => {
        const payload = {
            token: params.token,
            log: {
                key: key,
                log: logMessage,
                date_time: new Date(),
            }
        };
        dispatch(updateInterveiewTimeine(payload));
    };
    const disconnetRomme = (key) => {


        dispatch(closeMeetingRoom(key));
    };
    useEffect(() => {

        const token = localStorage.getItem('roomToken');
        const roomNamel = localStorage.getItem('roomNamel');
        if (token && roomNamel) {
            connectToRoom(token, roomNamel);
        }

        return () => {
            disconnectFromRoom();
        };
    }, [roomName]);

    useEffect(() => {
        const handleClick = (event) => {
            const participantElement = event.target.closest('.participant');
            if (participantElement) {
                $('#video-chat-window > div').appendTo('.rightbar-videos').addClass('participant');
                $(participantElement).appendTo('#video-chat-window').removeClass('participant');
            }
        };
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            $(".rightbar-videos .participant div").each(function (i, e) {
                const videos = $(this).find('video');

                if (videos.length > 1) {
                    videos.each(function (i, e) {
                        if (this) {
                            this.style.display = 'none';
                        }
                    });

                    const screenShareVideo = $(this).find('video#screenshare')[0];
                    if (screenShareVideo) {
                        screenShareVideo.style.display = 'initial';
                    }
                } else {
                    const firstVideo = videos[0];
                    if (firstVideo) {
                        firstVideo.style.display = 'initial';
                    }
                }
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    // Connect to room
    const connectToRoom = (token, roomNamel) => {

        const { connect, createLocalVideoTrack } = Video;

        const videoChatWindow = document.getElementById('video-chat-window');

        createLocalVideoTrack().then(track => {
            setParticipants(prevParticipants => [...prevParticipants, 'me']);
            var participantDiv = document.createElement('div');
            participantDiv.setAttribute('id', 'me');
            participantDiv.setAttribute('class', '');
            var tracksDiv = document.createElement('div');
            participantDiv.appendChild(tracksDiv);
            var labelDiv = document.createElement('center');
            labelDiv.innerHTML = "me";
            participantDiv.appendChild(labelDiv);
            tracksDiv.appendChild(track.attach());
            // var videoChatWindow = document.getElementById('media-div');
            videoChatWindow.appendChild(participantDiv);

            logEvent('interview_started', "Interview started");
            setVideoTrack(track);
        }).catch(error => console.error('Error attaching local video track:', error));

        const connectOptions = { name: roomNamel };
        (token && roomNamel) &&
            connect(token, connectOptions).then(room => {
                setRoom(room);

                room.participants.forEach(participantConnected);
                room.on('participantConnected', participantConnected);
                room.on('participantDisconnected', participantDisconnected);

                // Handle room disconnection
                room.on('disconnected', () => {
                    logEvent(`left_${room.localParticipant.identity}`, `${room.localParticipant.identity} left the meeting`);
                    localStorage.removeItem("roomToken");
                    localStorage.removeItem("roomNamel");

                    room.localParticipant.tracks.forEach(publication => {
                        const attachedElements = publication.track.detach();
                        attachedElements.forEach(element => element.remove());
                    });
                    setRoom(null);
                });


            }).catch(error => {
                alert(error.message);
                console.error(`Unable to connect to Room: ${error.message}`);
            });
    };


    // Disconnect from room
    const disconnectFromRoom = () => {
        if (room) {
            room.disconnect();

        }
    };

    const participantConnected = (participant) => {
        const videoChatWindow = document.getElementById('video-chat-window');

        setParticipants(prevParticipants => [...prevParticipants, participant.identity]);
        logEvent(`joined_${participant.identity}`, `${participant.identity} joined the meeting`);


        var participantDiv = document.createElement('div');
        participantDiv.setAttribute('id', participant.sid);
        participantDiv.setAttribute('class', 'participant');
        var tracksDiv = document.createElement('div');
        participantDiv.appendChild(tracksDiv);
        var labelDiv = document.createElement('center');
        labelDiv.innerHTML = participant.identity.replaceAll("_", " ");
        setcandidateName(participant.identity ? participant.identity.replaceAll("_", " ") : "Candidate")
        participantDiv.appendChild(labelDiv);
        videoChatWindow.appendChild(participantDiv);


        participant.tracks.forEach(publication => {
            if (publication.isSubscribed)
                trackSubscribed(tracksDiv, publication.track);
        });

        participant.on('trackSubscribed', track => trackSubscribed(tracksDiv, track));
        participant.on('trackAdded', track => trackSubscribed(tracksDiv, track));
        participant.on('trackUnsubscribed', trackUnsubscribed);
        participant.on('trackDisabled', track => trackDisabled(tracksDiv, track));
        participant.on('trackEnabled', track => trackEnabled(tracksDiv, track));

        if (document.getElementsByClassName('participant').length > 0) {
            $('.no-participants').hide();
            $('.participant').appendTo('.rightbar-videos');
        }

    }

    function participantDisconnected(participant) {
        setParticipants(prevParticipants =>
            prevParticipants.filter(p => p !== participant.identity)
        );
        logEvent(`left_${participant.identity}`, `${participant.identity} left the meeting`);

        document.getElementById(participant.sid).remove();

        disconnetRomme(room);
        localStorage.removeItem("roomToken");
        localStorage.removeItem("roomNamel");

    }

    function trackSubscribed(div, track) {
        var trackElement = track.attach();
        trackElement.setAttribute('id', track.name);
        div.appendChild(trackElement);
    }

    function trackUnsubscribed(track) {
        track.detach().forEach(element => element.remove());
    }

    function trackDisabled(div, track) {
        if (track.kind === "video") {
            $(div).find("video").get(0).pause();
            $(div).find("video").css('opacity', '0');
        }

    }

    function trackEnabled(div, track) {
        if (track.kind === "video") {
            $(div).find("video").get(0).play();
            $(div).find("video").css('opacity', '1');
        }
    }

    const toggleAudioTrack = () => {
        room.localParticipant.audioTracks.forEach((publication) => {
            if (publication.track.isEnabled) {
                setIsAudioMuted(true)
                publication.track.disable();
            } else {
                setIsAudioMuted(false)
                publication.track.enable();

            }
        });
    };

    const toggleVideoTrack = () => {
        room.localParticipant.videoTracks.forEach((publication) => {
            if (publication.track.isEnabled) {
                setIsVideoMuted(true)
                publication.track.disable();
                $("#me video").get(0).pause();
                $("#me video").css('opacity', '0');
            } else {
                setIsVideoMuted(false)
                publication.track.enable();
                $("#me video").get(0).play();
                $("#me video").css('opacity', '1');
            }
        });
    };

    const AVControl = ({ type }) => {
        const icon = type === 'audio'
            ? (isAudioMuted ? faMicrophoneSlash : faMicrophone)
            : (isVideoMuted ? faVideoSlash : faVideo);
        const tooltipTxt = type === 'audio'
            ? (isAudioMuted ? "Unmute" : "Mute")
            : (isVideoMuted ? "Start Video" : "Stop Video");

        const toggleTrack = type === 'audio' ? toggleAudioTrack : toggleVideoTrack;
        const liveClass = type === 'audio' ? "live-mic" : "live-video";


        return (
            <div className={liveClass} onMouseEnter={() => handleMouseEnter(tooltipTxt)}
                onMouseLeave={handleMouseLeave} onClick={toggleTrack}>
                <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip(tooltipTxt)}
                // Show only when 'mute' is hovered
                >
                    <span
                        className="span-svg"

                    >
                        <div className="avControl" >
                            <FontAwesomeIcon icon={icon} />
                        </div>
                    </span>
                </OverlayTrigger>
            </div>

        );
    };

    const leaveMeetingHandle = () => {
        if (room) {
            room.disconnect();
            setRoom(null);

            if (videoTrack) {
                videoTrack.stop();
                setVideoTrack(null);
            }

            if (audioTrack) {
                audioTrack.stop();
                setAudioTrack(null);
            }

            if (screenTrack) {
                screenTrack.stop();
                setScreenTrack(null);

                const screenVideo = document.getElementById('screenshareDiv');
                if (screenVideo) {
                    screenVideo.remove();
                }

            }

            logEvent('exited_interview', "Exited interview");
            // logEvent(`left_${candidateName}`, `${candidateName} left the meeting`);

            setMeetingLeft(true);
            setShowModalFeedBack(false);
            disconnetRomme(roomName);
        }

        setMeetingLeft(true);
        setShowModalFeedBack(false);
    };

    const toggleScreenShare = async () => {
        navigator.mediaDevices.getDisplayMedia().then(stream => {
            let screen = Video.LocalVideoTrack(stream.getTracks()[0], { name: 'screenshare' });

            room.localParticipant.publishTrack(screen);

            // show screen shared in window starts
            var participantDiv = document.createElement('div');
            participantDiv.setAttribute('id', 'screenshareDiv');
            participantDiv.setAttribute('class', '');
            var tracksDiv = document.createElement('div');
            participantDiv.appendChild(tracksDiv);
            var labelDiv = document.createElement('center');
            labelDiv.innerHTML = 'Screen Shared';
            participantDiv.appendChild(labelDiv);
            tracksDiv.appendChild(screen.attach());
            const videoChatWindow = document.getElementById('video-chat-window');
            videoChatWindow.appendChild(participantDiv);
            $('#me center').html('Me');
            $('#me').appendTo('.rightbar-videos').addClass('participant');
            logEvent(`screen_share_${room.localParticipant.identity}`, `${room.localParticipant.identity || 'User'}  started sharing screen`);

            setScreenTrack(screen);
            setIsScreenSharing(true);

            screen.mediaStreamTrack.onended = () => {
                $(".screenShare-video").trigger("click");
                stopScreenShare();
            };
        }).catch(() => {
            // alert('Could not share the screen.');
        });
    };

    const stopScreenShare = () => {
        if (screenTrack) {
            room.localParticipant.unpublishTrack(screenTrack);
            screenTrack.stop()


            document.getElementById('screenshareDiv').remove();
            $('#me').appendTo('#video-chat-window').removeClass('participant');
            $('#me center').html('');
            logEvent(`screen_stope_${room.localParticipant.identity}`, `${room.localParticipant.identity || 'User'}  stopped sharing screen`);

            // logEvent('screen_share', `${room.localParticipant.identity || 'User'} stopped sharing screen`);
            setScreenTrack(null);
            setIsScreenSharing(false);
        }
    };


    const handleModalClose = () => setShowModal(false);

    const handleModalShow = () => setShowModal(true);
    const handleModalFeedBackClose = () => setShowModalFeedBack(false);

    const handleModalFeedBackShow = () => setShowModalFeedBack(true);
    useEffect(() => {
        if (contentEditor) {
            setFormData(prevData => ({
                ...prevData,
                invite_message: contentEditor
            }));
        }
    }, [contentEditor]);
    const handleChange = (e) => {
        const { name, value } = e.target;

        // Update the form data state
        setFormData({
            ...formData,
            [name]: value
        });


        const newErrors = { ...errors };


        if (name === 'email') {
            if (value && !/\S+@\S+\.\S+/.test(value)) {
                newErrors[name] = 'Email is invalid';
            } else {
                newErrors[name] = '';
            }
        }


        if (value.trim() === '') {
            newErrors[name] = 'Field is required';
        } else if (name !== 'email') {
            newErrors[name] = '';
        }


        setErrors(newErrors);
    };

    const AddParticipant = (e) => {
        e.preventDefault();
        const { email } = formData;

        // Initialize a new errors object
        const newErrors = {};

        // Check for email presence
        if (!email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email is invalid';
        }


        if (Object.keys(newErrors).length > 0) {
            setErrors(prevErrors => ({
                ...prevErrors,
                ...newErrors,
            }));
            return;
        }

        // Prepare payload for dispatch
        const payload = {
            "token": params.token,
            "email": email,
            "content": formData.invite_message
        };

        // Dispatch action to add participant
        dispatch(addParticipant(payload))
            .then((res) => {
                if (res.payload.error) {
                    sweetAlert("error", "Error!", `${res.payload.error}!`);
                } else {
                    // Clear errors on successful submission
                    setErrors({});
                    sweetAlert("success", "Success!", res.payload.message);
                    setShowModal(false);
                }
            })
            .catch(() => {
                sweetAlert("error", "Error!", "Something went wrong!");
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let formErrors = {};


        if (rating === 0) {
            formErrors.rating = "Rating is required";
        }

        if (!comments || comments.trim() === "") {
            formErrors.comments = "Comments are required";
        }


        if (Object.keys(formErrors).length === 0) {


            let payload = {

                "stage_id": 10,
                "end_timestamp": new Date(),
                "interview_type": 2,
                "interview_rating": rating,
                "comments": comments,
                "is_active": false,

            }
            dispatch(UpdateInterviewByToken({ token: params.token, payload })).then((resp) => {
                if (resp.payload.error) {
                    sweetAlert(
                        "error",
                        "Error!",

                        `${resp.payload.error}!`
                    );
                }
                else {
                    leaveMeetingHandle()

                }


            }).catch(() => {
                sweetAlert(
                    "error",
                    "Error!",
                    "Something went wrong!"
                );
            });




        } else {
            setErrorsFeedback(formErrors);
        }
    };


    const handleMouseLeave = () => {
        setHoveredTooltip(null);
    };

    const handleMouseEnter = (tooltipId) => {
        setHoveredTooltip(tooltipId);
    };

    const renderTooltip = (tooltipText) => (
        <Tooltip id="button-tooltip">{tooltipText}</Tooltip>
    );


    return (
        <>
            {!roomName &&
                <Spinner />
            }

            <div
                className="container-liveintervew "
                style={{ overflowY: meetingLeft ? "none" : "auto" }}
            >

                {meetingLeft ? (
                    <div className="meeting-left">
                        <center style={{ color: "white", }}>You have left Video Call</center>
                    </div>
                ) : (
                    <>
                        {error && (
                            <div className="mb-5 mt-5">
                                <div className="alert alert-danger mt-3">{error}</div>
                            </div>
                        )}
                        <Row className="row-pad-0">
                            <div className="main-video-container sm-t-20">

                                <div id="video-chat-window" className="video-chat-window" ></div>

                                <div className={`rightbar-videos ${participantsCount.length > 2 ? " sm-participants-4" : " "}`} >
                                    <div className="no-participants sm-t-20">No participants yet</div>
                                </div>
                            </div>

                            <Col md={12} className="handel-setting">
                                <AVControl type="audio" />
                                <AVControl type="video" />



                                <div className="screenShare-video" onMouseEnter={() => handleMouseEnter("screenShare")}
                                    onMouseLeave={handleMouseLeave} onClick={isScreenSharing ? stopScreenShare : toggleScreenShare}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={renderTooltip(isScreenSharing ? " Stop sharing" : "Share Screen")}
                                        show={hoveredTooltip === "screenShare"} // Show only when 'screenShare' is hovered
                                    >
                                        {
                                            isScreenSharing ? <span className="span-svg stopScreenShare-svg">{stopScreenShareIcon}</span> : <span className="span-svg"> <i className="fa fa-desktop" aria-hidden="true"></i></span>
                                        }

                                    </OverlayTrigger>
                                </div>
                                {params.name &&
                                    <div className="live-invite-icon" onMouseEnter={() => handleMouseEnter("inviteUser")}
                                        onMouseLeave={handleMouseLeave} onClick={handleModalShow}>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={renderTooltip("Invite User")}
                                            show={hoveredTooltip === "inviteUser"} // Show only when 'inviteUser' is hovered
                                        >
                                            <span
                                                className="span-svg"

                                            >
                                                {adduser}
                                            </span>
                                        </OverlayTrigger>
                                    </div>
                                }
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip("Leave Meeting")}
                                    show={hoveredTooltip === "leaveMeeting"} // Show only when 'leaveMeeting' is hovered
                                >
                                    <div className="leaveMeeting" onClick={params.name ? leaveMeetingHandle : handleModalFeedBackShow}>

                                        <span
                                            className="span-svg"
                                            onMouseEnter={() => handleMouseEnter("leaveMeeting")}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            {leaveMeeting}
                                        </span>

                                    </div>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </>
                )}
            </div >
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Invite User</Modal.Title>
                </Modal.Header>
                <Form onSubmit={AddParticipant} id="inviteVideoUserFrm">
                    {/* <form id="inviteVideoUserFrm"> */}
                    <Modal.Body>
                        <div className="participants-wrap">
                            <Col md={12} className="mt-20 mb-20">
                                <Label text="Email Address" className="labelinput mb-1 d-flex" required="required" />
                                <InputField type="text" id="email" name="email" required autocomplete="off" onChange={handleChange} placeholder="Enter email address" />
                                {errors.email && <span className="text-danger d-flex">{errors.email}</span>}
                            </Col>
                            <Col md={12} className="mt-20 mb-20">
                                <TextEditor
                                    textValue={contentEditor}
                                    setTextValue={setContentEditor}
                                    placeholderValue={"Share interview details for the candidate"}
                                />
                            </Col>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton text="Send Invite" onClick={AddParticipant} className="custom-btn-primary" />
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal show={showModalFeedBack} onHide={handleModalFeedBackClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Share Candidate Feedback</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Col md={12}>
                        <Row className="row-pad-0 bg-white border-radius-5 pad-10">
                            <Col md={12}>


                                <Form.Label className="min-sub-heading d-flex mb-10">Rating:</Form.Label>
                                <StarRating
                                    value={rating}
                                    count={5}
                                    size={20}
                                    activeColor="#FFD700"
                                    onChange={(value) => setRating(value)}
                                />
                                {errorsFeedback.rating && <><span className="text-danger d-flex">{errorsFeedback.rating}</span></>}


                            </Col>
                            <Form.Group controlId="exampleForm.ControlTextarea1" className="pad-20">
                                <Form.Label className="min-sub-heading d-flex mb-10">Comments</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    placeholder="Enter your experience and suggestions below"
                                    value={comments}
                                    onChange={(e) => setComments(e.target.value)}
                                />
                                {errorsFeedback.comments && <><span className="text-danger d-flex">{errorsFeedback.comments}</span></>}


                            </Form.Group>


                            <Col md={12} className="text-right">
                                <CustomButton text=" Submit Feedback" type="submit" className="custom-btn-primary sm-proceed-btn" />

                            </Col>
                        </Row>
                    </Col>
                </Form>
            </Modal>
        </>
    );
};

export default LiveInterview;