export function UpdateMetaTags({ title = "Default Title", description = "Default Description" }) {

    document.title = title;


    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {

        metaDescription = document.createElement("meta");
        metaDescription.name = "description";
        document.head.appendChild(metaDescription);
    }


    metaDescription.setAttribute("content", description);
}
