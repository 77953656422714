import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Row, Col, ProgressBar, } from 'react-bootstrap';

import CardInfromation from './CardInfromation';
import AddPackage from './AddPackage';
import Plan from './Plan';
import { UpdateMetaTags } from "../common/UpdateMetaTags";
import { accountStatus, accountUsageInfo, updatePackagepurchased } from '../../store/actions/accountBillingAction';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Spinner from '../common/Spinner';
import AmountBreakdown from './AmountBreakdown';
import * as moment from 'moment';

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 5000
    });
};

const PlanOverview = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('checkout_session_id');
    const [confirmPayment, setConfirmPayment] = useState(false);
    const [errorMessage, setErrorsMessage] = useState('')
    const [showAddPackage, setShowAddPackage] = useState(false);
    const [PackageType, setpackageType] = useState(false);
    const { accountStatusverify, loading, error } = useSelector((state) => state.accountBilling) || {};


    const formatCustomDate = (date) => {
        const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "agu", "sep", "oct", "nov", "dec"];
        const momentDate = moment(date);
        const month = months[momentDate.month()];
        const day = momentDate.date();
        const year = momentDate.year();
        return `${month},${day},${year}`;
    };
    useEffect(() => {
        UpdateMetaTags({
            title: "Plan Overview | VidHirePro",
            description: "Plan Overview | VidHirePro",
        });
        if (sessionId) {
            const payload = {
                sessionId: sessionId
            };

            dispatch(updatePackagepurchased(payload)).then((res) => {
                setConfirmPayment(true);
                if (res.payload.error) {
                    sweetAlert(
                        "error",
                        "Error!",
                        `${res.payload.error}!`
                    );
                } else {
                    setConfirmPayment(false);
                    navigate('/billing/plan-overview');
                    sweetAlert(
                        "success",
                        "Success!",
                        res.payload.message
                    );
                }
            });
        }
    }, [dispatch, sessionId, navigate, confirmPayment]);

    useEffect(() => {
        dispatch(accountStatus())
            .then((res) => {

                if (res.error) {
                    setErrorsMessage(res?.payload?.error)
                }

            })
            .catch((err) => {
                setErrorsMessage(err)
                console.error('Unexpected Error:', err);
            });

    }, [dispatch, sessionId, confirmPayment, navigate]);




    const addPackage = (type) => {
        setpackageType(type)
        setShowAddPackage(!showAddPackage);
    };
    const accountInfo = accountStatusverify?.active_package && accountStatusverify?.active_package;
    const percentageEmail = (accountInfo?.email_messages / accountInfo?.allowedEmails) * 100;
    const percentageSMS = (accountInfo?.sms_messages / accountInfo?.allowedSms) * 100;

    return (
        <>
            {confirmPayment && <Spinner />}
            {loading && <Spinner />}
            <Col className="row-pad-0 wrap-billing-plan y-scroll">

                <Plan accountInfo={accountInfo} loading={loading} accountStatusverify={accountStatusverify} errorMessage={errorMessage} addPackage={addPackage} />
                {/* {accountStatusverify && !accountStatusverify?.is_trial && (
                    <Col md={12} className="bg-white radius-7 mt-20 pad-20 amount-breakdown-wrap">
                        <h5 className='heading '>Amount Breakdown</h5>
                        {accountStatusverify?.account_id &&
                            <AmountBreakdown accountID={accountStatusverify?.account_id} addPackage={addPackage} />}
                    </Col>
                )} */}
                <Col md={12} className="bg-white radius-7 mt-20 pad-20 credit-usage-analytic-wrap">
                    <Row className='row-pad-0 mt-10 mb-10'>
                        <Col md={6} className=' mb-10 pad-0'><h5 className='heading'>Credit Usage Analytics</h5></Col>
                        <Col md={6} className='text-end  mb-10 '>
                            <p className=' mr-10' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                <label style={{ fontWeight: "600", fontSize: "13px", marginRight: "5px" }}>Credits Expiry Date:</label>
                                <span style={{ fontSize: "16px", letterSpacing: "1px", fontWeight: "500", textTransform: "capitalize" }}>

                                    {formatCustomDate(accountInfo?.package_expiry_date)}
                                </span>
                            </p>
                        </Col>
                        <Col md={12} className='mt-10 mb-10'>
                            <div className='box-emil-analytics pad-10'>
                                <div className="d-flex justify-content-between">
                                    <div><h6 className=''>Email Usage Analytics</h6></div>
                                    <div>{accountInfo?.email_messages}/{accountInfo?.allowedEmails} Used</div>
                                </div>
                                <div>
                                    <ProgressBar animated now={percentageEmail} />
                                </div>
                            </div>
                            <div className='box-emil-analytics pad-10 mt-20'>
                                <div className="d-flex justify-content-between">
                                    <div><h6>SMS Usage Analytics</h6></div>
                                    <div>{accountInfo?.sms_messages}/{accountInfo?.allowedSms} Used</div>
                                </div>
                                <div>
                                    <ProgressBar animated now={percentageSMS} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                {/* {accountStatusverify && !accountStatusverify?.is_trial && (
                    <CardInfromation />
                )} */}
            </Col>


            {showAddPackage && <AddPackage handleClose={addPackage} type={PackageType} />}
        </>
    );
};

export default PlanOverview;
