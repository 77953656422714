import React, { useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const TextEditor = ({ setTextValue, textValue, placeholderValue, handleChange }) => {
    const editorRef = useRef(null);


    const handleEditorChange = (event, editor) => {
        const newContent = editor.getData();

        handleChange(newContent);
    };



    return (
        <CKEditor
            editor={ClassicEditor}

            data={textValue}
            onReady={(editor) => {
                editorRef.current = editor;
            }}
            onChange={handleEditorChange}
            config={{
                placeholder: placeholderValue,
                height: '250px',
                toolbar: [
                    'undo', 'redo', '|', 'bold', 'italic',
                ],
            }}
            style={{ height: '250px' }}
        />
    );
};

export default TextEditor;























// import React, { useRef } from 'react';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// const TextEditor = ({ setTextValue, textValue, placeholderValue, handleChange }) => {
//     const editorRef = useRef(null);

//     const handleEditorChange = (event, editor) => {
//         const newContent = editor.getData();
//         handleChange(newContent);
//     };
//     const handlePaste = (e) => {
//         e.preventDefault();

//         const clipboardData = e.clipboardData.getData("Text");
//         console.log(clipboardData, "clipboardData")
//         const combinedContent = `${textValue} ${clipboardData}`.trim();
//         handleChange(combinedContent);

//     };
//     return (
//         <CKEditor
//             editor={ClassicEditor}
//             onPaste={handlePaste}
//             data={textValue}
//             onReady={(editor) => {
//                 editorRef.current = editor;
//             }}
//             onChange={handleEditorChange}
//             config={{
//                 placeholder: placeholderValue,
//                 height: '250px',
//                 toolbar: [
//                     'undo', 'redo', '|', 'bold', 'italic',
//                 ],
//             }}
//             style={{ height: '250px' }}
//         />
//     );
// };

// export default TextEditor;
