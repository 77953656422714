import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Vidpmlogo } from "../helper/Icons/Icon";
import { Checkbox, Label, InputField, CustomButton, FeedBack } from "../components/form/index";
import { Container, Row, Col, Form } from 'react-bootstrap';
import { vidhireProLogo } from '../helper/Icons/Icon';
import { Link, useNavigate } from "react-router-dom";
import { loginFields, otpFields } from '../components/accountSetting/FieldConfig';
import Spinner from "../components/common/Spinner";
import { login } from '../store/actions/authActions';
import { sendSmsAuth, verifyAuthcode, verifycode } from '../store/actions/userActions';
import Swal from 'sweetalert2';


const TwoFactorAuth = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [data, setData] = useState({ otp: '' });
    const [incorrect, setIncorrect] = useState(false)
    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const userToken = useSelector((state) => state?.auth?.userToken);
    const number = useSelector((state) => state?.auth?.userInfo?.phone_signin_security);
    const { loading } = useSelector((state) => state.user);

    const validateForm = () => {
        if (data.otp.length === 0) {
            setErrors('otp is required')
        }
        return errors
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const payload = {
                phone_number: number,
                code: data.otp,
                userToken: userToken
            };

            dispatch(verifyAuthcode(payload)).then((result) => {

                if (result.type === "user/verifyauthcode/fulfilled") {
                    localStorage.setItem('userToken', userToken)
                    navigate("/dashboard")
                    window.location.reload();
                } else if (result.type === "user/verifyauthcode/rejected") {
                    Swal.fire({
                        showConfirmButton: false,
                        showCloseButton: true,
                        icon: 'error',
                        timer: 3000,
                        title: 'Verification Failed',
                        text: result.payload.detail || result.payload.error
                    });
                }

            }).catch((error) => {
                console.error('Verification Error:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'An error occurred while verifying the code.',
                });
            });

        }
    };
    const handleResendOtp = () => {

        const payload = {
            number: number,
            userToken: userToken
        };
        dispatch(sendSmsAuth(payload));
    };

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: name === 'remember' ? checked : value
        }));

        // Clear errors related to the input field if valid
        if (name === 'email' && value && !/\S+@\S+\.\S+/.test(value)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: 'Email is invalid'
            }));
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: ''
            }));
        }
        setIncorrect(false)
    };


    if (isLoading) {
        return <Spinner />;
    }
    return (
        <Container fluid className="pad-0">

            {loading && <Spinner />}

            <Row>
                <Col md={7} sm={6} className='pad-0'>
                    <div className='logo'> <img src={vidhireProLogo} alt='Logo' /></div>
                    <div className='info-graphics'></div>
                </Col>

                <Col md={5} sm={6} className="justify-content-center login-left-side d-flex">
                    <Form onSubmit={handleSubmit} noValidate validated={validated}>
                        <Row className="">
                            <Row className='main-heading  two-factorAuth-wrap'>
                                {/* <Col className='text-center'><h1 className='main-heading'>Verification</h1></Col> */}

                                <div className='two-factorAuth-logo'> <img src={vidhireProLogo} style={{ height: "60", width: "20" }} alt='Logo' /></div>
                                {/* <span className='text-center mb-10 mt-10 ' style={{fontSize:"1"}}> */}
                                {/* <p >Verification</p> */}
                                {/* </span> */}

                            </Row>
                            <div className='text-center mb-10 mt-10 ml-10 '>
                                <p >Please enter the 5 digit code sent to your mobile number *******{number[number.length - 4]}{number[number.length - 3]}{number[number.length - 2]}{number[number.length - 1]} </p>
                            </div>
                            <Row className="">

                                {otpFields.map((field, index) => (
                                    <Col md={12} className={field.type === "otp" ? "mt-10 mb-1 p-relative" : "mt-10 mb-1 p-relative"} key={index}>
                                        <Label
                                            id={field.id}
                                            className={field.className}
                                            text={field.text}
                                            required="required"
                                        />
                                        <InputField
                                            type={field.type === ""}
                                            id={field.id}
                                            placeholder={field.placeholder}
                                            onChange={handleChange}
                                            name={field.name}
                                            value={data[field.name] || ''}
                                            isInvalid={!!errors[field.name]} // Check for errors
                                        />
                                        {errors.length > 0 && (
                                            <FeedBack error={errors} className={"error"} />
                                        )}
                                    </Col>
                                ))}
                                <Row className="mb-1 mt-20 row-pad-0 ">
                                    <CustomButton text="Verify" type="submit" className="custom-btn-primary" />
                                </Row>
                                <Row className="mb-1 mt-20 row-pad-0 ">
                                    <CustomButton text="Resend Code" onClick={handleResendOtp} className="custom-btn-primary" />
                                </Row>
                            </Row>

                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default TwoFactorAuth;
