import React, { useState, useEffect } from "react";
import { Table, Col, Row, Form } from "react-bootstrap";
import { eye, pencil, deletclrred } from "../../helper/Icons/Icon";
import { CustomButton } from "../form";
import Spinner from "../common/Spinner";
import { useLocation, useOutletContext } from "react-router-dom";
import { getAll, deleteCompany } from "../../store/actions/companyActions";
import { getByName } from "../../store/actions/userPrefrenceActions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReorderColumns from "../common/ReorderColumns";
import EditCompany from "./EditCompany";
import * as moment from 'moment'
import Swal from 'sweetalert2';
import Paginate from "../common/Paginate";
import ErrorAlert from "../common/ErrorAlert";
import Tabelcolumn from "../common/Tabelcolumn";
import ReactDatePicker from "react-datepicker";
import { UpdateMetaTags } from "../common/UpdateMetaTags";
const defaultCurrentPage = 1
const defaultPerPage = 25

const CompanyList = ({ addCompany }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const refreshApi = useOutletContext();
    const [updateList, setUpdateList] = useState(true)
    const [deletec, setDeletec] = useState(false);
    const [showEditCompany, setEditCompany] = useState(false);
    const [showCompany, setCompany] = useState(false);
    const [companyId, setCompanyId] = useState();
    const [showreorderColumns, setshowreorderColumns] = useState(false);

    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);
    const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
    const [perPage, setPerPage] = useState(userPrefrenceDetail?.preference_value?.record_per_page ?? defaultPerPage);
    const [searchField, setSearchField] = useState({});
    const prefrenceName = "users";
    const [errorMessage, setErrorMessage] = useState(null)
    const [errorMessageAlert, setErrorMessageAlert] = useState(null)
    const { companies, loading, error } = useSelector((state) => state.company);

    let companyList = companies.data;
    let headers = companies.headers;
    let pages = companies.pages;
    let totalPages = companies.total_page;
    let totalRecords = companies.total_record;

    const [sortConfig, setSortConfig] = useState({ key: companies?.order_by, order: companies?.sort_by });

    useEffect(() => {
        UpdateMetaTags({
            title: "Companies | VidHirePro",
            description: "Companies | VidHirePro",
        });
        dispatch(getByName(prefrenceName));
    }, [dispatch, updateList])

    useEffect(() => {
        if (userPrefrenceDetail?.preference_value?.record_per_page) {
            setPerPage(userPrefrenceDetail.preference_value.record_per_page);
        }
    }, [userPrefrenceDetail]);

    useEffect(() => {
        if (perPage) {
            let payload = {
                'limit': perPage, 'page': currentPage, search: searchField, order_by: sortConfig?.key, sort_by: sortConfig?.order
            }
            dispatch(getAll(payload)).then((res) => {
                if (res.payload.status === 401) {
                    setErrorMessageAlert(res.payload?.data?.error || 'Unauthorized access');
                    return;
                }

                if (res.payload.status && res.payload?.data?.error) {
                    setErrorMessage(res.payload?.data?.error || "Something went wrong");
                    return;
                }


                setErrorMessage(null);



            });
        }


    }, [dispatch, addCompany, deletec, currentPage, perPage, searchField, updateList, showreorderColumns, sortConfig])
    useEffect(() => {
        refreshPage();

    }, [refreshApi])
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPage = (perPage) => {
        setPerPage(perPage);
        setCurrentPage(1)
    };




    const handelModel = (companyId) => {
        setCompanyId(companyId);
        setEditCompany(!showEditCompany);
        setUpdateList(!updateList);
    };

    const handelToggelModel = (type, id) => {
        setCompanyId(id);

        if (type === "reorderColumns") {
            setshowreorderColumns(true)


        }

    };

    const closeModel = () => {
        refreshPage(pages)
        setshowreorderColumns(false)
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure to delete the selected item(s).",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteCompany(id)).then(() => {
                    setDeletec(true);
                    Swal.fire({
                        icon: "success",
                        title: "Deleted",
                        text: "Item(s) has been deleted.",
                        showConfirmButton: false,
                        timer: 1000,
                    });
                });
            }

            setDeletec(false);
        });
    };
    const handleDateChange = (date, key) => {
        const formattedDate = date ? moment(date).format('MM-DD-YYYY') : "";
        setCurrentPage(1)
        setSearchField((prevState) => {
            const newSearchField = { ...prevState };

            if (formattedDate) {

                newSearchField[key] = formattedDate;
            } else {

                delete newSearchField[key];
            }

            return newSearchField;
        });
    };

    const handleFieldSearch = (e) => {
        const { name, value } = e.target;

        setCurrentPage(1)
        if (value === "") {
            const newSearchField = { ...searchField };
            delete newSearchField[name];
            setSearchField(newSearchField);
        } else {

            setSearchField((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const refreshPage = (page) => {
        setSearchField({});
        setCurrentPage(defaultCurrentPage);
        setPerPage(userPrefrenceDetail?.preference_value?.record_per_page);
        setSortConfig({})
        setUpdateList(!updateList);
        let data = {
            'limit': perPage, 'page': currentPage, search: searchField
        }
        dispatch(getAll(data)).then((res) => {
            setSortConfig({ key: res.payload?.order_by, order: res.payload?.sort_by })
            if (res.payload.status === 401) {
                setErrorMessageAlert(res.payload?.data?.error || 'Unauthorized access');
                return;
            }

            if (res.payload.status && res.payload?.data?.error) {
                setErrorMessage(res.payload?.data?.error || "Something went wrong");
                return;
            }


            setErrorMessage(null);



        });
    };

    const viewCompany = (id) => {
        sessionStorage.setItem('companyID', id);
        window.open('/company', '_blank');
    };

    const toggleSortOrder = (key) => {
        setSortConfig(prevConfig => {
            if (prevConfig.key === key) {
                return { key, order: prevConfig.order === 'asc' ? 'desc' : 'asc' };
            } else {
                return { key, order: 'asc' };
            }
        });

    };

    return (
        <>
            {loading && <Spinner />}
            {errorMessageAlert && <ErrorAlert errorMessage={errorMessageAlert} />}
            <Row className="listing-section row-pad-0">
                <Col md={12} className="text-end  mt-10 mb-10">
                    <CustomButton
                        className="header-btn mr-10"
                        text={<i class="fa fa-columns fa-fw"></i>}
                        module="companies"
                        Tooltip="Select column"
                        onClick={() => handelToggelModel("reorderColumns")}
                    />
                    <CustomButton
                        className="header-btn mr-10"
                        text={<i class="fa fa-refresh"></i>}
                        onClick={refreshPage}
                        Tooltip="Refresh"

                    />
                </Col>
                <div className="oveflow-companyList-auto">
                    <Table className="tabel" style={{}}>
                        <thead>
                            <tr>
                                <th style={{ width: "20px" }} className="th-text">
                                    View Details
                                </th>
                                <th style={{ width: "45px" }} className="th-text">
                                    Edit
                                </th>
                                <th style={{ width: "20px" }} className="th-text">
                                    Delete
                                </th>
                                {headers &&
                                    headers.map((header, index) => {
                                        const fieldValue = searchField[header.key] || '';
                                        const isSortedColumn = sortConfig.key === header.key;
                                        const isDateField = header.key.toLowerCase().includes("created_date") || header.key.toLowerCase().includes("updated_date") || header.key.toLowerCase().includes("job_expiry_date");

                                        return (
                                            <th key={index} className="sorting" style={{ minWidth: "100px", width: "200px" }}>
                                                <span onClick={() => toggleSortOrder(header.key)}>
                                                    {isSortedColumn ? (sortConfig.order === 'asc' ? <i class="fa fa-sort-amount-asc" aria-hidden="true"></i> : <i class="fa fa-sort-amount-desc" aria-hidden="true"></i>) : <i class="fa fa-exchange" aria-hidden="true"></i>}
                                                </span>
                                                {isDateField ? (
                                                    <div>

                                                        <ReactDatePicker
                                                            closeOnScroll={true}
                                                            className="form-control rating-dropdown custom-datepicker"
                                                            selected={fieldValue}
                                                            onChange={(date) => handleDateChange(date, header.key)}
                                                            placeholderText={header.title}
                                                            dateFormat="MM-dd-yyyy"
                                                            isClearable={!!fieldValue}
                                                            showPopperArrow={true}
                                                            popperPlacement="bottom"
                                                        // withPortal
                                                        // portalId="react-datepicker-portal"

                                                        />
                                                    </div>
                                                ) : (
                                                    <Form.Control
                                                        name={header.key}
                                                        type="text"
                                                        value={fieldValue}
                                                        placeholder={header.title}
                                                        onChange={handleFieldSearch}
                                                    />
                                                )}

                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {

                                errorMessage ? <td colSpan={10} className=" pad-20 text-center mt-20 mb-10 error-message">{errorMessage}</td> : (companyList && companyList.length > 0 ? (
                                    companyList.map((company) => {

                                        return (
                                            <tr key={company.id}>

                                                <td className="cursor quick-action td-icon"><Tabelcolumn span="span" onClick={() => viewCompany(company.id)} icon={eye} Tooltip="Detail" /> </td>
                                                <td className="cursor quick-action td-icon"><Tabelcolumn span="span" onClick={() => handelModel(company.id)} icon={pencil} Tooltip="Edit" /></td>
                                                <td className="cursor quick-action td-icon"><Tabelcolumn span="span" onClick={() => handleDelete(company.id)} icon={deletclrred} Tooltip="Delete" /></td>



                                                {Object.keys(company).map(function (key) {
                                                    if (key.includes("_date"))
                                                        return <td key={key} className="td-text">{moment(company[key]).format('DD-MM-YYYY h:mm A')}</td>;
                                                    if (key.includes("company_description"))
                                                        return <td key={key} className="td-text td-ellipsis"  ><p className="td-text td-ellipsis" dangerouslySetInnerHTML={{ __html: company[key] }} /></td>;
                                                    else if (key != "id")
                                                        return <td key={key} className="td-text td-ellipsis" title={company[key]}>{company[key]}</td>

                                                })}


                                            </tr>

                                        )
                                    })) : <tr>
                                    <td colSpan={`${headers?.length ? headers?.length + 3 : 10}`} className="text-center">No records found.</td>
                                </tr>
                                )}
                        </tbody>
                    </Table>



                </div>
                <Paginate
                    pages={pages}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    perPage={perPage}
                    totalRecords={totalRecords}
                    handlePageChange={handlePageChange}
                    handlePerPage={handlePerPage}
                />
                {showEditCompany && <EditCompany companyId={companyId} handleClose={() => handelModel(0)} />}
                {showreorderColumns && <ReorderColumns module="companies" headertext="Manage Columns" handleClose={() => closeModel()} />}

            </Row>
        </>
    );
};

export default CompanyList;
