import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { CustomButton } from '../components/form';
import { crossicon, user } from '../helper/Icons/Icon';
import BasicInfo from '../components/profile/BasicInfo';
import MeetingScheduler from '../components/profile/MeetingScheduler';
import MyPreferences from '../components/profile/MyPreferences';
import SigninSecurity from "../components/profile/SigninSecurity";
import { useSelector, useDispatch } from 'react-redux';
import TimeLine from '../components/profile/TimeLine';
import Spinner from '../components/common/Spinner';
import { UsersProfile, createPreference } from '../store/actions/userActions';
import EditUser from '../components/accountSetting/EditUser';
import Swal from 'sweetalert2';

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 3000
    });
}

const Profile = ({ handleClose, profileId }) => {
    const dispatch = useDispatch();
    const [editUser, setEditUser] = useState(false);
    const [preferenceValue, setPreferenceValue] = useState();
    const { UsersProfiledata, loading, error } = useSelector((state) => state.user);

    const [reloadData, setReload] = useState(false);

    const EditId = UsersProfiledata?.data?.user.id;

    const PreferenceHandle = (e) => {
        const { value } = e.target;
        setPreferenceValue(value);
    }

    useEffect(() => {

        dispatch(UsersProfile(profileId || " "));
    }, [profileId, reloadData]);

    const reLoad = (reload) => {
        setReload(prev => !prev);
    }

    useEffect(() => {
        if (preferenceValue) {
            const payload = {
                "preference_value": {
                    "record_per_page": preferenceValue,
                },
                "preference_name": "users",
                "module_code": "users"
            };
            dispatch(createPreference(payload)).then((res) => {
                if (res.payload.error) {
                    sweetAlert("error", "Error!", `${res.payload.error}!`);
                } else {
                    setReload(!reloadData);
                    sweetAlert("success", "Success!", res.payload.message);
                }
            }).catch(() => {
                sweetAlert("error", "Error!", "Disconnect Your Internet!");
            });
        }
    }, [preferenceValue]);

    const handleEditUser = () => {
        setEditUser(true);
    }
    const handleEditUserClose = () => {
        setReload(!reloadData);
        setEditUser(false);
    }

    return (
        <>
            <Offcanvas show={true} onHide={handleClose} placement='end' className="bg-ligte-gray custom-offcanvas" style={{ width: "80%" }}>
                <Offcanvas.Header className="model-header bg-white">
                    <Offcanvas.Title>
                        <span className="svg mr-10">{user}</span>
                        <span>Profile & Preferences</span>
                    </Offcanvas.Title>
                    <div className="text-end">
                        <CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} />
                    </div>
                </Offcanvas.Header>
                {loading && <Spinner />}



                <Offcanvas.Body className='mt-1'>
                    <Row className="row-pad-0">
                        <Col md={7} className='pad-0'>
                            {UsersProfiledata && <BasicInfo userBasicInfo={UsersProfiledata.data.user} handleEditUser={handleEditUser} />}
                            <Row className="row-pad-0 mt-20">
                                {UsersProfiledata && <MyPreferences PreferenceHandle={PreferenceHandle} preference={UsersProfiledata.data.user_preferences} />}
                            </Row>
                            <Row className="row-pad-0 mt-20">
                                {UsersProfiledata && <SigninSecurity security={UsersProfiledata} reLoad={reLoad} />}
                            </Row>
                            <Row className="row-pad-0 mt-20">
                                {UsersProfiledata?.data?.user?.id && <MeetingScheduler userprofileId={UsersProfiledata.data.user.id} meetingScheduler={UsersProfiledata.data.meeting_schedulers} reLoad={reLoad} />}
                            </Row>
                        </Col>
                        <Col md={5} className='mt-1'>
                            <Row className="row-pad-0">
                                {UsersProfiledata?.data?.user?.id && <TimeLine id={UsersProfiledata.data.user.id} />}
                            </Row>
                        </Col>
                    </Row>
                </Offcanvas.Body>



            </Offcanvas>
            {editUser && <EditUser handleClose={handleEditUserClose} profileId={EditId} />}
        </>
    );
}

export default Profile;
