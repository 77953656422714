import { createSlice } from '@reduxjs/toolkit'
import { create, update, meetingschedular, liveinterviewEvaluatorsRating, getLiveRecordedInterview, getAll, getById, deleteInterview, downloadinterview, downloadinterviews, deleteInterviews, exportFile, getRecordedInterview, saveInterviewTimeline, getInterviewRating, getTimeLineById, getFinalRemarks, downloadLiveinterview, } from '../actions/interviewActions'


const initialState = {
    loading: false,
    interviews: [],
    recordedInterview: [],
    InterviewRating: [],
    InterviewTimeLine: [],
    LiverecordedInterview: null,
    finalRemarks: null,
    downloadinterviewData: null,
    downloadinterviewList: null,
    downloadLiveinterviewData: null,
    liveinterviewEvaluatorsRatingData: null,
    error: null,
}

const interviewSlice = createSlice({
    name: 'interview',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state, action) => {
                state.loading = true
            })
            .addCase(create.fulfilled, (state, action) => {
                state.loading = false
                //state.interviews.push(action.payload)
            })
            .addCase(create.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(meetingschedular.pending, (state, action) => {
                state.loading = true
            })
            .addCase(meetingschedular.fulfilled, (state, action) => {
                state.loading = false
                //state.interviews.push(action.payload)
            })
            .addCase(meetingschedular.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(update.pending, (state, action) => {
                state.loading = true
            })
            .addCase(update.fulfilled, (state, action) => {
                state.loading = false
                state.interviews = action.payload
            })
            .addCase(update.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getAll.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.loading = false
                state.interviews = action.payload
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getById.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getById.fulfilled, (state, action) => {
                state.loading = false
                state.interviews = action.payload
            })
            .addCase(getById.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(deleteInterview.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteInterview.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteInterview.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(deleteInterviews.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteInterviews.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteInterviews.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(exportFile.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(exportFile.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(exportFile.pending, (state, action) => {
                state.loading = true
            }).addCase(getRecordedInterview.fulfilled, (state, action) => {
                state.loading = false
                state.recordedInterview = action.payload
            })
            .addCase(getRecordedInterview.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getRecordedInterview.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getLiveRecordedInterview.fulfilled, (state, action) => {
                state.loading = false
                state.LiverecordedInterview = action.payload
            })
            .addCase(getLiveRecordedInterview.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getLiveRecordedInterview.pending, (state, action) => {
                state.loading = true
            })



            .addCase(getInterviewRating.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getInterviewRating.fulfilled, (state, action) => {
                state.loading = false;
                state.InterviewRating = action.payload;
            })
            .addCase(getInterviewRating.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })



            .addCase(liveinterviewEvaluatorsRating.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(liveinterviewEvaluatorsRating.fulfilled, (state, action) => {
                state.loading = false;
                state.liveinterviewEvaluatorsRatingData = action.payload;
            })
            .addCase(liveinterviewEvaluatorsRating.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })




            .addCase(getTimeLineById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getTimeLineById.fulfilled, (state, action) => {
                state.loading = false;
                state.InterviewTimeLine = action.payload;
            })
            .addCase(getTimeLineById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(saveInterviewTimeline.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(saveInterviewTimeline.fulfilled, (state, action) => {
                state.loading = false;
                // state.InterviewTimeLine = action.payload;
            })
            .addCase(saveInterviewTimeline.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            }).addCase(getFinalRemarks.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getFinalRemarks.fulfilled, (state, action) => {
                state.loading = false;
                state.finalRemarks = action.payload;
            })
            .addCase(getFinalRemarks.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            }).addCase(downloadinterview.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(downloadinterview.fulfilled, (state, action) => {
                state.loading = false;
                state.downloadinterviewData = action.payload;
            })
            .addCase(downloadinterview.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            }).addCase(downloadinterviews.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(downloadinterviews.fulfilled, (state, action) => {
                state.loading = false;
                state.downloadinterviewList = action.payload;
            })
            .addCase(downloadinterviews.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })






            .addCase(downloadLiveinterview.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(downloadLiveinterview.fulfilled, (state, action) => {
                state.loading = false;
                state.downloadLiveinterviewData = action.payload;
            })
            .addCase(downloadLiveinterview.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
        ;




    },
})


export default interviewSlice.reducer