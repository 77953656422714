import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { getAll } from '../../store/slices/interviewAppSlice';
import { Label } from '../form';

const InterviewApps = ({ interviewAppId, onChange }) => {
    const dispatch = useDispatch();
    const options = useSelector(state => state.interviewApp.interviewApps);

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    const handleChange = (e) => {
        onChange(e);
    };

    return (
        <>
            <Label text="Interview App" htmlFor="interview_app_id" className="label-input" required="required" />
            <Form.Select name='interview_app_id' disabled={true} id='interview_app_id' onChange={handleChange} >
                <option value="">Select the interview app</option>

                {options &&
                    options.map(option => (
                        <option key={option.id} value={option.interview_app_id} selected={(interviewAppId && interviewAppId === option.interview_app_id) ? "selected" : ""}>{option.interview_app_name}</option>
                    ))}
            </Form.Select >
        </>
    );
};

export default InterviewApps;