import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { getAll, getTemplates } from '../../store/actions/interviewTemplateActions';
import { Label } from '../form';

const PostJobInterviewTemplates = ({ interviewTemplateId, onChange, EditjobTemplates, icontootip, }) => {
    const dispatch = useDispatch();
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isClearable, setIsClearable] = useState(true);
    const { templatesgetAll } = useSelector((state) => state.interviewTemplate);

    const handleChange = (selectedOption) => {
        if (selectedOption) {
            onChange('interview_template_id', selectedOption.value);
        } else {
            onChange('interview_template_id', '');
        }
    };

    useEffect(() => {
        const payload = {
            search: search,
        }

        dispatch(getTemplates(payload));
    }, [dispatch, search]);


    // useEffect(() => {
    //     if (search === null && !EditjobTemplates) {


    //         templateIdGet(templatesgetAll?.default_id)
    //     }
    //     else {
    //         console.log("")
    //     }
    // }, [templatesgetAll?.data])




    const handleSearchChangeInput = (inputValue) => {

        setSearch(inputValue);


    };

    if (!templatesgetAll.data || !Array.isArray(templatesgetAll?.data)) {
        return (
            <>
                <Label text="Select Interview Template" htmlFor="interview_template_id" />
                <Form.Select name='interview_template_id'>
                    <option value="">Something went wrong. Check your internet connection.</option>
                </Form.Select>
            </>
        );
    }

    const options = templatesgetAll?.data.map(option => ({
        value: option.id,
        label: option.template_name
    }));


    const selectedOption = interviewTemplateId ? options.find(option => option.value === interviewTemplateId) : null;


    return (
        <>
            <Label
                text="Interview Templates"
                className="label-input"
                htmlFor="interview_template_id"
                tooltip={icontootip ? "Your first template selection is final and cannot be changed later." : undefined}
                icon={icontootip ? <i style={{ marginLeft: "5px" }} className="fa fa-info-circle mr-5"></i> : undefined}
                required="required"
            />
            <Select
                isDisabled={!!EditjobTemplates}
                isLoading={isLoading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                options={options}
                onInputChange={handleSearchChangeInput}
                onChange={handleChange}
                value={selectedOption} // Ensure this is correctly set
                placeholder="Select interview template name"
            />
        </>
    );
};


export default PostJobInterviewTemplates;
