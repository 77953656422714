import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jobAnalytics } from "../store/actions/DashboardAction";
import { createPreference } from "../store/actions/userActions";
import { getByName } from "../store/actions/userPrefrenceActions";
import Spinner from "../components/common/Spinner";
import Filter from "./Filter";
import { Modal, Button, Row, Col } from "react-bootstrap";
import JobAnalytics from "../components/dashboard/JobAnalytics"; // Assuming this component exists

import ErrorAlert from "../components/common/ErrorAlert";
const JobAnalyticsCard = ({ cardModel, setJobAnalyticsDate, refreshcp }) => {
    const dispatch = useDispatch();


    const [showModal, setShowModal] = useState(false);
    const [customProps, setCustomProps] = useState(null);

    const [userPreferences, setUserPreferences] = useState({});

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

    const formattedThirtyDaysAgo = thirtyDaysAgo.toISOString().split("T")[0];
    const yesterday = new Date();
    // yesterday.setMonth(yesterday.getMonth() + 1);
    const formattedYesterday = yesterday.toISOString().split("T")[0];
    const [errorMessage, setErrorMessage] = useState(null)
    const [errorMessageAlert, setErrorMessageAlert] = useState(null)
    const [formData, setFormData] = useState({
        startDate: formattedThirtyDaysAgo,
        endDate: formattedYesterday,
    });
    const [dateFilters, setDateFilters] = useState({
        jobAnalytics: { startDate: formattedThirtyDaysAgo, endDate: formattedYesterday },
    });





    const { dashboardAnalytics } = useSelector((state) => state.dashboard);
    const { userPrefrenceDetail, loading } = useSelector((state) => state.userPrefrence);

    useEffect(() => {

        fetchUserPreferences();
    }, [dispatch, refreshcp]);

    const fetchUserPreferences = () => {
        const preferenceNames = "jobAnalytics"

        dispatch(getByName(preferenceNames)).then((res) => {
            const { payload } = res;
            if (payload && payload.preference_value) {
                const { startDate, endDate } = payload.preference_value;

                const start = startDate;
                const end = endDate;

                const newDateFilters = {
                    jobAnalytics: {
                        startDate: start,
                        endDate: end,
                    },
                };
                setFormData({
                    startDate: start,
                    endDate: end,
                })

                setDateFilters(newDateFilters);
                fetchInitialData(newDateFilters)

            }
            else {

                fetchInitialData(dateFilters)
            }
        });
    };

    const fetchInitialData = (filters) => {
        const { startDate, endDate } = filters.jobAnalytics;

        if (startDate && endDate) {
            const formattedStartDate = startDate;
            const formattedEndDate = endDate;
            setJobAnalyticsDate({
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            })

            dispatch(jobAnalytics({ startDate: formattedStartDate, endDate: formattedEndDate })).then((res) => {
                if (res.payload.status === 401) {
                    setErrorMessageAlert(res.payload?.data?.error || 'Unauthorized access');
                    return;
                }

                if (res.payload.status && res.payload?.data?.error) {
                    setErrorMessage(res.payload?.data?.error || "Something went wrong");
                    return;
                }


                setErrorMessage(null);
            });
        }
    };


    // Handle date change
    const handleDateChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleSubmit = async () => {
        const { startDate, endDate } = formData;


        if (!startDate || !endDate) {
            console.error("Start date and end date are required.");
            return;
        }


        const formattedStartDate = new Date(startDate).toISOString().split('T')[0];
        const formattedEndDate = new Date(endDate).toISOString().split('T')[0];

        setDateFilters((prev) => ({
            ...prev,
            [customProps]: { startDate: formattedStartDate, endDate: formattedEndDate },
        }));

        const payload = {
            preference_value: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            },
            preference_name: customProps,
            module_code: "dashboardCard",
        };

        try {
            await dispatch(createPreference(payload));
            await dispatch(jobAnalytics({ startDate: formattedStartDate, endDate: formattedEndDate }));

            setJobAnalyticsDate({
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            });

            setShowModal(false);
        } catch (error) {
            console.error("Error during form submission:", error);
        }
    };


    const handleReset = () => {
        setFormData({
            startDate: formattedThirtyDaysAgo,
            endDate: formattedYesterday,
        });

        setDateFilters({
            jobAnalytics: {
                startDate: formattedThirtyDaysAgo,
                endDate: formattedYesterday,
            },
        });
        const formattedStartDate = formattedThirtyDaysAgo;
        const formattedEndDate = formattedYesterday;
        const payload = {
            preference_value: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            },
            preference_name: customProps,
            module_code: "dashboardCard",
        };

        dispatch(createPreference(payload))
            .then(() => {
                if (customProps === "jobAnalytics") {
                    dispatch(jobAnalytics({
                        startDate: formattedThirtyDaysAgo,
                        endDate: formattedYesterday,
                    }));
                }
                setJobAnalyticsDate({
                    startDate: formattedThirtyDaysAgo,
                    endDate: formattedYesterday,
                })
                setShowModal(false); // Close modal after resetting filters
            })
            .catch((error) => console.error("Reset preference update error:", error));
    };

    // Handle opening the modal
    const handleOpenModal = (type) => {
        setCustomProps(type);
        setShowModal(true); // Open the modal
    };

    const isYesterdayFilter = (formData) => {


        const startDate = new Date(formData.startDate);
        const endDate = new Date(formData.endDate);
        const yesterdayDate = new Date(yesterday);
        return (
            startDate.getDate() === new Date(yesterdayDate).getDate() &&
            startDate.getMonth() === new Date(yesterdayDate).getMonth() &&
            startDate.getFullYear() === new Date(yesterdayDate).getFullYear() &&
            endDate.getDate() === new Date(formattedThirtyDaysAgo).getDate() &&
            endDate.getMonth() === new Date(formattedThirtyDaysAgo).getMonth() &&
            endDate.getFullYear() === new Date(formattedThirtyDaysAgo).getFullYear()
        );
    };


    return (
        <>
            {loading && <Spinner />}
            {errorMessageAlert && <ErrorAlert errorMessage={errorMessageAlert} />}
            {errorMessage ?
                <span className=" pad-20 text-center mt-20 mb-10 error-message">{errorMessage}</span> :

                <Row className="row-pad-0 mt-20 border-radius-5 bg-white dashboard-analytics">
                    {dashboardAnalytics && (
                        <JobAnalytics
                            cardModel={cardModel}
                            formData={dateFilters.jobAnalytics}
                            dashboardAnalytics={dashboardAnalytics}
                            handleOpenModal={() => handleOpenModal("jobAnalytics")}
                            Yesterday={isYesterdayFilter(dateFilters.jobAnalytics)}
                        />
                    )}
                </Row>
            }
            {showModal && (
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Filter
                            startDate={formData.startDate}
                            endDate={formData.endDate}
                            onchange={handleDateChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>

                        <Button className="custom-btn-primary" onClick={handleSubmit}>
                            Apply
                        </Button>
                        <Button variant="secondary" onClick={handleReset}>
                            Reset
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default JobAnalyticsCard;
