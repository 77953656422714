
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Checkbox, CustomButton, InputField, Label, SelectField, Switch, Textarea } from "../form/index"
import { JobActive, crossicon, faplusicon, faminusicon, copyicon } from "../../helper/Icons/Icon"
import { Container, Row, Col, Offcanvas, Form } from "react-bootstrap"
import { postjobfiled } from "./fields";
import Spinner from "../common/Spinner";
import TextEditor from "../common/TextEditor";
import { Link, useNavigate } from "react-router-dom"
import InviteCandidates from "./InviteCandidates";
import { getAll } from '../../store/actions/interviewTemplateActions';
import { update, getById } from '../../store/actions/jobActions'
import { useDispatch, useSelector } from 'react-redux';

import Industries from '../common/Industries';
import JobTypes from '../common/JobTypes';
import PayTypes from '../common/PayTypes';
import Countries from '../common/Countries';
import States from '../common/States';
import WorkspaceTypes from '../common/WorkspaceTypes';
import WorkAuthorization from '../common/WorkAuthorization';
import DesignatedUsers from '../common/DesignatedUsers';
import PostJobInterviewTemplates from '../common/PostJobInterviewTemplates';
import Companies from '../common/Companies';
import * as moment from 'moment';

import Swal from 'sweetalert2';
const ERROR_MESSAGES = {
    job_title: 'Job title is required',
    job_type_id: 'Job type is required',
    company_id: 'Company is required',
    city: 'City is required',
    state_name: 'State is required',
    country_name: 'Country is required',
    zip_code: 'Zip code is required',
    job_expiry_date: 'Job expiration date is required',
    pay_type_id: 'Pay type is required',
    pay_salary: 'Pay/salary is required',
    job_description: 'Job description is required'
};
const Editjob = ({ jobId, handleClose }) => {
    const today = moment().format('YYYY-MM-DD');
    const [contentEditor, setContentEditor] = useState('');
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const { jobDetail, loading, error } = useSelector((state) => state.job);
    const [formSate, setFormSate] = useState(null);
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const [showInviteCandidates, setShowInviteCandidates] = useState(false);

    const inputRef = useRef(null);
    const { interviewTemplates } = useSelector((state) => state.interviewTemplate);
    useEffect(() => {
        if (jobId) {
            dispatch(getById(jobId));
            dispatch(getAll());
        }

    }, [dispatch, jobId]);

    useLayoutEffect(() => {
        if (jobDetail) {
            setFormData(jobDetail)
            setContentEditor(jobDetail?.job_description)
        }

    }, [jobDetail]);
    // const formattedJobExpiryDate = moment(formData?.job_expiry_date).format('YYYY-MM-DD');
    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        let newValue = value;
        console.log(name, "name,", value)
        if (e.target.type === 'checkbox' || e.target.type === 'switch') {
            newValue = checked;
        }


        if (name === 'pay_salary' && isNaN(value)) {

            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: 'Only numbers are allowed'
            }));

            return;
        }


        //  setFormData({ ...data, [name]: newValue });
        setFormData((prevData) => {
            const updatedFormData = { ...prevData, [name]: value };
            validateField(name, value);
            return updatedFormData;
        });


    };

    const handleSearchChange = (name, value) => {

        let newValue = value;
        if (name == "country_name") {
            setFormSate(null)
        }
        if (name === 'industry_id' && newValue === '') {
            newValue = null;
        }

        //  setFormSate({ ...data, [name]: newValue });
        setFormData((prevData) => {
            const updatedFormData = { ...prevData, [name]: newValue };
            validateField(name, newValue);
            return updatedFormData;
        });
    };
    useEffect(() => {

        setFormData((prevData) => {
            const updatedFormData = { ...prevData, job_description: contentEditor };
            validateField('job_description', contentEditor);
            return updatedFormData;
        });


    }, [contentEditor])
    const validateField = (name, value) => {
        setErrors((prevErrors) => {
            let error = '';
            if (!value) {
                error = ERROR_MESSAGES[name];
            }

            return { ...prevErrors, [name]: error };
        });
    }
    const jobprivateSwitch = () => {
        setIsChecked(!isChecked);
    };



    const Invite = () => {
        navigate("/jobtemplate");
    };

    const sweetAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            showConfirmButton: false,
            timer: 3000
        });
    }
    const validateForm = () => {
        const errors = {};
        if (!formData.job_title || formData.job_title.trim() === '') {
            errors.job_title = ERROR_MESSAGES.job_title;
        }

        if (!formData.job_type_id) {
            errors.job_type_id = ERROR_MESSAGES.job_type_id;
        }

        if (!formData.company_id) {
            errors.company_id = ERROR_MESSAGES.company_id;
        }

        if (!formData.city) {
            errors.city = ERROR_MESSAGES.city;
        }

        if (!formData.state_name) {
            errors.state_name = ERROR_MESSAGES.state_name;
        }

        if (!formData.country_name) {
            errors.country_name = ERROR_MESSAGES.country_name;
        }

        if (!formData.zip_code) {
            errors.zip_code = ERROR_MESSAGES.zip_code;
        }

        if (!formData.job_expiry_date) {
            errors.job_expiry_date = ERROR_MESSAGES.job_expiry_date;
        }

        if (!formData.pay_type_id) {
            errors.pay_type_id = ERROR_MESSAGES.pay_type_id;
        }

        if (!formData.pay_salary) {
            errors.pay_salary = ERROR_MESSAGES.pay_salary;
        }

        if (!formData.job_description) {
            errors.job_description = ERROR_MESSAGES.job_description;
        }

        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);
        if (Object.keys(validationErrors).length === 0) {

            var payload = formData;
            dispatch(update({ jobId, payload }))
                .then((res) => {
                    if (res.payload.status == 500) {
                        return sweetAlert(
                            "error",
                            "Error!",
                            res.payload.detail

                        )


                    }
                    if (res.payload.error) {

                        return sweetAlert(
                            "error",
                            "Error!",
                            res.payload.error

                        )

                    }
                    else {

                        handleClose()
                        return sweetAlert(
                            "success",
                            "Success!",
                            res.payload.message ? "Job updated successfully" : res.payload.message

                        )

                    }
                })
                .catch(error => {

                    return sweetAlert(
                        "error",
                        "Error!",
                        "Something went wrong!"
                    )
                });
        }
        else {
            setErrors(validationErrors);
        }


    };


    return (
        formData &&
        <>

            <Offcanvas
                show={true}
                onHide={handleClose}
                placement="end"
                className="bg-ligte-gray custom-offcanvas"
                style={{ width: "60%" }}
            >
                <Container
                    fluid
                    className="row-pad-0 pad-0 bg-ligte-gray pad-20 wrap-postjob"
                >

                    {loading &&
                        <Spinner />
                    }

                    <Offcanvas.Header className="model-header bg-white ">
                        <Offcanvas.Title>
                            <span className="model-header-svg">{JobActive}Edit a Job</span>

                        </Offcanvas.Title>
                        <div className="text-end">
                            <CustomButton
                                onClick={handleClose}
                                className="text-end close-btn "
                                text={crossicon}
                            />
                        </div>
                    </Offcanvas.Header>

                    <Offcanvas.Body className="mt-10 wrap postjob-body">
                        <Form>
                            <Row className="row-pad-0 bg-white mt-20 postjob ">

                                <Col md={12} className="pad-margin mb-20">
                                    <Label text="Job Title" className="label-input" HtmlFor="JobTitle" required="required" />
                                    <InputField
                                        type="text"
                                        placeholder="Enter title of the first job you are hiring for"
                                        name="job_title"
                                        id="job_title"
                                        onChange={handleChange}
                                        Value={formData?.job_title}
                                    />
                                    {errors.job_title && <><span className="text-danger">{errors.job_title}</span></>}
                                </Col>


                                <Col md={6} className="pad-margin mb-20">
                                    <JobTypes typeId={formData?.job_type_id} onChange={handleSearchChange} />
                                    {errors.job_type_id && <><span className="text-danger">{errors.job_type_id}</span></>}
                                </Col>
                                <Col md={6} className="pad-margin mb-20">
                                    <Companies companyId={formData?.company_id} onChange={handleSearchChange} editjobcom="notwork" />
                                    {errors.company_id && <><span className="text-danger">Company is required</span></>}
                                </Col>
                                <Col md={6} className="pad-margin pad-margin mb-20">

                                    {interviewTemplates && <PostJobInterviewTemplates interviewTemplateId={formData?.interview_template_id} interviewTemplates={interviewTemplates} onChange={handleSearchChange} EditjobTemplates="hide" />}
                                    {errors.interview_template_id && <><span className="text-danger">{errors.interview_template_id}</span></>}
                                </Col>

                                <Col md={6} className="pad-margin pad-margin mb-20">
                                    <DesignatedUsers designatedUserId={formData?.followup_user} onChange={handleSearchChange} />
                                    {errors.followup_user && <><span className="text-danger">{errors.followup_user}</span></>}
                                </Col>

                                <Col md={6} className="pad-margin mb-20">
                                    <Industries industryId={formData?.industry_id} onChange={handleSearchChange} />
                                </Col>
                                <Col md={6} className="pad-margin pad-margin mb-20">
                                    <WorkAuthorization workAuthorizationId={formData?.work_authorization_id} onChange={handleSearchChange} />
                                </Col>
                                <Col md={6} className="pad-margin pad-margin mb-20">
                                    <Label text="City" className="label-input" HtmlFor="" required="required" />
                                    <InputField
                                        type="text"
                                        name="city"
                                        placeholder="city"
                                        onChange={handleChange}
                                        Value={formData?.city}
                                    />
                                    {errors.city && <><span className="text-danger">{errors.city}</span></>}
                                </Col>
                                <Col md={6} className="pad-margin pad-margin mb-20">
                                    <States stateName={formData?.country_name} state={formData.state_name ?? null} onChange={handleSearchChange} />
                                    {errors.state_name && <><span className="text-danger">{errors.state_name}</span></>}
                                </Col>
                                <Col md={6} className="pad-margin pad-margin mb-20">
                                    <Countries countryName={formData?.country_name} onChange={handleSearchChange} />
                                    {errors.country_name && <><span className="text-danger">{errors.country_name}</span></>}
                                </Col>
                                <Col md={6} className="pad-margin pad-margin mb-20">
                                    <Label text="Zip Code " className="label-input" HtmlFor="" required="required" />
                                    <InputField
                                        type="text"
                                        name="zip_code"
                                        placeholder="Enter ZIP code"
                                        onChange={handleChange}
                                        Value={formData?.zip_code} />
                                    {errors.zip_code && <><span className="text-danger">{errors.zip_code}</span></>}
                                </Col>
                                <Col md={6} className="pad-margin pad-margin mb-20">
                                    <Label text="Job Expiration Date " className="label-input" HtmlFor="" required="required" />
                                    <Form.Control

                                        name='job_expiry_date'
                                        min={moment(new Date()).format('YYYY-MM-DD')}
                                        onClick={(e) => e.target.showPicker()}
                                        type="date"
                                        onChange={handleChange}
                                        value={moment(formData?.job_expiry_date).format('YYYY-MM-DD')}
                                    />
                                    {errors.job_expiry_date && <><span className="text-danger">{errors.job_expiry_date}</span></>}
                                </Col>
                                <Col md={6} className="pad-margin pad-margin mb-20">
                                    <WorkspaceTypes workSpaceTypeId={formData?.workspace_type_id} onChange={handleSearchChange} />
                                </Col>
                                <Col md={6} className="pad-margin mb-20">
                                    <PayTypes payTypeId={formData?.pay_type_id} onChange={handleSearchChange} />
                                    {errors.pay_type_id && <><span className="text-danger">{errors.pay_type_id}</span></>}
                                </Col>

                                <Col md={6} className="pad-margin">
                                    <Label text="Pay/Salary" className="label-input" HtmlFor="paySalary" required="required" />
                                    <InputField
                                        type="text"
                                        placeholder="Pay/Salary"
                                        name="pay_salary"
                                        id="pay_salary"
                                        onChange={handleChange}
                                        Value={formData?.pay_salary}
                                    />

                                    {errors.pay_salary && <><span className="text-danger">{errors.pay_salary}</span></>}
                                </Col>

                                <Col md={12} className="mt-10 mb-10">
                                    <Label HtmlFor="" className="label-input" text="Job Description" required="required" />


                                    <TextEditor
                                        textValue={contentEditor}
                                        setTextValue={setContentEditor}
                                        placeholderValue={"Enter job description"}
                                    />

                                    {errors.job_description && <><span className="text-danger">{errors.job_description}</span></>}
                                </Col>
                                <Col md={6} className="label-checkbox">
                                    {/* <Checkbox name="is_build_template" checked={formData.is_build_template} label="Save this job as a template" onChange={handleChange} />
                                 */}
                                </Col>
                                <Col md={6} className="jobprivate-switch ">
                                    <Switch name="is_private" checked={formData.is_private} onChange={handleChange} />
                                    <span className="span">Make job private</span>
                                </Col>
                            </Row>

                            <Col md={12} className="text-end mt-20 mb-20 pad-0">
                                <CustomButton
                                    className="mr-10 mb-10 custom-btn-primary "
                                    text="Publish Job"
                                    onClick={handleSubmit}
                                />
                                {/* <CustomButton
                                    className="custom-btn-primary mb-10"
                                    onClick={PublishInviteCandidates}
                                    text="Publish Job & Invite Candidates"
                                /> */}
                            </Col>
                        </Form>
                    </Offcanvas.Body>
                </Container>
            </Offcanvas >

        </>
    );
};


export default Editjob;
