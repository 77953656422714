import { createSlice } from '@reduxjs/toolkit'
import { login, logout, changePassword, Forgetpassword, changePasswordByID, verifyPasswordToken, updatePassword } from '../actions/authActions'

const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

const initialState = {
    loading: false,
    userToken,
    userInfoForgetpsd: null,
    verifyToken: null,
    error: null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = false
                state.userInfo = action.payload.user
                state.userToken = action.payload.token
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
        builder
            .addCase(changePassword.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.loading = false
                state.userInfo = action.payload

            })
            .addCase(changePassword.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
        builder
            .addCase(changePasswordByID.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(changePasswordByID.fulfilled, (state, action) => {
                state.loading = false
                // state.userInfo = action.payload

            })
            .addCase(changePasswordByID.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
        builder
            .addCase(logout.fulfilled, (state, action) => {
                state.loading = false
                state.userToken = null
            })
        builder

            .addCase(Forgetpassword.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(Forgetpassword.fulfilled, (state, action) => {
                state.loading = false
                state.userInfoForgetpsd = action.payload

            })
            .addCase(Forgetpassword.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(updatePassword.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(updatePassword.fulfilled, (state, action) => {
                state.loading = false
                state.userInfoForgetpsd = action.payload

            })
            .addCase(updatePassword.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(verifyPasswordToken.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(verifyPasswordToken.fulfilled, (state, action) => {
                state.loading = false
                state.verifyToken = action.payload

            })
            .addCase(verifyPasswordToken.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
    },
})

export default authSlice.reducer