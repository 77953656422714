import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Offcanvas, Container, Col, Row } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CustomButton, Textarea } from "../form";
import InviteAnotherJobCadidates from "../common/InviteAnotherJobCadidates";
import CandidatesList from "./CandidatesList";
import { crossicon, adduser, attachment, Jobs } from "../../helper/Icons/Icon";
import ActionList from "../common/ActionList";
import { actionlistfields, headertimeLine } from "./fields"
import FeedTimeLine from "../common/FeedTimeLine";
import Interview from "../RecordedInterviewDetails/Interview";
import ShareCandidate from "../common/ShareCandidate";
import { getByName } from "../../store/actions/userPrefrenceActions.js";
import Editjob from "./Editjob";
import Spinner from "../common/Spinner";
import * as moment from 'moment'
import { getById, deleteJob } from '../../store/actions/jobActions'
import Swal from 'sweetalert2';
import ErrorMessage from "../common/ErrorMessage";
import InviteCandidates from "./InviteCandidates.jsx";

import ChangeStatus from "./ChangeStatus";
import ScheduleInterviews from "../common/ScheduleInterview";

import Postjob from "./Postjob";
const renderTooltip = (text) => (
    <Tooltip id="tooltip">
        {text}
    </Tooltip>
);
const JobDetail = ({ handleClose, jobId }) => {
    const prefrenceName = "users";
    const dispatch = useDispatch();
    const [updateList, setUpdateList] = useState(true)
    const [refreshList, setrefreshList] = useState(false)
    const [jobPid, setJobId] = useState(jobId);
    const [showScheduleInterviews, setScheduleInterviews] = useState(false);
    const [showGenerateLink, setGenerateLink] = useState(false);
    const [showInviteCandidates, setInviteCandidates] = useState(false);
    const [showPostJob, setPostJob] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null)
    const [showEditPage, setShowEditPage] = useState(false);
    const [showactionlist, setshowactionlist] = useState(false);
    const [showChangeStatus, setChangeStatus] = useState(false);
    const [showInterview, setInterview] = useState(false);
    const [jobStatus, setjobStatus] = useState();
    const [showTooltip, setShowTooltip] = useState({});

    const { jobDetail, loading, error } = useSelector((state) => state.job);
    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);

    useEffect(() => {
        if (jobPid) {
            dispatch(getById(jobPid)).then((res) => {
                if (res.type === "job/getById/rejected") {

                    if (res.payload) {
                        setErrorMessage(res.payload);
                    } else {

                        setErrorMessage({
                            title: "An error occurred",
                            detail: "Unable to retrieve job details at this time.Please try again later."
                        });
                    }
                } else {

                    setErrorMessage(null);
                }

            });
        }

    }, [dispatch, jobId, updateList]);
    useEffect(() => {
        dispatch(getByName(prefrenceName));
    }, [dispatch, jobId]);



    const refreshPage = () => {
        dispatch(getById(jobPid))
        setUpdateList(!updateList)

    }

    const handleMouseEnter = (key) => {
        setShowTooltip(prev => ({ ...prev, [key]: true }));
    };

    const handleMouseLeave = (key) => {
        setShowTooltip(prev => ({ ...prev, [key]: false }));
    };
    const deleteMsg = () => {
        Swal.fire({
            icon: 'success',
            title: 'Deleted',
            text: 'Deleted a job successfully',
            showConfirmButton: false,
            timer: 3000
        });

    }

    const handleDelete = (jobId = false) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure to delete the selected item(s).',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                if (jobId) {
                    dispatch(deleteJob(jobId))
                        .then(() => {

                            deleteMsg();
                            handleClose()
                        });
                }
            }
        });


    };
    const toggleCandidates = (type, id, statusJob) => {




        if (type === "ChangeStatus") {
            setJobId(id)
            setjobStatus(statusJob)
            setChangeStatus(!showChangeStatus)

        }


    };

    const handleModel = (type, ID) => {
        setJobId(ID)
        if (type === "adduser") {
            setInviteCandidates(true);

        }

        if (type === "actionList") {
            setshowactionlist(true)
        }
        if (type === "CopyJobOrder") {
            setPostJob(true)

        }
        if (type === "BulkInviteCandidates") {
            setInviteCandidates(true);

        }
        if (type === "GenerateRecordedLink") {
            setGenerateLink(true)
        }
        if (type === "ScheduleLiveInterview") {
            setScheduleInterviews(true);
        }
        if (type === "editJob") {
            setShowEditPage(true);

        }
        if (type === "Delete") {
            handleDelete(ID)

        }

    }

    const handelCloseModel = (type, id) => {
        if (type === "actionList") {
            setshowactionlist(false)
        }
        if (type === "BulkInviteCandidates") {
            setInviteCandidates(false);

        }
        if (type === "Interview") {
            setInterview(false)
        }
        if (type === "GenerateRecordedLink") {
            setGenerateLink(false)
        }
        if (type === "CopyJobOrder") {
            setPostJob(false)

        }
        if (type === "ScheduleLiveInterview") {
            setScheduleInterviews(false);

        }
        if (type === "editJob") {
            setShowEditPage(false);
            setUpdateList(!updateList)


        }


    }

    // const stripHtmlTags = (str) => {
    //     return str.replace(/<[^>]*>/g, ' ');
    // };
    return (
        <>


            <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "82%" }}>
                <Container fluid className="detailpage-wrap pad-0">
                    {loading &&
                        <Spinner />
                    }


                    {errorMessage && <ErrorMessage error={errorMessage} closebtn={<CustomButton className="closeIconouter" onClick={handleClose} icon={crossicon} />} />}
                    {jobDetail && <>    <Row className="row-pad-0">
                        <div className="header">
                            <div className="header-left">
                                {/* <span>{Jobs}</span> */}
                                <h4>Job Orders Detail</h4>
                                <div className="date">
                                    <p className="created">
                                        <span>CREATED BY: {jobDetail.created_by}</span>
                                        <small> {jobDetail.created_date && moment(jobDetail.created_date).format('MM-DD-YYYY h:mm A')}</small>
                                    </p>
                                    <p className="updated">
                                        <span>UPDATED BY: {jobDetail.updated_by}</span>
                                        <small>{jobDetail.updated_date && moment(jobDetail.updated_date).format('MM-DD-YYYY h:mm A')}</small>
                                    </p>
                                </div>

                                <CustomButton className="actionlist-btn" onClick={() => handleModel("actionList", jobDetail.id)} icon={<i class="fa fa-ellipsis-v" ></i>} />
                            </div>
                            <div className="header-right">
                                <ul className="right-ul">
                                    <li>
                                        <OverlayTrigger
                                            placement="bottom"
                                            show={showTooltip['InviteCandidates']}
                                            overlay={renderTooltip("Invite Candidates")}
                                        >
                                            <span
                                                onMouseEnter={() => handleMouseEnter('InviteCandidates')}
                                                onMouseLeave={() => handleMouseLeave('InviteCandidates')}
                                            >

                                                <CustomButton
                                                    className="circle-btn ml-2"
                                                    onClick={() => handleModel("BulkInviteCandidates", jobDetail.id)}
                                                    icon={adduser}
                                                />
                                            </span>
                                        </OverlayTrigger>
                                    </li>
                                    <li>
                                        <OverlayTrigger
                                            placement="bottom"
                                            show={showTooltip['generateLink']}
                                            overlay={renderTooltip("Generate Link")}
                                        >
                                            <span
                                                onMouseEnter={() => handleMouseEnter('generateLink')}
                                                onMouseLeave={() => handleMouseLeave('generateLink')}

                                            >
                                                <CustomButton
                                                    className="circle-btn ml-10"
                                                    onClick={() => handleModel("GenerateRecordedLink", jobDetail.id)}
                                                    icon={attachment}
                                                />
                                            </span>
                                        </OverlayTrigger>
                                    </li>
                                </ul>


                                <ul className="right-ul">
                                    <li>
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={renderTooltip("Refresh")}
                                        >
                                            <span>
                                                <CustomButton className="btn-default" onClick={refreshPage} icon={<i class="fa fa-refresh"></i>} />

                                            </span>
                                        </OverlayTrigger>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Row>
                        <Col md={12} className="body-wrap pad-0">
                            <CustomButton className="closeIconouter" onClick={handleClose} icon={crossicon} />
                            <div className="body-left-side ">
                                <Row className="row-pad-0 custom-row-pad-0">
                                    <Col md={1} className="custom-md-1 mt-15">
                                        <div className="profile">
                                            <span>{Jobs}</span>
                                        </div>
                                    </Col>
                                    <Col md={11} className="content pad-0 custom-md-11 mt-10">
                                        <div className="flex-space-between">
                                            <div className="d-flex base-line">
                                                <h5 className="min-sub-heading">
                                                    <label >{jobDetail.job_title} <small className="small-text"> / {jobDetail.company_name}</small> </label>
                                                </h5>
                                                <CustomButton className={`btn-status ml-10 status-${jobDetail.stage_name}`} text={jobDetail.stage_name} onClick={() => toggleCandidates("ChangeStatus", jobDetail.id, jobDetail.stage_name)} />
                                            </div>
                                            <div>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    show={showTooltip['editJob']}
                                                    overlay={renderTooltip("Edit  Job")}
                                                >
                                                    <span
                                                        onMouseEnter={() => handleMouseEnter('editJob')}
                                                        onMouseLeave={() => handleMouseLeave('editJob')}

                                                    >
                                                        <CustomButton className="actionlist-btn" onClick={() => handleModel("editJob", jobDetail.id)} icon={<i class="fa fa-pencil" style={{ fontSize: "14px", color: "black" }}></i>} />    </span>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                        <div className="flex-Xcenter">
                                            <div className="list-title">
                                                <label className="label-view">Job Type :</label><span className="view-text">{jobDetail.job_type_name}</span>
                                            </div>
                                            <div className="list-title">
                                                <label className="label-view">Pay/Salary :</label><span className="view-text">{jobDetail.pay_salary}</span>
                                            </div>
                                            <div className="list-title">
                                                <label className="label-view">Work Authorization :</label><span className="view-text">{jobDetail.work_authorization_name}</span>
                                            </div>
                                            <div className="list-title">
                                                <label className="label-view">Workspace :</label><span className="view-text">{jobDetail.workspace_type_name}</span>
                                            </div>
                                            <div className="list-title">
                                                <label className="label-view">Job Expires on :</label><span className="view-text">{moment(jobDetail.job_expiry_date).format('MM-DD-YYYY')}</span>
                                            </div>
                                            <div className="list-title">
                                                <label className="label-view">Pay Type :</label><span className="view-text">{jobDetail.pay_type_name}</span>
                                            </div>
                                            <div className="list-title">
                                                <label className="label-view">Interview Template :</label><span className="view-text">{jobDetail.interview_template_name}</span>
                                            </div>
                                            <div className="list-title">
                                                <label className="label-view">Followup User:</label><span className="view-text">{jobDetail.followup_user_name}</span>
                                            </div>
                                            <div className="list-title">
                                                <label className="label-view"> Industry :</label><span className="view-text">{jobDetail.industry_name}</span>
                                            </div>
                                        </div>
                                        <div className="list-location">
                                            <label className="label-view">Location :</label>
                                            <span className="view-text">
                                                <a
                                                    href={`https://www.google.com/maps/place/${encodeURIComponent(`${jobDetail.city}, ${jobDetail.state_name} ${jobDetail.zip_code}, ${jobDetail.country_name}`)}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {`${jobDetail.city}, ${jobDetail.state_name} ${jobDetail.zip_code}, ${jobDetail.country_name}`}
                                                </a>


                                            </span>
                                        </div>

                                        <div className="list-description">
                                            <label className="label-view">Job Description :</label>
                                            {/* <Textarea
                                                as="textarea"
                                                name="interview_template_description"
                                                className="input-textarea"
                                                rows={3}
                                                readOnly

                                                value={stripHtmlTags(jobDetail.job_description)}

                                            /> */}
                                            <p

                                                style={{ height: "90px" }}
                                                className="view-text bg-white pad-10 radius-5 resizable"
                                                dangerouslySetInnerHTML={{ __html: jobDetail.job_description }}
                                            ></p>

                                        </div>

                                    </Col>
                                </Row>

                                <CandidatesList jobId={jobPid} update={updateList} mainPageClose={handleClose} preferenceValue={userPrefrenceDetail && userPrefrenceDetail?.preference_value?.record_per_page} />

                            </div>
                            <div className="body-right-side">
                                <FeedTimeLine module={"jobs"} module_pk_id={jobPid} mainPageClose={handleClose} />
                            </div>
                        </Col>
                    </>}
                </Container>
            </Offcanvas>


            {showInterview && <Interview headertimeLine={headertimeLine} handleClose={() => handelCloseModel("Interview")} />}
            {showactionlist && <ActionList ID={jobId} list={actionlistfields} headertext=" Action List " onclick={handleModel} handleClose={() => handelCloseModel("actionList")} />}
            {showInviteCandidates && <InviteCandidates jobId={jobId} handleClose={() => handelCloseModel("BulkInviteCandidates")} />}
            {showEditPage && <Editjob jobId={jobId} handleClose={() => handelCloseModel("editJob")} />}
            {showGenerateLink && <  InviteAnotherJobCadidates jobId={jobId} handleClose={() => handelCloseModel("GenerateRecordedLink")} />}
            {showPostJob && <Postjob copyJobId={jobId} handleClose={() => handelCloseModel("CopyJobOrder")} />}
            {/* {showGenerateLink && <GenerateLink jobId={jobId} handleClose={() => handelCloseModel("GenerateRecordedLink")} />}
            */}

            {showScheduleInterviews && <ScheduleInterviews jobId={jobId} handleClose={() => handelCloseModel("ScheduleLiveInterview")} />}
            {showChangeStatus && <ChangeStatus handleClose={() => toggleCandidates("ChangeStatus")} jobId={jobId} statusActive={jobStatus} reloadList={setUpdateList} />}
        </>


    )
}

export default JobDetail;