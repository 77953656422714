import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvitations } from "../store/actions/DashboardAction";
import Card from "../components/dashboard/Card";
import Filter from "./Filter";
import { Modal, Button, Row } from 'react-bootstrap';
import { createPreference } from "../store/actions/userActions";
import { getByName } from "../store/actions/userPrefrenceActions";

const InvitationsCard = ({ HandelCardChart, setJobAnalyticsDate }) => {


    const [showModalCard, setShowModalCard] = useState(false);
    const [customProps, setCustomProps] = useState(null);

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

    const formattedThirtyDaysAgo = thirtyDaysAgo.toISOString().split("T")[0];
    const yesterday = new Date();
    // yesterday.setMonth(yesterday.getMonth() + 1);
    const formattedYesterday = yesterday.toISOString().split("T")[0];

    const [formData, setFormData] = useState({
        startDate: formattedThirtyDaysAgo,
        endDate: formattedYesterday,
    });

    const [dateFilters, setDateFilters] = useState({
        invitations: {
            startDate: formattedThirtyDaysAgo,
            endDate: formattedYesterday,
        },
    });


    const [userPreferences, setUserPreferences] = useState({});

    const dispatch = useDispatch();
    const { dashboardInvitations } = useSelector(state => state.dashboard);

    const Invitations = [
        dashboardInvitations?.invited_interviews_count,
        dashboardInvitations?.submitted_interviews_count,
        dashboardInvitations?.passed_interviews_count,
        dashboardInvitations?.failed_interviews_count,
    ];


    useEffect(() => {
        fetchUserPreferences();
    }, [dispatch]);

    const fetchUserPreferences = () => {
        const preferenceNames = "invitations";

        dispatch(getByName(preferenceNames)).then((res) => {
            const { payload } = res;
            if (payload && payload.preference_value) {
                const { startDate, endDate } = payload.preference_value;

                const start = startDate;
                const end = endDate;

                const newDateFilters = {
                    invitations: {
                        startDate: start,
                        endDate: end,
                    },
                };

                setUserPreferences({ invitations: { startDate: start, endDate: end } });
                setDateFilters(newDateFilters);
                fetchInitialData(newDateFilters);
            }
            else {
                fetchInitialData({ invitations: { startDate: formData.startDate, endDate: formData.endDate } });
            }
        });
    };

    const fetchInitialData = (filters) => {
        const { startDate, endDate } = filters.invitations;

        if (startDate && endDate) {

            setJobAnalyticsDate({
                startDate: startDate,
                endDate: endDate,
            })
            dispatch(getInvitations({ startDate, endDate }));

        } else {
            console.error("Invalid date objects", startDate, endDate);
        }
    };

    const handleDateChange = (name, value) => {
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = () => {

        const startDate = new Date(formData.startDate).toISOString().split("T")[0]
        const endDate = new Date(formData.endDate).toISOString().split("T")[0]
        setDateFilters((prev) => ({
            ...prev,
            [customProps]: { startDate, endDate },
        }));
        setJobAnalyticsDate({
            startDate: startDate,
            endDate: endDate,
        })
        const payload = {
            preference_value: {
                startDate: startDate,
                endDate: endDate,
            },
            preference_name: customProps,
            module_code: "dashboardCard",
        };

        dispatch(createPreference(payload))
            .then(() => {
                if (customProps === "invitations") {
                    dispatch(getInvitations({ startDate, endDate }));
                }

                setShowModalCard(false);
            })
            .catch((error) => console.error("Preference update error:", error));
    };

    const handleOpenModalCard = (props) => {
        setCustomProps(props);
        setFormData(dateFilters[props]);
        setShowModalCard(true);
    };

    const handleReset = () => {
        const resetFilters = {
            invitations: {
                startDate: formattedThirtyDaysAgo,
                endDate: formattedYesterday,
            },
        };

        setDateFilters(resetFilters);
        setFormData({
            startDate: resetFilters.invitations.startDate,
            endDate: resetFilters.invitations.endDate,
        });
        setJobAnalyticsDate({
            startDate: resetFilters.invitations.startDate,
            endDate: resetFilters.invitations.endDate,
        })
        const payload = {
            preference_value: {
                startDate: resetFilters.invitations.startDate,
                endDate: resetFilters.invitations.endDate,
            },
            preference_name: customProps,
            module_code: "dashboardCard",
        };

        dispatch(createPreference(payload))
            .then(() => {
                dispatch(getInvitations({
                    startDate: resetFilters.invitations.startDate,
                    endDate: resetFilters.invitations.endDate,
                }));

                setShowModalCard(false);
            })
            .catch((error) => console.error("Preference update error:", error));
    };


    const isYesterdayFilter = (formData) => {


        const startDate = new Date(formData.startDate);
        const endDate = new Date(formData.endDate);
        const yesterdayDate = new Date(yesterday);
        return (
            startDate.getDate() === new Date(yesterdayDate).getDate() &&
            startDate.getMonth() === new Date(yesterdayDate).getMonth() &&
            startDate.getFullYear() === new Date(yesterdayDate).getFullYear() &&
            endDate.getDate() === new Date().getDate() &&
            endDate.getMonth() === new Date().getMonth() &&
            endDate.getFullYear() === new Date().getFullYear()
        );
    };
    return (
        <>
            {/* Conditional rendering: only show if it's "yesterday" */}


            <Card
                formData={dateFilters.invitations}
                dashboard={Invitations}
                labelData={['Invited', 'Submitted', 'Passed', 'Failed']}
                handleOpenModal={() => handleOpenModalCard("invitations")}
                cardModel={HandelCardChart}
                name="Interviews"
                nameapi="Interviews"
                Yesterday={isYesterdayFilter(dateFilters.invitations)}
            />

            {showModalCard && (
                <Modal show={showModalCard} onHide={() => setShowModalCard(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Filter
                            startDate={formData.startDate}
                            endDate={formData.endDate}
                            onchange={handleDateChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="custom-btn-primary" onClick={handleSubmit}>
                            Apply
                        </Button>
                        <Button variant="secondary" onClick={handleReset}>
                            Reset
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default InvitationsCard;
