import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Offcanvas, Container, Row, Col } from 'react-bootstrap';
import CriclrDiv from '../common/CriclrDiv';
import ViewerFile from '../common/ViewerFile';
import Spinner from '../common/Spinner';
import { CustomRating } from '../form/index';
import CandidateRespones from '../common/CandidateRespones';
import TimeLine from './TimeLine';
import { crossicon, acceptcandidate, rejectecandidate, share, downloadarrowblow, Video, HiredICon } from '../../helper/Icons/Icon';
import { CustomButton } from '../form/index';
import aryaimg from '../../assets/images/users.png';
import RatingsTabel from './RatingsTabel';

import CommentModel from '../common/CommentModel';
import CustomAlert from '../common/CustomAlert';
import ShareCandidate from '../common/ShareCandidate';
import { downloadinterview } from '../../store/actions/interviewActions';
import { getRecordedInterview } from '../../store/actions/interviewActions';
import { commentCreateAccept, commentCreatecandRejected, commentCreateHired } from '../../store/actions/recorderInterviewAction';
import Swal from 'sweetalert2';
const config = require('../../config/config.json');

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 2500
    });
}

const RecordedInterview = ({ handleClose, id }) => {
    const [updateList, setUpdateList] = useState(false)
    const [showShareCandidate, setShowShareCandidate] = useState(false);
    const [showApcceptCandidate, setApcceptCandidate] = useState(false);
    const [showRejectCandidate, setRejectCandidate] = useState(false);
    const [showDocViewer, setDocViewer] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [commentData, setCommentData] = useState();

    const [showHiredCandidate, setHiredCandidate] = useState(false);
    const dispatch = useDispatch();

    const { recordedInterview, loading, error } = useSelector((state) => state.interview);
    const candidate = recordedInterview?.interview_candidate;
    const jobId = recordedInterview?.job_id;
    const interviewId = recordedInterview?.interview_id;
    const candidateId = candidate?.candidate_id;
    // const candidateRespones = recordedInterview?.interview_template_questions;
    const InterviewUnderway = recordedInterview?.interview_stage?.stage_name.includes("Interview Underway") && recordedInterview?.interview_stage?.stage_name.includes("Interview Underway")
    const Invited = recordedInterview?.interview_stage?.stage_name.includes("Invited") && recordedInterview?.interview_stage?.stage_name.includes("Invited")

    useEffect(() => {
        if (id) {
            dispatch(getRecordedInterview(id));
        }
    }, [dispatch, updateList, id]);



    const handelComment = (e) => {
        const { name, value } = e.target;
        setCommentData({
            ...commentData,
            [name]: value
        });
    }

    const handelAccept = () => {
        const payload = commentData;

        if (!candidateId || !interviewId) {
            console.error('Candidate ID or Interview ID is undefined.');
            return;
        }

        dispatch(commentCreateAccept({ candidateId, interviewId, payload }))
            .then(() => {
                handelCloseModel('apcceptCandidate');
                dispatch(getRecordedInterview(id));
                return sweetAlert('success', 'Success!', 'Candidate Passed successfully');

            })
            .catch((error) => {
                console.error('Error:', error);
                return sweetAlert('error', 'Error!', 'Something went wrong!');
            });
    };

    const handelRejected = () => {
        const payload = commentData;

        if (!candidateId || !interviewId) {
            console.error('Candidate ID or Interview ID is undefined.');
            return;
        }

        dispatch(commentCreatecandRejected({ candidateId, interviewId, payload }))
            .then(() => {
                handelCloseModel('rejectCandidate');
                dispatch(getRecordedInterview(id));
                return sweetAlert('success', 'Success!', 'Candidate marked as rejected');

            })
            .catch((error) => {
                console.error('Error:', error);
                return sweetAlert('error', 'Error!', 'Something went wrong!');
            });
    };
    const handelHired = () => {
        const payload = commentData;

        if (!candidateId || !interviewId) {
            console.error('Candidate ID or Interview ID is undefined.');
            return;
        }

        dispatch(commentCreateHired({ candidateId, interviewId, payload }))
            .then(() => {
                handelCloseModel('Hired');
                dispatch(getRecordedInterview(id));
                return sweetAlert('success', 'Success!', 'Candidate Hired successfully');

            })
            .catch((error) => {
                console.error('Error:', error);
                return sweetAlert('error', 'Error!', 'Something went wrong!');
            });

    }
    const getFileExtension = (url) => {
        const splitUrl = url.split('.');
        return splitUrl.length > 1 ? splitUrl.pop() : '';
    };
    const handelModel = (props) => {
        if (props === "apcceptCandidate") {
            setApcceptCandidate(true);
        }
        if (props === "rejectCandidate") {
            setRejectCandidate(true);
        }
        if (props === "Hired") {
            setHiredCandidate(true)
        }

        if (props === "Evaluation") {
            setShowShareCandidate(true);
        }
        if (props === "download") {
            InterviewsDownload(interviewId)
        }
    }

    const handelCloseModel = (props) => {
        if (props === "apcceptCandidate") {
            setApcceptCandidate(false);
        }
        if (props === "rejectCandidate") {
            setRejectCandidate(false);
        }
        if (props === "Hired") {
            setHiredCandidate(false)
        }
        if (props === "Evaluation") {
            setShowShareCandidate(false);
        }
        if (props === "alert") {
            setShowAlert(false);
        }
        if (props === "DocViewer") {
            setDocViewer(false);
        }
    }

    const handelModelDocViewer = (type, documentUri) => {
        const fileExtension = getFileExtension(documentUri);

        if (fileExtension === 'pdf' || fileExtension === 'txt') {
            window.open(documentUri, '_blank');
            setDocViewer(false);
        }
        if (fileExtension === 'docx' || fileExtension === 'doc') {
            setDocViewer(true);
        }
    }
    const InterviewsDownload = (interviewId) => {

        if (interviewId) {
            dispatch(downloadinterview(interviewId))
                .then((res) => {


                    if (res.error)
                        Swal.fire({
                            icon: 'error',
                            title: 'Download Failed',
                            text: `${res.error.message}`,
                            showConfirmButton: false,
                            timer: 2500
                        });
                    else {
                        const byteCharacters = atob(res.payload.data);
                        const byteNumbers = new Uint8Array(byteCharacters.length);

                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }

                        const blob = new Blob([byteNumbers], { type: 'application/zip' })
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = 'interview.zip';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);

                        URL.revokeObjectURL(link.href);

                        Swal.fire({
                            icon: 'success',
                            title: 'Interview Downloaded',
                            text: `Interview downloaded successfully.`,
                            showConfirmButton: false,
                            timer: 2000
                        });
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Download Failed',
                        text: `Failed to download invoice: ${error.message}`,
                    });
                });

        }
        else {

            Swal.fire({
                icon: 'error',
                title: 'errro',

                showConfirmButton: false,
                timer: 1000
            });
        }

    }

    return (
        <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray recordedinterview-wrap custom-offcanvas" style={{ width: "90%" }}>
            <Container fluid className="detailpage-wrap pad-0">
                <Offcanvas.Header className="model-header bg-white ">
                    <Offcanvas.Title>
                        <span className="model-header-svg">{Video}Recorded Interview Details </span></Offcanvas.Title>
                    <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} /></div>
                </Offcanvas.Header>
                {loading && <Spinner />}

                {recordedInterview &&
                    <Col md={12} className="pad-0 recordedinterview-body">
                        <Row className="row-pad-0">
                            <Col md={8} className="">
                                <Row className="row-pad-0 detail-interviewer bg-white">
                                    <div className="flex-space-between">
                                        <div className="d-flex">
                                            <h5 className="min-sub-heading">
                                                <label className="sub-heading ">Candidate Details</label> <small className='mr-10'>/  {recordedInterview?.job_title}</small>
                                            </h5>
                                            {recordedInterview?.interview_stage && (
                                                <CustomButton
                                                    className={`${recordedInterview.interview_stage.stage_name && recordedInterview.interview_stage.stage_name.replace(/\s+/g, '-').toLowerCase()} status`}

                                                    text={recordedInterview.interview_stage.stage_name}
                                                />
                                            )}
                                        </div>
                                        <div>
                                            <CustomRating value={recordedInterview.average_rating} edit={false} />
                                        </div>
                                    </div>
                                    <Row className="row-pad-0 custom-row-pad-0">
                                        <Col md={1} className="custom-md-1 pad-0 mt-10">
                                            <div className="profile mr-15">
                                                <img src={aryaimg} alt="user profile" />
                                            </div>
                                        </Col>
                                        <Col md={11} className="content pad-0 custom-md-11 mt-10">
                                            <div className="flex-space-between">
                                                <div className="d-flex">
                                                    <h5 className="name">
                                                        <span className="min-heading">{candidate?.first_name} {candidate?.last_name}</span>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="flex-Xcenter">
                                                {candidate?.email_address && (
                                                    <div className="list-title">
                                                        <label className="label-view">Email: </label>
                                                        <span className="view-text">{candidate.email_address}</span>
                                                    </div>
                                                )}

                                                {candidate?.city && (
                                                    <div className="list-title">
                                                        <label className="label-view">City: </label>
                                                        <span className="view-text">{candidate.city}</span>
                                                    </div>
                                                )}

                                                {candidate?.address && (
                                                    <div className="list-title">
                                                        <label className='label-view'>Address: </label>
                                                        <span className="view-text">{candidate.address}</span>
                                                    </div>
                                                )}

                                                {candidate?.phone && (
                                                    <div className="list-title">
                                                        <label className="label-view">Phone Number: </label>
                                                        <span className="view-text">{candidate.phone}</span>
                                                    </div>
                                                )}

                                                {candidate?.country_name && (
                                                    <div className="list-title">
                                                        <label className="label-view">Country: </label>
                                                        <span className="view-text">{candidate.country_name}</span>
                                                    </div>
                                                )}
                                                {candidate?.state_name
                                                    && (
                                                        <div className="list-title">
                                                            <label className="label-view">State Name:</label>
                                                            <span className="view-text">{candidate.state_name}</span>
                                                        </div>
                                                    )}
                                                {candidate?.zip_code
                                                    && (
                                                        <div className="list-title">
                                                            <label className="label-view">Zip Code                            :</label>
                                                            <span className="view-text">{candidate.zip_code}</span>
                                                        </div>
                                                    )}
                                                {candidate?.resume && (
                                                    <div className="list-title">
                                                        <label className="label-view">Resume: </label>
                                                        <span className="view-text">
                                                            <a href="#" onClick={() => handelModelDocViewer("DocViewer", candidate.resume)}> Preview |</a>
                                                        </span>
                                                        <span className="view-text">
                                                            <a href={`${candidate.resume}`} download target="_blank">Download </a>
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                            {recordedInterview?.skills && recordedInterview?.skills.length > 0 ? (
                                                <div className="list-location mt-10 mb-10">
                                                    <label className="label-view" style={{ marginRight: "5px" }} >Skills:</label>
                                                    {recordedInterview?.skills.map((skill) => (
                                                        <CustomButton
                                                            key={skill.skill_id}
                                                            className="btn-status skill-tags"
                                                            text={skill.skill_name}
                                                        />
                                                    ))}
                                                </div>) : null}


                                            {candidate?.profile_summary && (
                                                <div className="list-description">
                                                    <label className="label-view">Profile Summary: </label>
                                                    <div className="mt-1">
                                                        <p className="view-text " dangerouslySetInnerHTML={{ __html: candidate.profile_summary }}>

                                                        </p>

                                                    </div>
                                                </div>
                                            )}

                                        </Col>
                                    </Row>
                                </Row>
                                <Row className="row-pad-0 pad-0  interviewer-responses bg-white mt-20 ">
                                    <CandidateRespones responses={recordedInterview?.interview_template_questions} setUpdateList={setUpdateList} save={true} />
                                </Row>

                            </Col>
                            <Col md={4}>
                                <Row className="row-pad-0 timeline-wrap">
                                    {(InterviewUnderway || Invited) ? <div></div> : <div className="d-flex mt-10">
                                        <CriclrDiv icon={share} onclick={() => handelModel("Evaluation")} tooltiptext="Share Candidate for Evaluation" clrName="timeline-criclr border-header cursor" />
                                        <CriclrDiv icon={acceptcandidate} onclick={() => handelModel("apcceptCandidate")} tooltiptext="Accept Candidate" clrName="timeline-criclr accept-candidate-svg border-header cursor" />
                                        <CriclrDiv icon={downloadarrowblow} clrName="timeline-criclr border-header cursor" onclick={() => handelModel("download")} tooltiptext="Download" />
                                        <CriclrDiv icon={rejectecandidate} onclick={() => handelModel("rejectCandidate")} tooltiptext="Reject Candidate" clrName="timeline-criclr border-header cursor" />
                                        {/* <CriclrDiv icon={HiredICon} onclick={() => handelModel("Hired")} tooltiptext="Hired" clrName="timeline-criclr border-header cursor" /> */}


                                    </div>
                                    }
                                    <TimeLine id={id} />

                                </Row>
                            </Col>
                            <Row className="row-pad-0 rating-tabel-wrap mt-20 ">
                                <RatingsTabel id={id} />
                            </Row>
                        </Row>



                    </Col>
                }
            </Container>


            {showRejectCandidate && <CommentModel icon={rejectecandidate} handelComment={handelComment} headertext="Reject Candidate" handelSubmit={handelRejected} handleClose={() => handelCloseModel("rejectCandidate")} />}
            {showApcceptCandidate && <CommentModel icon={acceptcandidate} headertext="Accept Candidate" handelComment={handelComment} handelSubmit={handelAccept} handleClose={() => handelCloseModel("apcceptCandidate")} />}
            {showAlert && <CustomAlert handleClose={() => handelCloseModel("alert")} />}
            {showShareCandidate && <ShareCandidate interviewID={interviewId} handleClose={() => handelCloseModel("Evaluation")} />}
            {showDocViewer && <ViewerFile handleClose={() => handelCloseModel("DocViewer")} document={`${candidate.resume}`} />}
            {showHiredCandidate && <CommentModel icon={HiredICon} headertext="Hired" handelComment={handelComment} handelSubmit={handelHired} handleClose={() => handelCloseModel("Hired")} />}

        </Offcanvas>
    );
}

export default RecordedInterview;
