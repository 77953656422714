import React, { useState, useEffect } from 'react';
import { Offcanvas, Container, Row, Col, } from 'react-bootstrap';
import { CustomButton, Checkbox } from '../form';
import { crossicon } from '../../helper/Icons/Icon';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { getPackages, stripePayment } from '../../store/actions/accountBillingAction';
import { loadStripe } from '@stripe/stripe-js';
import Spinner from '../common/Spinner';
const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 1000
    });
}

const AddPackage = ({ handleClose, type }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({ selectedPackage: null });
    const [payment, setPayment] = useState(false);
    const { packagesGet, loading, error } = useSelector((state) => state.accountBilling);
    const [errors, setErrors] = useState({});
    const packagesDetail = packagesGet?.data;

    useEffect(() => {
        if (type) {
            const payload = {
                package_name: type
            }
            dispatch(getPackages(payload));
        }

    }, [dispatch]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value === formData.selectedPackage ? null : value
        });


        setErrors({
            ...errors,
            selectedPackage: ""
        });
    };

    const handlePayment = async () => {
        if (!formData.selectedPackage) {
            setErrors({
                selectedPackage: "Please select a package to proceed with payment."
            });
        } else {
            setPayment(true);
            try {
                const stripe = await loadStripe("pk_test_aJZLyiunJxvJkisL6rnAm96p");
                const id = formData.selectedPackage;
                dispatch(stripePayment(id)).then((res) => {
                    setPayment(false);

                    if (res.payload.sessionId) {
                        stripe.redirectToCheckout({
                            sessionId: res.payload.sessionId
                        }).then((result) => {
                            if (result.error) {
                            } else {


                            }
                        });
                    }
                });
            } catch (error) {

            }
        }
    }
    return (
        <>
            <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "50%" }}>
                <Container fluid className="pad-20 svg-wrap-20">
                    <Offcanvas.Header className="model-header bg-white m-0 actionlist-header">
                        <Offcanvas.Title>Buy {type == "email" ? "Email" : "SMS"} Credits</Offcanvas.Title>
                        <div className="text-end">
                            <CustomButton onClick={handleClose} className="text-end close-btn" text={crossicon} />
                        </div>
                    </Offcanvas.Header>
                    <Col md={12} className='pad-10'>

                        {payment && <Spinner animation="border" variant="primary" />}
                        <Row className="mt-20 radius-5 bg-white pad-10">
                            {packagesDetail && packagesDetail.map((value, index) => (
                                <div key={index} className="d-flex align-items-center pad-10 mb-10 ml-10">
                                    <div className="mr-20 d-flex align-items-center buy-credits">
                                        <Checkbox
                                            name="selectedPackage"
                                            id={value.id}
                                            type="radio"
                                            className="radio-width"
                                            label={`${value.description} for $${value.price}`}

                                            onChange={handleChange}
                                            value={value.id}
                                        />
                                    </div>
                                    {/* <div>
                                        <p>{value.description} for {value.price}$</p>
                                    </div> */}
                                </div>
                            ))}
                        </Row>
                        {errors.selectedPackage && <span className="text-danger">{errors.selectedPackage}</span>}
                        <Col className="text-end mt-20 mb-20">
                            <CustomButton text="Cancel" onClick={handleClose} className="custom-btn-outline mr-10" />
                            <CustomButton text="PayNow" onClick={handlePayment} className="custom-btn-primary" />
                        </Col>
                    </Col>
                </Container>
            </Offcanvas>
        </>
    );
};

export default AddPackage;
