import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { getAll } from '../../store/slices/countrySlice';
import { Label } from '../form';

const Countries = ({ countryName, onChange, Norequired }) => {
    const dispatch = useDispatch();
    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const optionsCountries = useSelector(state => state.country.countries);

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);


    const handleChange = (selectedOption) => {
        if (selectedOption) {
            onChange('country_name', selectedOption.value);
        } else {
            onChange('country_name', null);
        }
    };
    if (!optionsCountries || !Array.isArray(optionsCountries)) {
        return (
            <>
                <Label text="State" htmlFor="state_name" />
                <Form.Select name='state_name'>
                    <option value="">Something went wrong. Check your internet connection.</option>
                </Form.Select>
            </>
        );
    }

    const options = optionsCountries.map(option => ({
        value: option.name,
        label: option.name,
    }));
    const selectedOption = countryName ? options.find(option => option.value === countryName) : null;

    return (
        <>
            <Label text="Country" className="label-input" HtmlFor="country_name" required={Norequired ? "" : "required"} />


            <Select
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                options={options}
                onChange={handleChange}
                value={selectedOption}
                placeholder="Select country"
            />

        </>
    );
};

export default Countries;