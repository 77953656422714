import { createSlice } from '@reduxjs/toolkit'
import { create, jobscandidate, getjobCandidatesStatuses, update, getjobquestion, getAll, getById, getJobs, deleteJob, deleteJobs, getJobStages, exportFile, getJobStatus, updateJobStage, getCandidateCountsByJob } from '../actions/jobActions'


const initialState = {
    loading: false,
    jobs: [],
    jobStages: [],
    jobDetail: null,
    status: [],
    jobCandidatesStatuses: null,
    updateJobStage: [],
    allGetJobs: [],
    JobsQuestion: [],
    jobsUniqueCandidate: null,
    cadidateCount: null,
    error: null,
}

const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state, action) => {
                state.loading = true
            })
            .addCase(create.fulfilled, (state, action) => {
                state.loading = false
                // state.jobs.push(action.payload)
            })
            .addCase(create.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(update.pending, (state, action) => {
                state.loading = true
            })
            .addCase(update.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(update.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getAll.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.loading = false
                state.jobs = action.payload
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(jobscandidate.pending, (state, action) => {
                state.loading = true
            })
            .addCase(jobscandidate.fulfilled, (state, action) => {
                state.loading = false
                state.jobsUniqueCandidate = action.payload
            })
            .addCase(jobscandidate.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getById.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getById.fulfilled, (state, action) => {
                state.loading = false
                state.jobDetail = action.payload
            })
            .addCase(getById.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(deleteJob.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteJob.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteJob.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(deleteJobs.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteJobs.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteJobs.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getJobStages.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getJobStages.fulfilled, (state, action) => {
                state.loading = false
                state.jobStages = action.payload
            })
            .addCase(getJobStages.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(exportFile.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(exportFile.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(exportFile.pending, (state, action) => {
                state.loading = true
            }).addCase(getJobStatus.fulfilled, (state, action) => {
                state.loading = false
                state.status = action.payload

            })
            .addCase(getJobStatus.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getJobStatus.pending, (state, action) => {
                state.loading = true
            }).addCase(updateJobStage.fulfilled, (state, action) => {
                state.loading = false
                state.updateJobStage = action.payload

            })
            .addCase(updateJobStage.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(updateJobStage.pending, (state, action) => {
                state.loading = true
            }).addCase(getJobs.pending, (state, action) => {
                // state.loading = true
            }).addCase(getJobs.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(getJobs.fulfilled, (state, action) => {
                state.loading = false
                state.allGetJobs = action.payload

            }).addCase(getjobquestion.pending, (state, action) => {
                state.loading = true
            }).addCase(getjobquestion.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(getjobquestion.fulfilled, (state, action) => {
                state.loading = false
                state.JobsQuestion = action.payload

            })
            .addCase(getCandidateCountsByJob.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getCandidateCountsByJob.fulfilled, (state, action) => {
                state.loading = false
                state.cadidateCount = action.payload
            })
            .addCase(getCandidateCountsByJob.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getjobCandidatesStatuses.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getjobCandidatesStatuses.fulfilled, (state, action) => {
                state.loading = false
                state.jobCandidatesStatuses = action.payload
            })
            .addCase(getjobCandidatesStatuses.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
    },
})

export default jobSlice.reducer



