import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { CustomButton, InputField, Switch } from "../form/index";
import { adduser, crossicon, faplusicon, faminusicon, expirtime, uploadfile } from "../../helper/Icons/Icon";
import { Container, Row, Col, Offcanvas } from "react-bootstrap";
import { inviteMultijobs } from '../../store/actions/candidateInvitationActions';
import Swal from 'sweetalert2';
import Spinner from "../common/Spinner";

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 3000
    });
}

const InviteCandidate = ({ handleClose, jobId }) => {
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.candidateInvitations);

    const [isExpire, setIsExpire] = useState(true);
    const [isShowProfile, setIsShowProfile] = useState(true);
    const [expireDays, setExpireDays] = useState(5);
    const [inputFields, setInputFields] = useState({ email: '', name: '', phone: '' });
    const [errors, setErrors] = useState({});
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    const phonePattern = /^[+\d\s()\-]{10,}$/;

    const handleIncrement = () => {
        setExpireDays(expireDays + 1);
    };

    const handleDecrement = () => {
        if (expireDays > 1) {
            setExpireDays(expireDays - 1);
        }
    };

    const handleChange1 = (event) => {
        const checkedValue = event.target.checked;
        setIsExpire(checkedValue);
    };

    const handleChange2 = (event) => {
        const checkedValue = event.target.checked;
        setIsShowProfile(checkedValue);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputFields(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        if (!inputFields.email || !emailPattern.test(inputFields.email)) {
            formErrors.email = "Enter a valid email address.";
        }
        if (!inputFields.phone || !phonePattern.test(inputFields.phone)) {
            formErrors.phone = "Please enter a valid phone number.";
        }

        return formErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const jobs = {
            job_ids: jobId,
            candidate: inputFields,
            expire_after_days: expireDays,
            show_company_profile: isShowProfile ? 1 : 0,
        };

        if (jobId) {
            dispatch(inviteMultijobs(jobs))
                .then((res) => {
                    if (res.payload.status === 500) {
                        return sweetAlert("error", "Error!", res.payload.detail);
                    }
                    if (res.payload.error) {
                        return sweetAlert("error", "Error!", res.payload.error);
                    } else {
                        handleClose();
                        return sweetAlert("success", "Success!", res.payload.message);
                    }
                })
                .catch(() => {
                    return sweetAlert("error", "Error!", "Something went wrong");
                });
        } else {
            return sweetAlert("error", "Error!", "Job ID is required");
        }
    };

    return (<>
        <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "650px" }}>
            {loading && <Spinner />}
            <Container fluid className="row-pad-0 pad-0 bg-ligte-gray pad-20">
                <Offcanvas.Header className="model-header bg-white ">
                    <Offcanvas.Title><span className="model-header-svg">{adduser} Invite Candidate</span></Offcanvas.Title>
                    <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn" text={crossicon} /></div>
                </Offcanvas.Header>

                <Offcanvas.Body className="mt-10 wrap InviteCandidate-wrap">
                    <Row className="row-pad-0 InviteCandidatebody bg-white pad-20">
                        <Col md={12} className="d-flex InviteCandidate-addinput mb-10 pad-0">
                            <div>
                                <InputField
                                    type="email"
                                    className="input"
                                    placeholder="Enter email address"
                                    name="email"
                                    value={inputFields.email}
                                    onChange={handleInputChange}
                                />
                                {errors.email && <span className="text-danger">{errors.email}</span>}
                            </div>
                            <div>
                                <InputField
                                    type="text"
                                    placeholder="Enter name"
                                    name="name"
                                    value={inputFields.name}
                                    onChange={handleInputChange}
                                />
                                {errors.name && <span className="text-danger">{errors.name}</span>}
                            </div>
                            <div>
                                <InputField
                                    type="tel"
                                    placeholder="Enter phone number"
                                    name="phone"
                                    value={inputFields.phone}
                                    onChange={handleInputChange}
                                />
                                {errors.phone && <span className="text-danger">{errors.phone}</span>}
                            </div>
                        </Col>
                    </Row>

                    <Row className="row-pad-0 bg-white mt-20 share-setting-wrap">
                        <Col md={12} className="pad-0">
                            <h4 className="mb-10">Share Settings</h4>
                            <h5>Main Settings</h5>
                            <Row className="row-pad-0">
                                <Col md={12} className="main-setting-card">
                                    <div className="link mt-10">
                                        <div className="puls-minus" style={{ width: "90%" }}>
                                            <span className="mr-10">{expirtime}</span>
                                            <span>Expire Link After</span>
                                            <CustomButton icon={faminusicon} className="btn-default btn-mius" onClick={handleDecrement} />
                                            <InputField type="text" value={expireDays} readOnly />
                                            <CustomButton className="btn-default btn-puls" icon={faplusicon} onClick={handleIncrement} />
                                            <span className="mr-10">Days</span>
                                        </div>
                                        <Switch checked={isExpire} onChange={handleChange1} />
                                    </div>
                                    <div className="link mt-20 mb-10">
                                        <div style={{ width: "90%" }}>
                                            <span className="mr-10">{uploadfile}</span>
                                            <span>Include company landing page</span>
                                        </div>
                                        <Switch checked={isShowProfile} onChange={handleChange2} />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Col md={12} className="text-end mt-20 mb-20 pad-0">
                        <CustomButton className="custom-btn-outline mr-10" text="Cancel" onClick={handleClose} />
                        <CustomButton text="Invite" className="custom-btn-primary" onClick={handleSubmit} />
                    </Col>
                </Offcanvas.Body>
            </Container>
        </Offcanvas>
    </>
    );
};

export default InviteCandidate;
