import { Container, Offcanvas, Row, Col, Form, Nav, Tab, Dropdown } from "react-bootstrap";
import { Video, crossicon, newtemplate, faminusicon, faplusicon, timer, retake, UploadMediaImge, thingtime, ringcopy, BoldIcon, italicIcon, underlinecIcon, addlinkIcon, option, textquestion, attachment, fileupploed, mic } from "../../helper/Icons/Icon";
import CriclrDiv from "../common/CriclrDiv";
import ImportInterview from "./ImportInterview";
import UploadMedia from "./UploadMedia";
import { Label, InputField, Textarea, SelectField, Checkbox, CustomButton } from "../form/index";
import { useCallback, useState, useEffect, useRef, useLayoutEffect } from "react";
import QuestionsLibrary from "./QuestionsLibrary";
import Addlink from "./Addlink";

import EmailSmsConfiguration from "./EmailSmsConfiguration";
import PreInterviewForm from "./PreInterviewForm";
import Questions from "./Questions";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { update, getById } from '../../store/actions/interviewTemplateActions'
import Spinner from "../common/Spinner";
const ERROR_MESSAGES = {
    interview_template_name: 'Template name is required',
    interview_name: 'Interview name is required',

};
const EditInterviewTemplate = ({ interviewTemplateId, handleClose }) => {
    const dispatch = useDispatch()
    const [data, setData] = useState({});
    const { interviewTemplateDetail, loading, error } = useSelector((state) => state.interviewTemplate);

    const [showUploadMedia, setUploadMedia] = useState(false);
    const [showUploadMediaExit, setUploadMediaExit] = useState(false);

    const [showAddlink, setAddlink] = useState(false);
    const [showQuestionsLibrary, setQuestionsLibrary] = useState(false);
    const [showImportInterview, setImportInterview] = useState(false);

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (interviewTemplateId) {
            dispatch(getById(interviewTemplateId));

        }

    }, [dispatch, interviewTemplateId]);

    useEffect(() => {
        if (interviewTemplateDetail) {
            setData({
                interview_template_name: interviewTemplateDetail?.InterviewTemplateName,
                interview_name: interviewTemplateDetail?.InterviewName,
                interview_template_description: interviewTemplateDetail?.InterviewTemplateDescription,
                intro_media: interviewTemplateDetail?.intro_media || [],
                exit_media: interviewTemplateDetail?.exit_media || [],
                intro_message: interviewTemplateDetail?.intro_message,
                exit_message: interviewTemplateDetail?.exit_message,
                video_interview_invitation_email: interviewTemplateDetail?.video_interview_invitation_email,
                video_interview_invitation_sms: interviewTemplateDetail?.video_interview_invitation_sms,
                live_interview_invitation_email: interviewTemplateDetail?.live_interview_invitation_email,
                external_evaluator_invitation_email: interviewTemplateDetail?.external_evaluator_invitation_email,
                external_evaluator_invitation_sms: interviewTemplateDetail?.external_evaluator_invitation_sms,
                interview_success_email_to_candidate: interviewTemplateDetail?.interview_success_email_to_candidate,
                evaluation_success_email_to_ext_evaluator: interviewTemplateDetail?.evaluation_success_email_to_ext_evaluator,
                live_interview_invitation_sms: interviewTemplateDetail?.live_interview_invitation_sms,
                questions: interviewTemplateDetail?.questions || [],
                pre_interview_form: interviewTemplateDetail?.pre_interview_form || []
            });
        }
    }, [interviewTemplateDetail]);

    const sweetAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            showConfirmButton: false,
            timer: 3000
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: value.trim() ? '' : ERROR_MESSAGES[name]
        }));

    };
    const preInterviewForm = (fiels) => {
        data['pre_interview_form'] = fiels;
        setData({ ...data })
    }

    const temlateQuestins = (questions) => {

        data['questions'] = questions;
        setData({ ...data })
        if (questions) {
            setErrors(prevErrors => ({
                ...prevErrors,
                questions: '',
            }))
        }
    }
    const handleModel = (props) => {
        switch (props) {
            case "ImportInterview":
                setImportInterview(!showImportInterview);
                break;
            case "UploadMedia":

                setUploadMedia(!showUploadMedia);

                break;
            case "UploadMediaExit":

                setUploadMediaExit(!showUploadMediaExit);

                break;
            case "Addlink":
                setAddlink(!showAddlink);
                break;
            case "QuestionsLibrary":
                setQuestionsLibrary(!showQuestionsLibrary);
                break;
            default:
                break;
        }
    };

    const saveWelMsgMedia = (media) => {
        setData(prevData => ({
            ...prevData,
            intro_media: media
        }));

        setUploadMedia(false);
    };

    const saveExitMsgMedia = (media) => {

        setData(prevData => ({
            ...prevData,
            exit_media: media
        }));
        setUploadMediaExit(false);
    }

    const validateForm = () => {
        const errors = {};

        if (!data.interview_template_name) {
            errors.interview_template_name = 'Template name is required';
        }
        if (!data.interview_name) {
            errors.interview_name = 'Interview name is required';
        }

        // if (!data.interview_template_description) {
        //     errors.interview_template_description = 'Description template is required';
        // }
        if (!data.questions || data.questions.length === 0) {
            errors.questions = 'At least one question must be added';
        }

        return errors;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length === 0) {
            dispatch(update({ interviewTemplateId, payload: data }))
                .then(res => {
                    if (res.payload.error) {
                        sweetAlert("error", "Error!", res.payload.error);
                    } else {
                        setData({});
                        handleClose();
                        sweetAlert("success", "Success!", res.payload.message);
                    }
                })
                .catch(() => {
                    sweetAlert("error", "Error!", "Something went wrong!");
                });
        }
        else {
            setErrors(errors);
        }
    };

    const FileMediaDisplay = ({ fileMedia }) => {
        if (!Array.isArray(fileMedia)) return null;

        return (
            <div className="save-question-box mt-1 template-question-box">
                <label>Media:</label>
                {fileMedia.map((file, index) => {
                    const fileName = file.name;
                    const extension = file.type;

                    let icon;
                    if (['mp4', 'mov', 'avi'].includes(extension)) {
                        icon = Video;
                    } else if (['mp3', 'wav', 'aac'].includes(extension)) {
                        icon = ringcopy;
                    } else if (['jpeg', 'png', 'gif', 'jpg'].includes(extension)) {
                        icon = UploadMediaImge;
                    }
                    else if (['docx', 'pdf', 'doc'].includes(extension)) {
                        icon = attachment;
                    }
                    else {
                        icon = fileupploed;
                    }

                    return (
                        <div key={index} className="file-item">
                            {icon && <span className="file-icon">{icon}</span>}
                            <span className="file-name">{fileName}</span>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <>
            <Offcanvas
                show={true}
                onHide={handleClose}
                placement="end"
                className="bg-ligte-gray custom-offcanvas"
                style={{ width: "82%" }}
            >
                <Container fluid className="row-pad-0 pad-0 bg-ligte-gray pad-20">
                    <Offcanvas.Header className="model-header bg-white actionlist-header ">
                        <Offcanvas.Title>
                            <span className="model-header-svg ">{newtemplate}Edit  Interview Template</span>
                        </Offcanvas.Title>
                        <div className="text-end">
                            <CustomButton
                                onClick={handleClose}
                                className="text-end close-btn "
                                text={crossicon}
                            />
                        </div>
                    </Offcanvas.Header>
                    {(loading && data && interviewTemplateDetail) && <Spinner />}
                    <Col md={12} className=" mt-10 pad-10 new-template-wrap">
                        <Row className="row-pad-0 bg-white  radius-7 pad-20 ">
                            <Col md={12} className="pad-0">
                                <Row className="row-pad-0  mb-10">
                                    <Col md={6}>
                                        <Label
                                            text="Template Name"
                                            className="label"
                                            htmlFor="templateName"
                                            required="required"
                                        />
                                        <InputField
                                            type="text"
                                            name="interview_template_name"
                                            id="templateName"
                                            className="input-field"
                                            placeholder="Enter template name here"
                                            value={data.interview_template_name || ''}
                                            onChange={handleChange}
                                        />
                                        {errors.interview_template_name && <span className="text-danger">{errors.interview_template_name}</span>}

                                    </Col>
                                    <Col md={6}>
                                        <Label
                                            text="Interview Name"
                                            className="label"
                                            htmlFor="interviewName"
                                        // required="required"
                                        />
                                        <InputField
                                            type="text"
                                            name="interview_name"
                                            id="interviewName"
                                            className="input-field"
                                            placeholder="Enter interview name here"
                                            value={data.interview_name || ' '}
                                            onChange={handleChange}
                                        />
                                        {errors.interview_name && <span className="text-danger">{errors.interview_name}</span>}

                                    </Col>

                                    <Col md={12} className="mt-10">
                                        <Label
                                            text="Template Description"
                                            className="label"
                                            htmlFor="templateDescription"
                                        // required="required"
                                        />
                                        <Textarea
                                            as="textarea"
                                            className="input-textarea"
                                            name="interview_template_description"
                                            rows={4}
                                            placeholder="Enter template description here"
                                            value={data.interview_template_description || ' '}
                                            onChange={handleChange}
                                        />
                                        {/* {errors.interview_template_description && <span className="text-danger">{errors.interview_template_description}</span>} */}

                                    </Col>
                                </Row>
                            </Col>
                        </Row>


                        <Row className="row-pad-0 bg-white  mt-20 radius-7 pad-20">
                            {interviewTemplateDetail && <EmailSmsConfiguration onChange={handleChange} emailInterview={interviewTemplateDetail} />}
                        </Row>

                        <Row className="row-pad-0 bg-white  mt-20 radius-7 pad-20 ">

                            <PreInterviewForm preInterviewForm={preInterviewForm} data={interviewTemplateDetail?.pre_interview_form} />
                        </Row>

                        <Row className="row-pad-0 bg-white  mt-20 radius-7 pad-20 ">
                            <Col md={12} className="">
                                <Row className="InterviewJourney-content mt-10 mb-10 pad-10  row-pad-0">
                                    <Col md={12} className="pad-0">
                                        <h3 className="min-heading">Welcome Message</h3>
                                        <Row className="row-pad-0">
                                            <Col md={10} className="pad-0">
                                                <InputField
                                                    type="text"
                                                    onChange={handleChange}
                                                    id="intro_message"
                                                    name="intro_message"
                                                    value={data.intro_message || ''}
                                                    className="h-34 input-field"
                                                    placeholder="Enter welcome message"
                                                />
                                                {data.intro_media?.length > 0 && <FileMediaDisplay fileMedia={data.intro_media} />}
                                            </Col>
                                            <Col md={2} className="text-end">
                                                <CustomButton
                                                    text="+ Add Media"
                                                    onClick={() => handleModel("UploadMedia")}
                                                    className="custom-btn-outline h-34"
                                                />

                                            </Col>
                                        </Row>


                                    </Col>
                                </Row>
                                {/* end Welcome Message */}

                                {data.questions && <Questions temlateQuestins={temlateQuestins} requiredValue={data.questions} hidebtnEdit="addQuestion-Edit" />}
                                {errors.questions && <span className="text-danger">{errors.questions}</span>}
                            </Col>
                        </Row>

                        <Row className="row-pad-0 bg-white  mt-20 radius-7 pad-20">
                            <Col md={12}>
                                <div className="InterviewJourney-content bg-white mt-20 mb-10 pad-10  row-pad-0">

                                    <h3 className="min-heading">Exit Message</h3>

                                    <Row className="row-pad-0">
                                        <Col md={10} className="pad-0">
                                            <InputField
                                                onChange={handleChange}
                                                type="text"
                                                id="exit_message"
                                                name="exit_message"
                                                value={data.exit_message || ''}
                                                className="h-34 input-field"
                                                placeholder="Enter exit message"
                                            />
                                            {data.exit_media?.length > 0 && <FileMediaDisplay fileMedia={data.exit_media} />}
                                        </Col>
                                        <Col md={2} className="text-end">
                                            <CustomButton
                                                text="+ Add Media"
                                                onClick={() => handleModel("UploadMediaExit")}
                                                className="custom-btn-outline"
                                            />
                                        </Col>
                                    </Row>


                                </div>
                            </Col>
                        </Row>

                        <Col md={12} className="text-end mt-20  mb-10">
                            <CustomButton
                                text="Save"
                                className="custom-btn-primary"
                                onClick={handleSubmit}
                            />
                        </Col>
                    </Col>
                </Container>
                {/* {showImportInterview && (
                    <ImportInterview handleClose={() => handleModel("ImportInterview")} />
                )} */}
                {showUploadMedia && (
                    <UploadMedia fileMedia={data.intro_media} SavehandleModel={saveWelMsgMedia} handleClose={() => handleModel("UploadMedia")} />
                )}
                {showUploadMediaExit && (

                    <UploadMedia fileMedia={data.exit_media} SavehandleModel={saveExitMsgMedia} handleClose={() => handleModel("UploadMediaExit")} />
                )}
                {showAddlink && <Addlink handleClose={() => handleModel("Addlink")} />}
                {showQuestionsLibrary && (
                    <QuestionsLibrary
                        handleClose={() => handleModel("QuestionsLibrary")}
                    />
                )}
            </Offcanvas>
        </>
    );
};
export default EditInterviewTemplate;
