import { Table, Form, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Tabelcolumn from "./Tabelcolumn";
import { Video } from "../../helper/Icons/Icon";
import Liveterview from "../RecordedInterviewDetails/Liveterview";
import RecordedInterview from "../RecordedInterviewDetails/RecordedInterview";
import JobDetail from "../job/JobDetail";
import CandidatesDetail from "../candidate/CandidatesDetail";
import Paginate from "./Paginate";
import { dashboardInterviews, getHiredCandidates } from "../../store/actions/DashboardAction";
import Spinner from "./Spinner";
import { headertimeLine } from "../interview/fields";
import Statusbtn from "./Statusbtn";
import { getByName } from "../../store/actions/userPrefrenceActions";

const defaultCurrentPage = 1;
const defaultPerPage = 25
const headerdefault = [
    {
        "key": "candidate_name",
        "title": "Candidate Name",
        "checked": 1
    },
    {
        "key": "job_title",
        "title": "Job Name",
        "checked": 1
    },
    {
        "key": "recorded_live",
        "title": "Recorded\/Live",
        "checked": 1
    },
    {
        "key": "stage_name",
        "title": "Interview Status",
        "checked": 1
    },
    {
        "key": "job_status",
        "title": "Job Status",
        "checked": 1
    },
    {
        "key": "candidate_status",
        "title": "Candidate Status",
        "checked": 1
    }
]
const DashboardTable = ({ update, interviewTypeId, searchFiledEmpty }) => {
    const dispatch = useDispatch();

    const [searchField, setSearchField] = useState({});
    const [sortConfig, setSortConfig] = useState({});
    const [interviewId, setInterviewId] = useState(null);
    const [showLiveterview, setShowLiveterview] = useState(false);

    const [showJobDetail, setJobDetail] = useState(false);
    const [showCandidateDetail, setCandidateDetail] = useState(false);
    const [showRecordedInterview, setShowRecordedInterview] = useState(false);
    const { dashboardInterviewsData, loading } = useSelector((state) => state.dashboard);
    const pages = dashboardInterviewsData?.pages || [];
    const totalPages = dashboardInterviewsData?.total_page || 0;
    const totalRecords = dashboardInterviewsData?.total_record || 0;
    const data = dashboardInterviewsData?.data || [];
    const headers = dashboardInterviewsData?.headers || headerdefault;


    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);
    const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
    const [perPage, setPerPage] = useState(userPrefrenceDetail?.preference_value?.record_per_page ?? defaultPerPage);


    useEffect(() => {
        dispatch(getByName("users"));
    }, [dispatch])
    useEffect(() => {
        setCurrentPage(interviewTypeId?.page ?? 1)
    }, [interviewTypeId])
    useEffect(() => {
        if (userPrefrenceDetail?.preference_value?.record_per_page) {
            setPerPage(userPrefrenceDetail.preference_value.record_per_page);
        }
    }, [userPrefrenceDetail]);

    const handleFieldSearch = (e) => {
        const { name, value } = e.target;
        setSearchField(prev => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        if (interviewTypeId?.type || interviewTypeId?.type == 0) {
            setSearchField(searchFiledEmpty || {});
        }

    }, [interviewTypeId]);

    const toggleSortOrder = (key) => {
        setSortConfig(prevConfig => ({
            key,
            order: prevConfig.key === key ? (prevConfig.order === 'asc' ? 'desc' : 'asc') : 'asc'
        }));
    };

    const fetchData = async () => {
        try {
            const isHired = interviewTypeId.type === "Hired";


            const data = {
                limit: perPage,
                page: currentPage,
                search: searchField,
                interview_type_id: interviewTypeId.type,

                ...(interviewTypeId.name ? { status_name: interviewTypeId.name.toLowerCase() } : {}),

                startDate: interviewTypeId.startDate,
                endDate: interviewTypeId.endDate,
                order_by: sortConfig.key,
                sort_by: sortConfig.order,
            };


            if (perPage) {
                await dispatch(dashboardInterviews(data));
                // if (isHired) {
                //     await dispatch(dashboardInterviews(data));
                //     // await dispatch(getHiredCandidates(data));
                // } else {
                //     await dispatch(dashboardInterviews(data));
                // }
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (interviewTypeId?.type || interviewTypeId?.type == 0) {
            fetchData();
        }

    }, [dispatch, currentPage, perPage, searchField, sortConfig, update, interviewTypeId]);

    const handlePageChange = (page) => setCurrentPage(page);
    const handlePerPage = (perPage) => setPerPage(perPage);

    const handleModel = (type, id) => {

        if (type === "Recorded") {
            setInterviewId(id);
            setShowRecordedInterview(true);

        }
        if (type === "job") {
            setInterviewId(id);
            setJobDetail(true)
        }
        if (type === "candidate") {
            setInterviewId(id);
            setCandidateDetail(true)

        }
        if (type === "Live") {
            setInterviewId(id);
            setShowLiveterview(true);
        }

    };

    const closeModel = (modelType) => {
        setInterviewId('');
        if (modelType === "RecordedInterview") {
            setShowRecordedInterview(false);

        } if (modelType === "job") {

            setJobDetail(false)

        }
        if (modelType === "candidate") {

            setCandidateDetail(false)

        }
        if (modelType === "LiveInterview") {

            setShowLiveterview(false);
        }

    };

    return (
        <>
            {loading && <Spinner />}
            <div className="listing-section dashboard-table-listing  daspad-10 card-wrap" style={{ overflowX: "auto" }}>
                <Table className="tabel pad-10">
                    <thead>
                        <tr>
                            <th style={{ minWidth: "150px" }} className="th-text">Quick Action</th>
                            {headers.map((header, index) => (
                                <th key={index} className="sorting" style={{ minWidth: "200px" }}>
                                    <span onClick={() => toggleSortOrder(header.key)}>
                                        {sortConfig.key === header.key ? (
                                            sortConfig.order === 'asc' ? <i className="fa fa-sort-amount-asc" /> : <i className="fa fa-sort-amount-desc" />
                                        ) : <i className="fa fa-exchange" />}
                                    </span>
                                    <Form.Control
                                        name={header.key}
                                        value={searchField[header.key] || ''}
                                        type="text"
                                        placeholder={header.title}
                                        onChange={handleFieldSearch}
                                    />
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.length === 0 ? (
                            <tr>
                                <td colSpan={8} className="text-center">No records found.</td>
                            </tr>
                        ) : (
                            data.map((item, index) => (
                                <tr key={index}>
                                    <td className="cursor td-icon">
                                        <div className="quick-action">
                                            <Tabelcolumn
                                                className="cursor td-icon"
                                                span="span"
                                                icon={Video}
                                                className={` ${(item.stage_name.includes("Interview Underway") || item.stage_name.includes("Invited")) ? "cus-disabled" : ""} cursor td-icon`}
                                                onClick={() => handleModel(item.recorded_live, item.id)}
                                                Tooltip={item.recorded_live}
                                            />
                                        </div>
                                    </td>
                                    {headers && headers.map((header, headerIndex) => {
                                        const statusClass = item[header.key]?.replace(/\s+/g, '-').toLowerCase();
                                        return (
                                            <td key={headerIndex} className="td-ellipsis" title={item[header.key]}>
                                                {header.key === "stage_name" || header.key === "candidate_status" || header.key === "job_status" ? (
                                                    <Statusbtn className={`status ${statusClass}`} text={item[header.key]} />
                                                ) : header.key === "candidate_name" ? (
                                                    <a href="#" onClick={() => handleModel("candidate", item.candidate_id)}>
                                                        {item[header.key]}
                                                    </a>
                                                ) : header.key === "job_title" ? (
                                                    <a href="#" onClick={() => handleModel("job", item.job_id)}>
                                                        {item[header.key]}
                                                    </a>
                                                ) : (
                                                    item[header.key] || '-'
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))
                        )}
                    </tbody>

                </Table>

                <Paginate
                    pages={pages}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    perPage={perPage}
                    totalRecords={totalRecords}
                    handlePageChange={handlePageChange}
                    handlePerPage={handlePerPage}
                />


            </div>
            {showLiveterview && <Liveterview id={interviewId} timeLineheader={headertimeLine} handleClose={() => closeModel("LiveInterview")} />}
            {showRecordedInterview && <RecordedInterview id={interviewId} timeLineheader={headertimeLine} handleClose={() => closeModel("RecordedInterview")} />}
            {showCandidateDetail && <CandidatesDetail candidateId={interviewId} handleClose={() => closeModel("candidate")} />}
            {showJobDetail && <JobDetail jobId={interviewId} handleClose={() => closeModel("job")} />}

        </>
    );
};

export default DashboardTable;
