import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const config = require('../../config/config.json');
const API_BASE_URL = config.API_BASE_URL;

const BASE_URL = `${API_BASE_URL}/user-preference`;

const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

export const create = createAsyncThunk(
    'user-preference/save',
    async (payload, thunkAPI) => {
        const state = thunkAPI.getState();
        const userTokenInStore = userToken ?? state.auth?.userToken;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userTokenInStore
            },
        }

        try {
            const { data } = await axios.post(`${BASE_URL}`, payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)

export const getAll = createAsyncThunk(
    'user-preference/getAll',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            params: payload
        }

        try {
            const { data } = await axios.get(`${BASE_URL}`, config)
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const getByName = createAsyncThunk(
    'user-preference/getByName',
    async (pr_name, thunkAPI) => {
        const state = thunkAPI.getState();
        const userTokenInStore = userToken ?? state.auth?.userToken;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userTokenInStore
            },
        }

        try {
            const { data } = await axios.get(`${BASE_URL}/${pr_name}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)


export const deleteUser = createAsyncThunk(
    'user/deleteUser',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.delete(`${BASE_URL}/${id}`, config);
            return data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)


export const getDesignatedUsers = createAsyncThunk(
    'user/designated-users',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL}/designated-users`, config);
            return data.users;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const getUsersRoles = createAsyncThunk(
    'user/users-roles',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL}/user-roles`, config);
            return data.data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
// UsersProfile 

export const UsersProfile = createAsyncThunk(
    'user/UsersProfile',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL}/profile-detail/${id}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)


export const deleteUsers = createAsyncThunk(
    'job/deleteUsers',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.post(`${API_BASE_URL}/users-multiple-delete`, payload, config)
            return data;
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)