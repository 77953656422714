import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { getAll } from '../../store/slices/workAuthorizationSlice';
import { Label } from '../form';

const WorkAuthorization = ({ workAuthorizationId, onChange }) => {
    const dispatch = useDispatch();
    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const workAuthorizationData = useSelector(state => state.workAuthorization.workAuthorizations);

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    const handleChange = (selectedOption) => {
        if (selectedOption) {
            onChange('work_authorization_id', selectedOption.value);
        }
        else {
            onChange('work_authorization_id', ' ');
        }
    };
    if (!workAuthorizationData || !Array.isArray(workAuthorizationData)) {
        return (
            <>
                <Label text="Select pay type" htmlFor="pay_type_id" />
                <Form.Select name='pay_type_id'>
                    <option value="">Something went wrong. Check your internet connection.</option>
                </Form.Select>
            </>
        );
    }

    const options = workAuthorizationData?.map(option => ({
        value: option.id,
        label: option.name
    }));
    const selectedOption = workAuthorizationId ? options.find(option => option.value === workAuthorizationId) : null;

    return (
        <>
            <Label text="Work Authorization" className="label-input" HtmlFor="work_authorization_id" />
            <Select
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                options={options}
                value={selectedOption}
                onChange={handleChange}

                placeholder="Select your work authorization status"

                styles={{
                    clearIndicator: (provided) => ({
                        ...provided,
                        cursor: 'pointer'
                    }),
                }}

            />
        </>
    );
};

export default WorkAuthorization;







// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Form } from 'react-bootstrap';
// import { getAll } from '../../store/slices/workAuthorizationSlice';
// import { Label } from '../form';

// const WorkAuthorization = ({ workAuthorizationId, onChange }) => {
//     const dispatch = useDispatch();
//     const options = useSelector(state => state.workAuthorization.workAuthorizations);

//     useEffect(() => {
//         dispatch(getAll());
//     }, [dispatch]);

//     const handleChange = (e) => {
//         onChange(e);
//     };

//     return (
//         <>
//             <Label text="Work Authorization" className="label-input" HtmlFor="work_authorization_id" />
//             <Form.Select name='work_authorization_id' id='work_authorization_id' onChange={handleChange}>
//                 <option value="">Select your work authorization status</option>
//                 {options &&
//                     options.map(option => (
//                         <option key={option.id} value={option.id} selected={(workAuthorizationId && workAuthorizationId == option.id) ? "selected" : ""}>{option.name}</option>
//                     ))}
//             </Form.Select >
//         </>
//     );
// };

// export default WorkAuthorization;