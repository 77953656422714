import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getrecordedInterviews } from "../store/actions/DashboardAction";
import Card from "../components/dashboard/Card";
import Filter from "./Filter";
import { Modal, Button } from 'react-bootstrap';
import { createPreference } from "../store/actions/userActions";
import { getByName } from "../store/actions/userPrefrenceActions";

const RecordedInterviewsCard = ({ HandelCardChart, setJobAnalyticsDate }) => {



    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

    const formattedThirtyDaysAgo = thirtyDaysAgo.toISOString().split("T")[0];
    const yesterday = new Date();
    // yesterday.setMonth(yesterday.getMonth() + 1);
    const formattedYesterday = yesterday.toISOString().split("T")[0];

    const [formData, setFormData] = useState({
        startDate: formattedThirtyDaysAgo,
        endDate: formattedYesterday,
    });
    const [dateFilters, setDateFilters] = useState({
        recordedInterviews: {
            startDate: formattedThirtyDaysAgo,
            endDate: formattedYesterday,
        },
    });

    const [showModalCard, setShowModalCard] = useState(false);
    const [customProps, setCustomProps] = useState("recordedInterviews");




    const dispatch = useDispatch();
    const { recordedInterviews } = useSelector(state => state.dashboard);

    const recordedInterviewsData = [
        recordedInterviews?.invited_recorded_interviews_count,
        recordedInterviews?.submitted_recorded_interviews_count,
        recordedInterviews?.passed_recorded_interviews_count,
        recordedInterviews?.failed_recorded_interviews_count
    ];

    useEffect(() => {
        fetchUserPreferences();
    }, [dispatch]);


    const fetchUserPreferences = () => {
        const preferenceNames = "recordedInterviews";

        dispatch(getByName(preferenceNames)).then((res) => {
            const { payload } = res;
            if (payload && payload.preference_value) {
                const { startDate, endDate } = payload.preference_value;

                const start = startDate;
                const end = endDate;

                const newDateFilters = {
                    recordedInterviews: {
                        startDate: start,
                        endDate: end,
                    },
                };


                setDateFilters(newDateFilters);
                fetchInitialData(newDateFilters);
            } else {

                fetchInitialData(dateFilters);

            }
        }).catch((error) => {
            console.error("Error fetching user preferences:", error);
        });
    };


    const fetchInitialData = (filters) => {
        const { startDate, endDate } = filters.recordedInterviews || {};


        if (startDate && endDate) {


            dispatch(getrecordedInterviews({ startDate: startDate, endDate: endDate }));
            setJobAnalyticsDate({
                startDate: startDate,
                endDate: endDate,
            })
        } else {

        }
    };

    const handleDateChange = (name, value) => {
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = () => {

        const startDate = new Date(formData.startDate).toISOString().split("T")[0]
        const endDate = new Date(formData.endDate).toISOString().split("T")[0]

        if (!startDate || !endDate) {
            console.error("Start date and end date are required.");
            return;
        }
        setJobAnalyticsDate({
            startDate: startDate,
            endDate: endDate,
        })
        setDateFilters((prev) => ({
            ...prev,
            [customProps]: { startDate, endDate },
        }));

        const payload = {
            preference_value: {
                startDate: startDate,
                endDate: endDate,
            },
            preference_name: customProps,
            module_code: "dashboardCard",
        };

        dispatch(createPreference(payload))
            .then(() => {

                if (customProps === "recordedInterviews") {
                    dispatch(getrecordedInterviews({ startDate, endDate }));
                }
                setShowModalCard(false);
            })
            .catch((error) => console.error("Preference update error:", error));
    };

    const handleOpenModalCard = (props) => {
        setCustomProps(props);
        setFormData(dateFilters[props] || { startDate: yesterday, endDate: formattedThirtyDaysAgo });
        setShowModalCard(true);
    };

    const handleReset = () => {
        const resetFilters = {
            recordedInterviews: {
                startDate: formattedThirtyDaysAgo,
                endDate: formattedYesterday,
            },
        };

        setDateFilters(resetFilters);
        setFormData({
            startDate: resetFilters.recordedInterviews.startDate,
            endDate: resetFilters.recordedInterviews.endDate,
        });
        setJobAnalyticsDate({
            startDate: resetFilters.recordedInterviews.startDate,
            endDate: resetFilters.recordedInterviews.endDate,
        })
        const payload = {
            preference_value: {
                startDate: resetFilters.recordedInterviews.startDate,
                endDate: resetFilters.recordedInterviews.endDate,
            },
            preference_name: customProps,
            module_code: "dashboardCard",
        };

        dispatch(createPreference(payload))
            .then(() => {
                dispatch(getrecordedInterviews({
                    startDate: resetFilters.recordedInterviews.startDate,
                    endDate: resetFilters.recordedInterviews.endDate,
                }));
                setShowModalCard(false);
            })
            .catch((error) => console.error("Preference update error:", error));
    };
    const isYesterdayFilter = (formData) => {


        const startDate = new Date(formData.startDate);
        const endDate = new Date(formData.endDate);
        const yesterdayDate = new Date(yesterday);
        return (
            startDate.getDate() === new Date(yesterdayDate).getDate() &&
            startDate.getMonth() === new Date(yesterdayDate).getMonth() &&
            startDate.getFullYear() === new Date(yesterdayDate).getFullYear() &&
            endDate.getDate() === new Date().getDate() &&
            endDate.getMonth() === new Date().getMonth() &&
            endDate.getFullYear() === new Date().getFullYear()
        );
    };
    return (
        <>

            <Card
                formData={dateFilters.recordedInterviews}
                dashboard={recordedInterviewsData}
                labelData={['Invited', 'Submitted', 'Passed', 'Failed']}
                handleOpenModal={() => handleOpenModalCard("recordedInterviews")}
                cardModel={HandelCardChart}
                nameapi="recordedInterviews"
                Yesterday={isYesterdayFilter(dateFilters.recordedInterviews)}
                name="Recorded Interviews"
            />


            {showModalCard && (
                <Modal show={showModalCard} onHide={() => setShowModalCard(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Filter
                            startDate={formData.startDate}
                            endDate={formData.endDate}
                            onchange={handleDateChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="custom-btn-primary" onClick={handleSubmit}>
                            Apply
                        </Button>
                        <Button variant="secondary" onClick={handleReset}>
                            Reset
                        </Button>
                    </Modal.Footer>
                </Modal >
            )}
        </>
    );
};

export default RecordedInterviewsCard;
