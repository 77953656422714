
import React, { useState, useEffect, useRef } from 'react';
import { Checkbox, CustomButton, InputField, Label, SelectField, Switch, Textarea } from "../form/index"
import { JobActive, crossicon, faplusicon, faminusicon, copyicon } from "../../helper/Icons/Icon"
import { Container, Row, Col, Offcanvas, Form } from "react-bootstrap"
import { postjobfiled } from "./fields";
import Spinner from "../common/Spinner";
import TextEditor from "../common/TextEditor";
import { Link, useNavigate, useLocation } from "react-router-dom"
import InviteCandidates from "./InviteCandidates";
import CopyJob from '../common/CopyJob';
import { create, getById } from '../../store/actions/jobActions'

import { useDispatch, useSelector } from 'react-redux';
import * as moment from 'moment';
import Industries from '../common/Industries';
import JobTypes from '../common/JobTypes';
import PayTypes from '../common/PayTypes';
import Countries from '../common/Countries';
import States from '../common/States';
import WorkspaceTypes from '../common/WorkspaceTypes';
import WorkAuthorization from '../common/WorkAuthorization';
import DesignatedUsers from '../common/DesignatedUsers';
import PostJobInterviewTemplates from '../common/PostJobInterviewTemplates';
import Companies from '../common/Companies';

// interview_template_id
import Swal from 'sweetalert2';
const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 3000
    });
}
const initialFormData = {
    job_title: '',
    job_type_id: '',
    company_id: '',
    interview_template_id: '',
    industry_id: null,
    city: '',
    state_name: '',
    zip_code: '',
    country_name: 'United States',
    job_expiry_date: '',
    workspace_type_id: null,
    work_authorization_id: null,
    pay_type_id: '',
    pay_salary: '',
    job_description: '',
    is_build_template: false,
    is_private: false,
    followup_user: null,
};
const ERROR_MESSAGES = {
    job_title: 'Job title is required',
    job_type_id: 'Job type is required',
    company_id: 'Company is required',
    city: 'City is required',
    interview_template_id: "Interview template is required",
    state_name: 'State is required',
    country_name: 'Country is required',
    zip_code: 'Zip code is required',
    job_expiry_date: 'Job expiration date is required',
    pay_type_id: 'Pay type is required',
    pay_salary: 'Pay/salary is required',
    job_description: 'Job description is required'
};


const Postjob = ({ handleClose, copyJobId = 0 }) => {
    const location = useLocation();

    const [formSate, setFormSate] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { templatesgetAll } = useSelector((state) => state.interviewTemplate);
    const { companiesTypes } = useSelector(state => state.company);
    const [contentEditor, setContentEditor] = useState(null);
    const [copyJob, setcopyJob] = useState(copyJobId ?? 0);
    const [errors, setErrors] = useState({});
    const [jobId, setjobId] = useState();
    const [showInviteCandidates, setShowInviteCandidates] = useState(false);
    const { jobDetail, loading, error } = useSelector((state) => state.job);

    const [data, setData] = useState({ initialFormData })





    const CloseInviteCandidates = () => {
        setShowInviteCandidates(false);
        handleClose();
        if (location.pathname === '/jobs') {
            window.location.reload();
        } else {
            navigate('/jobs');
        }
    }


    const Invite = () => {
        navigate("/jobtemplate");
    };
    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        let newValue = value;
        if (e.target.type === 'checkbox' || e.target.type === 'switch') {
            newValue = checked;
        }


        if (name === 'pay_salary' && isNaN(value)) {

            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: 'Only numbers are allowed'
            }));

            return;
        }


        // setData({ ...data, [name]: newValue });
        setData((prevData) => {
            const updatedFormData = { ...prevData, [name]: value };
            validateField(name, value);
            return updatedFormData;
        });


    };

    const handleSearchChange = (name, value) => {

        let newValue = value;
        if (name == "country_name") {
            setFormSate(null)
        }
        if (name === 'industry_id' && newValue === '') {
            newValue = null;
        }

        // setData({ ...data, [name]: newValue });
        setData((prevData) => {
            const updatedFormData = { ...prevData, [name]: value };
            validateField(name, value);
            return updatedFormData;
        });



    };
    useEffect(() => {

        if (contentEditor) {



            setData((prevData) => {
                const updatedFormData = { ...prevData, job_description: contentEditor };
                validateField('job_description', contentEditor)
                return updatedFormData;
            });

        }


    }, [contentEditor])
    const validateField = (name, value) => {
        setErrors((prevErrors) => {
            let error = '';
            if (!value) {
                error = ERROR_MESSAGES[name];
            }

            return { ...prevErrors, [name]: error };
        });
    }
    const handelCopyJob = (data) => {
        if (data) {
            setcopyJob(data)
        }

    }

    useEffect(() => {
        if (copyJob) {
            dispatch(getById(copyJob));
            setErrors({});
        }

    }, [copyJob]);

    useEffect(() => {
        if (jobDetail) {
            setData(jobDetail);
            setContentEditor(jobDetail?.job_description)
        }

    }, [jobDetail]);
    useEffect(() => {
        setData(initialFormData);
        setContentEditor('')
    }, []);
    const validateForm = () => {
        const errors = {};
        if (!data.job_title || data.job_title.trim() === '') {
            errors.job_title = ERROR_MESSAGES.job_title;
        }

        if (!data.job_type_id) {
            errors.job_type_id = ERROR_MESSAGES.job_type_id;
        }

        if (!data.company_id) {
            errors.company_id = ERROR_MESSAGES.company_id;
        }

        if (!data.city) {
            errors.city = ERROR_MESSAGES.city;
        }

        if (!data.state_name) {
            errors.state_name = ERROR_MESSAGES.state_name;
        }

        if (!data.country_name) {
            errors.country_name = ERROR_MESSAGES.country_name;
        }

        if (!data.zip_code) {
            errors.zip_code = ERROR_MESSAGES.zip_code;
        }

        if (!data.job_expiry_date) {
            errors.job_expiry_date = ERROR_MESSAGES.job_expiry_date;
        }

        if (!data.pay_type_id) {
            errors.pay_type_id = ERROR_MESSAGES.pay_type_id;
        }

        if (!data.pay_salary) {
            errors.pay_salary = ERROR_MESSAGES.pay_salary;
        }

        if (!data.job_description) {
            errors.job_description = ERROR_MESSAGES.job_description;
        }

        return errors;
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        const validationErrors = validateForm(data);

        if (Object.keys(validationErrors).length === 0) {
            dispatch(create(data))
                .then((res) => {

                    if (res.payload.status == 500) {
                        return sweetAlert(
                            "error",
                            "Error!",
                            res.payload.detail

                        )

                    }
                    if (res.payload.error) {

                        return sweetAlert(
                            "error",
                            "Error!",
                            res.payload.error

                        )

                    }
                    else {
                        setData(initialFormData)
                        if (location.pathname === '/jobs') {
                            window.location.reload();
                        } else {
                            navigate('/jobs');
                        }

                        handleClose()
                        return sweetAlert(
                            "success",
                            "Success!",
                            res.payload.message ? "Job added successfully" : res.payload.message

                        )

                    }

                })
                .catch(error => {

                    return sweetAlert(
                        "error",
                        "Error!",
                        "Something went wrong!"
                    )
                });
        }
        else {
            setErrors(validationErrors);
        }
    };
    useEffect(() => {
        if (!data.company_id) {
            setData(prev => ({
                ...prev,
                company_id: prev.company_id || companiesTypes.default_id,
            }));
        }

        if (!data.interview_template_id) {
            setData(prev => ({
                ...prev,
                interview_template_id: prev.interview_template_id || templatesgetAll.default_id,
            }));
        }

        if (data.company_id && data.interview_template_id) {
            console.log("Skip: Values are already set");
        }
    }, [companiesTypes, templatesgetAll]);

    // useEffect(() => {
    //     if (!data.company_id || !data.interview_template_id) {
    //         setData(prev => ({
    //             ...prev,
    //             company_id: companiesTypes?.default_id,
    //             interview_template_id: templatesgetAll?.default_id
    //         }));
    //     }
    //     else {
    //         console.log("skipe")
    //     }

    // }, [companiesTypes, templatesgetAll]);

    const PublishInviteCandidates = (e) => {
        e.preventDefault();

        const validationErrors = validateForm(data);

        if (Object.keys(validationErrors).length === 0) {
            dispatch(create(data))
                .then((res) => {
                    if (res?.type == "job/create/rejected") {
                        return sweetAlert(
                            "error",
                            "Error!",
                            res.payload.error || res.payload.detail
                        )
                    }
                    else {
                        setData(initialFormData)
                        setjobId(res.payload.data.job_id);
                        setShowInviteCandidates(true);

                        navigate("/jobs")
                        return sweetAlert(
                            "success",
                            "Success!",
                            "Job added successfully",
                        )
                    }



                })
                .catch(error => {

                    return sweetAlert(
                        "error",
                        "Error!",
                        "Something went wrong!"
                    )
                });
        }
        else {
            setErrors(validationErrors);
        }
        // setShowInviteCandidates(true);
    };

    return (
        <>
            {!showInviteCandidates ?
                <Offcanvas
                    show={true}
                    onHide={handleClose}
                    placement="end"
                    className="bg-ligte-gray custom-offcanvas"
                    style={{ width: "60%" }}
                >
                    <Container
                        fluid
                        className="row-pad-0 pad-0 bg-ligte-gray pad-20 wrap-postjob"
                    >

                        {loading &&
                            <Spinner />
                        }

                        <Offcanvas.Header className="model-header bg-white ">
                            <Offcanvas.Title>
                                <span className="model-header-svg">{JobActive}Post a Job</span>

                            </Offcanvas.Title>
                            <div className="text-end">
                                <CustomButton
                                    onClick={handleClose}
                                    className="text-end close-btn "
                                    text={crossicon}
                                />
                            </div>
                        </Offcanvas.Header>

                        <Offcanvas.Body className="mt-10 wrap postjob-body">
                            <Form>
                                <Row className="row-pad-0 bg-white mt-20 postjob ">
                                    <Col md={6} className="pad-margin">
                                        <Link herf="#">
                                            <span>{copyicon}</span>Copy Existing Job Order
                                        </Link>
                                    </Col>
                                    <Col md={6} className="pad-margin">

                                        <CopyJob Changehandle={handelCopyJob} copyJob={copyJob} />
                                    </Col>



                                    <Col md={12} className="pad-margin mb-20">
                                        <Label text="Job Title" className="label-input" HtmlFor="JobTitle" required="required" />
                                        <InputField
                                            type="text"
                                            placeholder="Enter title of the first job you are hiring for"
                                            name="job_title"
                                            id="job_title"
                                            value={data.job_title}
                                            onChange={handleChange}
                                        />
                                        {errors.job_title && <><span className="text-danger">{errors.job_title}</span></>}
                                    </Col>


                                    <Col md={6} className="pad-margin mb-20">
                                        <JobTypes typeId={data.job_type_id} onChange={handleSearchChange} />
                                        {errors.job_type_id && <><span className="text-danger">{errors.job_type_id}</span></>}
                                    </Col>
                                    <Col md={6} className="pad-margin mb-20">

                                        <Companies companyId={data?.company_id} onChange={handleSearchChange} />
                                        {errors.company_id && <><span className="text-danger">{errors.company_id}</span></>}
                                    </Col>
                                    <Col md={6} className="pad-margin pad-margin mb-20">

                                        <PostJobInterviewTemplates interviewTemplateId={data?.interview_template_id} onChange={handleSearchChange} icontootip="show" />
                                        {errors.interview_template_id && <><span className="text-danger">{errors.interview_template_id}</span></>}
                                    </Col>

                                    <Col md={6} className="pad-margin pad-margin mb-20">
                                        <DesignatedUsers designatedUserId={data?.followup_user} onChange={handleSearchChange} />
                                        {errors.followup_user && <><span className="text-danger">{errors.followup_user}</span></>}
                                    </Col>

                                    <Col md={6} className="pad-margin mb-20">
                                        <Industries industryId={data?.industry_id} onChange={handleSearchChange} />
                                    </Col>
                                    <Col md={6} className="pad-margin pad-margin mb-20">
                                        <WorkAuthorization workAuthorizationId={data?.work_authorization_id} onChange={handleSearchChange} />
                                    </Col>
                                    <Col md={6} className="pad-margin pad-margin mb-20">
                                        <Label text="City" className="label-input" HtmlFor="" required="required" />
                                        <InputField defaultValue={data?.city} type="text" name="city" placeholder="city" onChange={handleChange} />
                                        {errors.city && <><span className="text-danger">{errors.city}</span></>}
                                    </Col>
                                    <Col md={6} className="pad-margin pad-margin mb-20">

                                        <States stateName={data?.country_name ?? null} state={data?.state_name ?? null} onChange={handleSearchChange} />
                                        {errors.state_name && <><span className="text-danger">{errors.state_name}</span></>}
                                    </Col>
                                    <Col md={6} className="pad-margin pad-margin mb-20">
                                        <Countries countryName={data?.country_name} onChange={handleSearchChange} />
                                        {errors.country_name && <><span className="text-danger">{errors.country_name}</span></>}
                                    </Col>
                                    <Col md={6} className="pad-margin pad-margin mb-20">
                                        <Label text="Zip Code " className="label-input" HtmlFor="" required="required" />
                                        <InputField type="text" defaultValue={data?.zip_code} name="zip_code" placeholder="Enter ZIP code" onChange={handleChange} />
                                        {errors.zip_code && <><span className="text-danger">{errors.zip_code}</span></>}
                                    </Col>
                                    <Col md={6} className="pad-margin pad-margin mb-20">
                                        <Label text="Job Expiration Date " className="label-input" HtmlFor="" required="required" />
                                        <Form.Control

                                            name='job_expiry_date'
                                            min={moment(new Date()).format('YYYY-MM-DD')}
                                            onClick={(e) => e.target.showPicker()}
                                            type="date"
                                            className="cursor"
                                            onChange={handleChange}
                                            value={data.job_expiry_date ? moment(data.job_expiry_date).format('YYYY-MM-DD') : ""} />
                                        {errors.job_expiry_date && <><span className="text-danger">{errors.job_expiry_date}</span></>}
                                    </Col>
                                    <Col md={6} className="pad-margin pad-margin mb-20">
                                        <WorkspaceTypes workSpaceTypeId={data?.workspace_type_id} onChange={handleSearchChange} />
                                    </Col>
                                    <Col md={6} className="pad-margin mb-20">
                                        <PayTypes payTypeId={data?.pay_type_id} onChange={handleSearchChange} />
                                        {errors.pay_type_id && <><span className="text-danger">{errors.pay_type_id}</span></>}
                                    </Col>

                                    <Col md={6} className="pad-margin">
                                        <Label text="Pay/Salary" className="label-input" HtmlFor="paySalary" required="required" />
                                        <InputField type="text" defaultValue={data?.pay_salary} name="pay_salary" placeholder="Pay/Salary" onChange={handleChange} />
                                        {errors.pay_salary && <><span className="text-danger">{errors.pay_salary}</span></>}
                                    </Col>

                                    <Col md={12} className="mt-10 mb-10">
                                        <Label HtmlFor="" text="Job Description" className="label-input" required="required" />

                                        {/* <Textarea
                                            onChange={handleChange}
                                            as="textarea"
                                            defaultValue={data?.job_description}
                                            row={35}
                                           
                                            id="job_description"
                                            placeholder="Enter Description"
                                        /> */}
                                        <TextEditor
                                            handleSearchChange={handleSearchChange}
                                            textValue={contentEditor}
                                            setTextValue={setContentEditor}
                                            placeholderValue={"Enter job description"}
                                        />

                                        {errors.job_description && (
                                            <span className="text-danger">{errors.job_description}</span>
                                        )}
                                    </Col>
                                    <Col md={6} className="label-checkbox">
                                        {/* <Checkbox name="is_build_template" checked={data?.is_build_template} label="Save this job as a template" onChange={handleChange} />
                                     */}
                                    </Col>
                                    <Col md={6} className="jobprivate-switch ">
                                        <Switch name="is_private" defaultValue={data?.is_private} checked={data?.is_private} onChange={handleChange} />
                                        <span className="span">Make job private</span>
                                    </Col>
                                </Row>

                                <Col md={12} className="text-end mt-20 mb-20 pad-0">
                                    <CustomButton

                                        className="mr-10 mb-10 custom-btn-outline"
                                        text="Publish Job"
                                        onClick={handleSubmit}
                                    />
                                    <CustomButton
                                        className="custom-btn-primary mb-10"
                                        onClick={PublishInviteCandidates}
                                        text="Publish Job & Invite Candidates"
                                    />
                                </Col>
                            </Form>
                        </Offcanvas.Body>
                    </Container>
                </Offcanvas > :

                <InviteCandidates jobId={jobId} onClick={Invite} handleClose={CloseInviteCandidates} />
            }

        </>
    );
};


export default Postjob;
